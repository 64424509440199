import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { RentalObjectInfo, ComponentBase, ObjectTypeInfo, PriceModel, RentalInfo, RentalStatus, BookingAlternative, LocationInfo, RentalAlternative } from 't4core';
import * as moment from 'moment';
import { BookingModel, BookingService } from 'app-components';

@Component({
  selector: 'ow-booking-alternative',
  templateUrl: './booking-alternative.html',
  styleUrls: ['./booking-alternative.css']
})
export class BookingAlternativeComponent extends ComponentBase implements OnInit, OnChanges {
  @Input() public booking: RentalAlternative;
  @Input() public unavailable: boolean = false;

  @Output() public selected: EventEmitter<RentalAlternative> = new EventEmitter<RentalAlternative>();

  public type: ObjectTypeInfo;
  public pickupLocation: LocationInfo;
  public returnLocation: LocationInfo;
  public Price: PriceModel;
  public loadingPrice: boolean = true;

  constructor(public bookingService: BookingService) {
    super();
  }

  async ngOnChanges() {
    this.update();
  }
    private update() {
    if (this.booking != null) {
      this.bookingService.getLocation(this.booking.PickupLocationId).then(x => this.pickupLocation = x);
      this.bookingService.getLocation(this.booking.ReturnLocationId).then(x => this.returnLocation = x);
      this.bookingService.getType(this.booking.ObjectTypeId).then(x => this.type = x);
    }
  }

  async ngOnInit() {
    this.update();
  }

  public select() {
    this.selected.emit(this.booking);
  }
}
