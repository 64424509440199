import { OnChanges, SimpleChanges } from '@angular/core';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ClientSettings, ComponentBase, CustomerField, CustomerInfo, CustomerType, SettingsService } from 't4core';

@Component({
  selector: 'app-customer-form-component',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.css']
})
export class CustomerFormComponent extends ComponentBase implements OnInit, OnChanges
{
  @Input() private customer: CustomerInfo = new CustomerInfo();
  @Input() private hideFields: string[] = [];
  @Input() private showFields: string[] = [];

  public form: UntypedFormGroup = new UntypedFormGroup(this.formBuilder.group({}).controls, { updateOn: 'blur' });;
  public formSettings: CustomerField[];

  constructor(private formBuilder: UntypedFormBuilder, private settings: SettingsService) {
    super();
  }

  async ngOnInit(): Promise<void> {
    // Load settings
    this.formSettings = this.configureForm(this.settings.clientSettings, this.customer);
    // Make sure all CopyFrom fields are honored
    this.copyValuesFromCustomer(this.formSettings, this.customer);
    this.processCopyFrom();
  }

  async ngOnChanges(changes:SimpleChanges) {
    this.processCopyFrom();
    if (changes.customer) {
      this.copyValuesFromCustomer(this.formSettings, this.customer);
    }

  }

  public modelChange(fieldId: string, value: string) {
    // Save the value
    this.customer[fieldId] = value;

    // Makre sure all fields are consistent
    this.processCopyFrom();
  }

  private configureForm(settings: ClientSettings, customer:CustomerInfo): CustomerField[] {
    if (!settings.CustomerSettings) return null;

    // Copy the metadata objects
    let config = [];
    /*settings.CustomerSettings.Fields.forEach(x => config.push({ ...x }));*/
    settings.CustomerSettings.Fields.filter(x => (x.ValidFor != 1)).forEach(x => config.push({ ...x }));

    // Hide/Show fields
    if (this.hideFields.length > 0)
      config.map(x => x.Visible = x.Visible && !this.hideFields.includes(x.FieldId));
    else if (this.showFields.length > 0)
      config.map(x => x.Visible = x.Visible && this.showFields.includes(x.FieldId));

    this.copyValuesFromCustomer(config, customer);

    return config;
  }

  // Makes sure all fields that should get their value from another form gets updated
  private processCopyFrom(): void {
    if (!this.formSettings) return;

    this.formSettings
      .filter(x => x.CopyFrom != null)
      .forEach(x => {
        var source = this.formSettings.find(y => y.FieldId == x.CopyFrom);
        if (source) {
          x.Value = source.Value;
        }
      });
  }

  private copyValuesFromCustomer(settings: CustomerField[], customer: CustomerInfo) {
    if (customer && settings) {
      for (const [key, value] of Object.entries(customer)) {
        var field = settings.find(x => x.FieldId == key);
        if (field) field.Value = value;
      }
    }
  }

  // Validate the form and return the result
  public validate(): boolean {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    return this.form.valid;
  }
}
