import { Component, ElementRef, OnInit } from '@angular/core';
import { PageBase } from 't4core';
import { BookingModel } from 'app-components';
import { NavigationService } from 'app-components';
import { BookingService } from 'app-components';

@Component({
  selector: 'app-initiate-new-bookig',
  templateUrl: './initiate-new-bookig.component.html',
  styleUrls: ['./initiate-new-bookig.component.css']
})
export class InitiateNewBookigComponent extends PageBase implements OnInit {

  public bookingState: BookingModel;
  public loading: boolean = true;
  public locationFetchSuccess: boolean = false;

  constructor(private navigator: NavigationService, el: ElementRef, private BookingDataSerivce: BookingService) {
    super(el);
  }

  public async ngOnInit() {
    await this.prepareBookingState();
  }

  public async prepareBookingState() {
    this.loading = true;
    this.bookingState = this.BookingDataSerivce.getBookingState();
    this.bookingState.existingBooking = this.navigator.getParam("rentalId");

    await this.saveBookingState();

    if (this.bookingState.atLocation) {
      this.navigator.executeCommand("NextAtLocation");
    }
    else {
      this.navigator.executeCommand("Next");
    }
    this.loading = false;
  }

  public async saveBookingState() {
    await this.BookingDataSerivce.saveState(this.bookingState);
  }

  public async back() {
    this.navigator.back();
  }

}
