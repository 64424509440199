<app-view-title Title="{{ labels.title }}" [isSplitColor]="true"></app-view-title>
<div class="view-content">
  <div class="container">
    <app-customer-form-component #frmCustomer class="row" [(customer)]="customer" [hideFields]="['Email', 'MobilePhone']">
    </app-customer-form-component>
    <div class="row mt-2" *ngIf="customer">
      <h6 class="col-12">{{ labels.gdpr }}</h6>
      <button class="col-10 btn btn-link" (click)="showTerms()" [disabled]="!hasGDPR">{{ labels.confirmGdpr }}</button>
      <mat-checkbox class="col-2" labelPosition="before" [(ngModel)]="confirmGDPR"></mat-checkbox>
    </div>
  </div>
</div>
<div class="view-actions white-fade-out-bg">
  <button mat-raised-button (click)="next(frmCustomer.validate())">
    {{ labels.next }}
  </button>
</div> 

