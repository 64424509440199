import { Component, OnInit, Inject } from '@angular/core';
import { DialogBase, RentalObjectInfo } from 't4core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-trailer-info',
  templateUrl: './trailer-info.component.html',
  styleUrls: ['./trailer-info.component.css']
})
export class TrailerInfoComponent extends DialogBase implements OnInit {

    public loader: boolean = false;
    public object: RentalObjectInfo;

    constructor(
        public dialogRef: MatDialogRef<TrailerInfoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: number) {
        super("TrailerInfoComponent");
    }

    async ngOnInit(): Promise<void> {
        this.loader = true;
        await this.getTrailerInfo();
        this.loader = false;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    async getTrailerInfo() {
        this.object = await this.Api.get<RentalObjectInfo>("RentalObject/GetObjectInfo", { objectId: this.data });
    }

}
