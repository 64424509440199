export type AppType = 'app' | 'web';

export interface AppTypeSettings {
  appType: AppType,
  isShowMenu: boolean;
  isShowGuide: boolean,
  isStoringCustomer: boolean
}

export const App: AppTypeSettings = {
  appType: 'app',
  isShowGuide: true,
  isShowMenu: true,
  isStoringCustomer: true
};

export const Web: AppTypeSettings = {
  appType: 'web',
  isShowGuide: false,
  isShowMenu: false,
  isStoringCustomer: false
};
