<h3 class="text-center" mat-dialog-title [innerHTML]="title"></h3>
<div mat-dialog-content>
  <div *ngIf="!rentalAgreement" class="componentLoader">
    <mat-progress-spinner diameter="25" mode="indeterminate"></mat-progress-spinner>
  </div>
  <div *ngIf="rentalAgreement">
    <div class="option">
      <div [innerHTML]="rentalAgreement"></div>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-raised-button [mat-dialog-close]="true">OK</button>
</div>

