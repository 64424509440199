const path = '../../assets/Images/';

export type Country = {
  phonePrefix: string,
  flagPath: string,
  fullName: string,
  shortName: CountryCode,
  language: string,
  phonePlaceholder: string
}
export type CountryCode = 'se' | 'dk' | 'no' | 'is' | 'de' | 'xx';


export const getCountries = (): Country[] => {
  let countries:Country[] = [];
  countries.push(sweden);
  countries.push(denmark);
  countries.push(norway);
  countries.push(iceland);
  countries.push(germany);
  // on future additions, pleease add before other
  countries.push(other);
  return countries;
}

export function getCountryByCode(countryCode): Country {
  switch (countryCode){
    case 'se':
      return sweden;
    case 'dk':
      return denmark;
    case 'no':
      return norway;
    case 'is':
      return iceland;
    case 'de':
      return germany;
    default:
      return other;
  }
}

export function getCountryByLanguageCode(language): Country {
  switch (language) {
    case 'sv':
      return sweden;
    case 'da':
      return denmark;
    case 'no':
      return norway;
    case 'is':
      return iceland;
    case 'de':
      return germany;
    default:
      return other;
  }
}

export function getCountryDataByName(name: string): Country {
  switch (name.toLowerCase()) {
    case 'sweden':
      return sweden;
    case 'denmark':
      return denmark;
    case 'norway':
      return norway;
    case 'iceland':
      return iceland;
    case 'germany':
      return germany;
    default:
      return null;
  }
}

export function getCountryByPhoneNumber(phoneNumber: string): Country {
  phoneNumber = phoneNumber.replace(/\s/g, '');
  let extractedCountryCode;
  if (phoneNumber.startsWith('+')) {
    extractedCountryCode = phoneNumber.substring(0, 3);
  }
  else if (phoneNumber.startsWith('00')) {
    extractedCountryCode = phoneNumber.replace('00', '+').substring(0, 3);
  }
  else {
    // How to find country code without initial + or 00? Let's panic!
  }
  switch (extractedCountryCode) {
    case '+45':
      return denmark;
    case '+46':
      return sweden;
    case '+47':
      return norway;
    case '+354':
      return iceland;
    case '+49':
      return germany;
    case '+xx':
    default:
      return other;
  }
}

const sweden: Country = {
  phonePrefix: "+46",
  flagPath: path+"flag-sweden.png",
  fullName: "Sweden",
  language: "sv",
  shortName: "se",
  phonePlaceholder: "0701234567"
};
const denmark: Country = {
  phonePrefix: "+45",
  flagPath: path+"flag-denmark.png",
  fullName: "Denmark",
  language: "da",
  shortName: "dk",
  phonePlaceholder: "32123456"
};
const norway: Country = {
  phonePrefix: "+47",
  flagPath: path+"flag-norway.png",
  fullName: "Norway",
  language: "no",
  shortName: "no",
  phonePlaceholder: "40612345"
};
const iceland: Country = {
  phonePrefix: "+354",
  flagPath: path+"flag-iceland.png",
  fullName: "Iceland",
  language: "is",
  shortName: "is",
  phonePlaceholder: "611 1234"
};
const germany: Country = {
  phonePrefix: "+49",
  flagPath: path+"flag-germany.svg",
  fullName: "Germany",
  language: "de",
  shortName: "de",
  phonePlaceholder: "0211 1234567"
};
const other: Country = {
  phonePrefix: "+XX",
  flagPath: path+"flag-other.png",
  fullName: "Other",
  language: "xx",
  shortName: "xx",
  phonePlaceholder: "+1 555 1234"
};
