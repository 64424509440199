
import { MatDialog } from '@angular/material/dialog';
import { AppSettingsService } from 't4-app-integration';
import { NavigationService } from 'app-components';
import { IdleDialog } from '../../../app/MyPage/Dialogs/idle-dialog/idle-dialog';
import { Component } from '@angular/core';

@Component({
  selector: 'app-idle-timer',
  template: ''
})
export class IdleTimerComponent {




  constructor(private appSettings: AppSettingsService, private Dialog: MatDialog, private navigator: NavigationService) {
    //Time out is only made for Instore function currently.
    if (this.appSettings.getParam("inStore") == "true" && this.appSettings.appType == "web") {
      this.setupIdleListeners(60); // in seconds.

      this.setIdleCallback(() => {

        this.removeIdleListeners();

        const dialogRef = this.Dialog.open(IdleDialog, { data: { time: 15 } });

        dialogRef.afterClosed().toPromise().then((result) => {
          console.log("Dialog closed with result:", result);

          if (result === false)
            this.navigator.menuNavigate("Start", 'E')
          else if (result === true || result === undefined)
            this.setupIdleListeners(60); // in seconds.
        });
      });
    }
  }

  // --------- Idle Function - Web ---------

  private timer: any;
  private idleDuration = 120000; // 120 sec default
  private idleCallback: () => void;

  private isResetCooldownActive = false;


  private resetIdleTimer = () => {
    //Checks if there is a cooldown on the reset.
    if (this.isResetCooldownActive) {
      return;
    }

    clearTimeout(this.timer);

    //handle the cooldown period.
    this.startResetCooldown();

    this.timer = setTimeout(() => {
      if (this.idleCallback) {
        this.idleCallback();
      }
    }, this.idleDuration);
  }

  private startResetCooldown() {
    // Activate the cooldown for resetting the idle timer
    this.isResetCooldownActive = true;

    // Deactivate the cooldown after 1 second
    setTimeout(() => {
      this.isResetCooldownActive = false;
    }, 1000);
  }


  //Starts all the idle listeners.
  private setupIdleListeners(seconds: number) {

    //Removes any existing timer, so we dont have more then one running in case.
    this.removeIdleListeners();

    //Sets up the duration on how long the wait time should be.
    this.idleDuration = seconds * 1000;

    ['mousemove', 'keydown', 'mousedown', 'touchstart'].forEach((eventType) => {
      document.addEventListener(eventType, this.resetIdleTimer);
    });
    //Start the timer after we have created the listeners.
    this.resetIdleTimer();
  }

  //Removes all idle listeners. 
  public removeIdleListeners() {
    ['mousemove', 'keydown', 'mousedown', 'touchstart'].forEach((eventType) => {
      document.removeEventListener(eventType, this.resetIdleTimer);
    });
    clearTimeout(this.timer);
  }

  //Set up the action after the timmers goes out.
  setIdleCallback(callback: () => void) {
    this.idleCallback = callback;
  }
}
