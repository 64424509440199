import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ComponentBase } from 't4core';
import { AppIntegrationService } from 't4-app-integration';

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  encapsulation: ViewEncapsulation.None
})
export class VerifyAccountComponent extends ComponentBase implements OnInit
{
  public validateCode: boolean = true;
  public verificationCode0: string = "";
  public verificationCode1: string = "";
  public verificationCode2: string = "";
  public verificationCode3: string = "";

  @ViewChild('vf0') vf0: ElementRef;
  @ViewChild('vf1') vf1: ElementRef;
  @ViewChild('vf2') vf2: ElementRef;
  @ViewChild('vf3') vf3: ElementRef;

  @Output() verificationCode: EventEmitter<string> = new EventEmitter<string>();
  @Output() resendVerification: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private appService: AppIntegrationService) {
    super();
  }

  public async ngOnInit() {
    setTimeout(() => this.vf0.nativeElement.focus(), 500);
  }

  public resendCode(isRepeat:boolean) {
    this.resendVerification.emit(isRepeat);
  }

  public focusNext(field: number, event) {
    var currentValue = this["verificationCode" + field];

    if (event.key == "Backspace") {
      if (!currentValue) { // Only go to previous if current field is empty
        if (field > 0) {
          this["vf" + (field - 1)].nativeElement.focus();
          this["verificationCode" + (field - 1)] = "";
        }
      }
    }
    else {
      if (currentValue) {
        if (field < 3) this["vf" + (field + 1)].nativeElement.focus();
        else this.sendVerificationCode();
      } else { console.log('isdjunisuan0pinsd'); }
    }
  }

  public async editDetails() {
        this.validateCode = true;
        this.verificationCode0 = "";
        this.verificationCode1 = "";
        this.verificationCode2 = "";
        this.verificationCode3 = "";
  }

  private sendVerificationCode(): void {
    let verificationCode = this.verificationCode0 + this.verificationCode1 + this.verificationCode2 + this.verificationCode3;
    this.verificationCode.emit(verificationCode);
  }

  public focus() {
    this.vf0.nativeElement.focus();
  }
}
