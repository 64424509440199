<app-view-title Title="" [showBack]="false" [showHeader]="false" [overlay]="true" ></app-view-title>
<div style="background: #00c035; width: 1000vw; height: 100vh;">
  <view-content [centered]="true" [fullscreen]="true">
    <h1 style="padding: 25px 0;">{{ 'Intellitrailer.Home.UseUse4Free.lblHeadline' | translate : 'This app is no longer in use' }}</h1>
    <p>{{ 'Intellitrailer.Home.UseUse4Free.lblMessage' | translate : 'Please download and use the new Use4Free app to rent your trailer.' }}</p>
    <img src="/Styles/Use4Free/logo.png" style="margin: 25px auto" />
    <div>
      <button mat-raised-button color="accent" (click)="hinunterlassen()">
        {{ 'Intellitrailer.Home.UseUse4Free.lnkDownload' | translate : 'Get the app here' }}</button>
    </div>
  </view-content>
</div>

