import { Component, OnInit, Inject } from '@angular/core';
import { DialogBase, LocationInfo } from 't4core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-location-info',
  templateUrl: './location-info.component.html',
  styleUrls: ['./location-info.component.css']
})
export class LocationInfoComponent extends DialogBase implements OnInit {

    public loader: boolean = false;
    public location: LocationInfo;

    constructor(
      public dialogRef: MatDialogRef<LocationInfoComponent>,
      @Inject(MAT_DIALOG_DATA) public data: number) {
        super("LocationInfoComponent");
    }

  async ngOnInit(): Promise<void> {
      this.loader = true;
      await this.getLocationInfo();
      this.loader = false;
  }

  onNoClick(): void {
      this.dialogRef.close();
  }

    async getLocationInfo() {
        this.location = await this.Api.get<LocationInfo>("Location/GetLocationInfo", { locationId: this.data });
    }

}
