import { Component, OnInit, ElementRef } from '@angular/core';
import { PageBase, RentalInfo } from 't4core';
import { LayoutService } from 'app-components';
import { NavigationService } from 'app-components';
import { ActiveRentalService } from 'app-components';

@Component({
  selector: 'app-pickup-complete',
  templateUrl: './pickup-complete.component.html',
  styleUrls: ['./pickup-complete.component.css']
})
export class PickupCompleteComponent extends PageBase implements OnInit {
    public booking: RentalInfo;
  constructor(el: ElementRef, private aviationService: NavigationService, private rentalService: ActiveRentalService, private layoutService: LayoutService) {
    super(el);
  }

    async ngOnInit(): Promise<void> {
      this.booking = await this.rentalService.getBooking();
      setTimeout(() => this.next(), 2500);
  }

  public async next() {
    this.aviationService.executeCommand("Next");
  }
}
