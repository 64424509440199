<app-view-title Title="{{ 'Intellitrailer.Booking.UncofirmedBooking.TitleBookingInformation' | translate : 'Booking information' }}" [isPrimaryColor]="true"></app-view-title>
<div class="view-content" (scroll)="onScroll($event)">
  <app-booking-summary [confirmed]="false"
                       (addableTypesChanged)="updateAddons($event)"
                       [editingExistingBooking]="this.bookingState.existingBooking"
                       [booking]="bookingState.booking"
                       (bookingChange)="bookingUpdatedFromSummary($event)"
                       [atLocation]="bookingState.atLocation"
                       (changeSavedCard)="toggleSavedCard($event)"
                       (changeTerms)="toggleTerms($event)"
                       (changePrice)="updatePrice($event)"
                       [addonsFromPopup]="addonsFromPopup"
                       (sendNext)="goNext()"
                       (specialConditionChanged)="updateSpecialCondition($event)">
  </app-booking-summary>
<div style="height: 90px"></div>
</div>
<div class="view-actions" style="background: var(--main-color); border-radius: 20px 20px 0 0;">
  <button mat-raised-button class="wide" (click)="prepareForNext()" *ngIf="!this.bookingState.existingBooking && !terms" [disabled]="!terms">
    {{ 'Intellitrailer.Navigation.Scroll' | translate : 'Scroll down and confirm' }}
  </button>
  <button mat-raised-button class="wide" (click)="prepareForNext()" *ngIf="!this.bookingState.existingBooking && terms" [disabled]="!terms || !specialConditionApproved">
    {{ 'Intellitrailer.Navigation.Confirm' | translate : 'Confirm' }} ({{ currentPrice.DiscountedTotalPrice | currency }})
  </button>
  <button mat-raised-button class="wide" (click)="prepareForNext()" *ngIf="this.bookingState.existingBooking && !terms" [disabled]="!terms">
    {{ 'Intellitrailer.Navigation.Scroll' | translate : 'Scroll down and confirm' }}
  </button>
  <button mat-raised-button class="wide" (click)="prepareForNext()" *ngIf="this.bookingState.existingBooking && terms" [disabled]="!terms">
    {{ 'Intellitrailer.Navigation.SaveChanges' | translate : 'Save changes' }}
  </button>
</div>

