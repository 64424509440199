export interface DeviceStatus {
  Bluetooth: DeviceServiceState,
  Location: DeviceServiceState,
  Camera: DeviceServiceState,
  Nfc: DeviceServiceState,

  IsInitialized: boolean
}

export enum DeviceServiceState {
  None = 0,
  Available = 1,
  Permitted = 2,
  AvailableAndPermitted = 3
}
