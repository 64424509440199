<app-view-title Title="Välj alternativ"></app-view-title>
<div class="view-content">
  <div>
    <div style="display: flex; flex-wrap: wrap; background: white;">
      <ng-container *ngIf="myOption">
        <div class="title-with-divider">
          <div class="title">Din sökning</div>
        </div>
        <ow-booking-alternative class="col-md-4 type-group" [booking]="myOption" [unavailable]="!isMyOptionAvailable" (selected)="book($event)"></ow-booking-alternative>
        <div class="title-with-divider">
          <div class="title">Närliggande alternativ</div>
        </div>
      </ng-container>
      <ow-booking-alternative *ngFor="let option of availableOptions" class="col-md-4 type-group" [booking]="option" (selected)="book($event)"></ow-booking-alternative>
      <div *ngIf="!availableOptions" style="width: 100%; color: var(--gray); text-align: center;"></div>
    </div>
  </div>
  <div class="view-actions white-fade-out-bg" *ngIf="(bookingState && bookingState.booking && bookingState.booking.Id && bookingState.booking.PrimaryObjectTypeId)">
    <button *ngIf="!loading" type="button" mat-raised-button (click)="next()" [disabled]="!bookingState.booking.PrimaryType">{{ 'Intellitrailer.Navigation.Next' | translate : 'Next' }}</button>
  </div>
</div>

