export class FindLockResult {
  public Success: boolean;
  public Mac: string;
  public Session: string;
  public Version?: string;
  public Reason?: number;
}

export class UnlockResult {
    public Success: boolean;
    public Version?: string;
}

export class UnlockIglooPinResult {
  public Success: Boolean;
  public Version?: string;
  public Pin: string;
}
