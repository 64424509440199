<app-view-title [isSplitColor]="true" Title="{{ 'Intellitrailer.Rental.TakePictures.Headline' | translate : 'Photos' }}"></app-view-title>
<view-content [centered]="true" [fullscreen]="true">
  <app-cameraPermissionRequired *ngIf="!ignoreCameraCheck && appService.Device.Camera < 2" (next)="skipCameraCheck()" [permissionAsked]="permissionAsked"></app-cameraPermissionRequired>
  <ng-container *ngIf="ignoreCameraCheck || appService.Device.Camera >= 2">
    <mat-horizontal-stepper linear #stepper *ngIf="event != 'Pickup'" [(selectedIndex)]="currentStep" (selectionChange)="onSelectionChange($event)">

      <!-- Custom States -->
      <ng-template matStepperIcon="image">
        <mat-icon class="mat-icon-class">image</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="last">
        <mat-icon class="mat-icon-class">done_all</mat-icon>

        <!-- Done State -->
      </ng-template>
      <ng-template matStepperIcon="done">
        <mat-icon class="mat-icon-class">done</mat-icon>
      </ng-template>

      <!-- Changes the Edit Button state -->
      <ng-template matStepperIcon="edit">
        <mat-icon class="mat-icon-class">camera</mat-icon>
      </ng-template>

      <!-- Loops through our angle list for object, creates a stepper for each -->
      <mat-step *ngFor="let photo of photos" state="image" [stepControl]="photo.control">
        <ng-template matStepperIcon>
        </ng-template>

        <h3 class="rounded-border-card-primary-text">{{ 'T4.Database.PhotoAngle.' + photo.angle?.Id + '_Name' | translate : photo.angle?.Name }}</h3>
        <div style="padding:50px 25px 25px 25px">
          <app-photo-view #appPhotoView [photo]="photo" [event]="event" bookingId="{{ booking?.Id }}" (failed)="photoFailed()" (completed)="photoTaken(photo)" (deleted)="photoDeleted(photo)"></app-photo-view>
        </div>
        <p>{{ 'T4.Database.PhotoAngleDescription.' + photo.angle?.Id + '_Name' | translate : photo.angle?.Description }}</p>
        
      </mat-step>

      <!-- Summary of the Images taken -->
      <mat-step state="last">
        <ng-template matStepperIcon></ng-template>
        <h3 class="rounded-border-card-primary-text">{{ 'Intellitrailer.Rental.Summary.Summary' | translate : 'Summary' }}</h3>
        <div class="summary-container">
          <ng-container *ngFor="let photo of photos">
            <p> {{ 'T4.Database.PhotoAngle.' + photo.angle?.Id + '_Name' | translate : photo.angle?.Name }} </p>
            <img [src]="photo?.data ? 'data:image/jpg;base64,' + photo.data : fallbackImage" class="summary-img-box" (click)="goToStep(photo)" />
          </ng-container>
          <h3 class="rounded-border-card-primary-text"> {{ 'Intellitrailer.Rental.Summary.AdditionalPhotos' | translate : 'Additional Photos' }}</h3>
          <app-photo-view *ngFor="let p of additionalPhotosArray; let index = index" [event]="event" [photo]="p" bookingId="{{ booking?.Id }}" (failed)="photoFailed()" (completed)="additionalPhotoTaken(p)" (deleted)="additionalPhotoDeleted(p)"></app-photo-view>
        </div>
      </mat-step>
    </mat-horizontal-stepper>

    <ng-container *ngIf="event == 'Pickup'">
      <div class="summary-container">
        <h3 class="rounded-border-card-primary-text">{{ 'Intellitrailer.Rental.Pickup.DocumentIssue' | translate : 'Document Issue or Damage' }}</h3>
        <p> {{ 'Intellitrailer.Rental.Pickup.DocumentDescription' | translate : 'Please take a clear picture to document any issues or damage you´ve noticed with the trailer.' }}</p>
        <app-photo-view *ngFor="let p of additionalPhotosArray; let index = index" [event]="event" [photo]="p" bookingId="{{ booking?.Id }}" (failed)="photoFailed()" (completed)="additionalPhotoTaken(p)" (deleted)="additionalPhotoDeleted(p)"></app-photo-view>
      </div>
    </ng-container>
    <div class="view-actions" *ngIf="event != 'Pickup' && stepper">
      <button *ngIf="stepper.selected?.stepControl?.valid" mat-raised-button color="primary" (click)="stepper.next()">{{ 'Intellitrailer.Rental.PhotoBtn.Next' | translate: 'Next'}}</button>
      <button *ngIf="!stepper.selected?.stepControl" mat-raised-button color="primary" (click)="confirm()">{{ 'Intellitrailer.Rental.PhotoBtn.Confirm' | translate: 'Confirm'}}</button>
    </div>

    <div class="view-actions" *ngIf="event == 'Pickup'">
      <button mat-raised-button color="primary" [disabled]="additionalPhotosArray.length <= 1" (click)="confirm()">{{ 'Intellitrailer.Rental.PhotoBtn.Confirm' | translate: 'Confirm'}}</button>
    </div>
  </ng-container>
</view-content>
<view-actions>
  <button *ngIf="stepper?.selected?.stepControl?.invalid" mat-raised-button color="primary" (click)="simulateChildClick()">{{ 'Intellitrailer.Rental.PhotoBtn.TakePicture' | translate: 'Take Picture'}}</button>
</view-actions>

