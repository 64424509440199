<app-view-title Title="{{ 'Intellitrailer.Rental.Component.ExtendRental.Extend' | translate : 'Extend' }}" [isSplitColor]="true"></app-view-title>
<view-content [centered]="true" [fullscreen]="true">
  <p *ngIf="booking.ReturnTime">{{ 'Intellitrailer.Booking.ExtendBookingTimePicker.CurrentTime' | translate : 'Current time return'}} : {{ this.booking.ReturnTime.toDate() | date:'MMM dd, HH:mm' | uppercase }}</p>

  <div class="col-12 col-md-6">
    <div class="title-with-divider">
      <div class="title"> {{'Intellitrailer.Booking.ExtendBookingTimePicker.ChoseNewTime' | translate : 'Choose new return time'}} </div>
    </div>
    <ng-container>
      <div class="datepicker-wrapper">
        <div class="datepicker-left-col">
          <span class="material-icons" (click)="setReturnDate(null, -1)" [ngClass]="isSameDay(this.returnDate, this.today.toDate()) ? 'unselectable' : 'selectable'">
            keyboard_arrow_left
          </span>
        </div>
        <div class="datepicker-col text-center">
          <mat-form-field>
            <input #returnInput class="input-center" matInput [matDatepicker]="return" [(ngModel)]="returnDate" [min]="this.today.toDate()" (dateChange)="setReturnDate($event.value)" (focus)="return.open()">
            <mat-datepicker-toggle matSuffix [for]="return"></mat-datepicker-toggle>
            <mat-datepicker #return (closed)="returnInput.blur()"></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="datepicker-right-col">
          <span class="material-icons" (click)="setReturnDate(null, 1)">
            keyboard_arrow_right
          </span>
        </div>
      </div>
      <div style="min-width: 100%">
        <timePicker #returnTime
                    [booking]="booking"
                    [minTime]="minReturn"
                    [maxTime]="maxReturn"
                    [selectedTime]="_returnTime"
                    [timeSlots]="returnSlots"
                    (selectedTimeChange)="setReturnTime($event)"
                    steps="quarter"
                    [loader]="timePickerLoading"
                    [isClosed]="returnClosed">
        </timePicker>
      </div>
    </ng-container>
  </div>
  <p style="margin:20px;">{{ 'Intellitrailer.Booking.ExtendBookingTimePicker.NewTime' | translate : 'New time return'}} : {{ _returnTime.toDate() | date:'MMM dd, HH:mm' | uppercase}}</p>
  <div style="display: inline-flex; align-items: center;">
    <p style="margin:25px; font-weight:bold; font-size:1.2em;">
      {{ 'Intellitrailer.Booking.ExtendBookingTimePicker.AdditionalCost' | translate : 'Additional Cost'}} : <span *ngIf="!priceLoading">{{ additionalCost | currency }}</span>
    </p>
    <mat-spinner diameter="20" *ngIf="priceLoading"></mat-spinner>
  </div>
  <div *ngIf="loader" class="componentLoader">
    <mat-progress-spinner diameter="75" mode="indeterminate"></mat-progress-spinner>
  </div>
</view-content>
  <view-actions>
    <button mat-raised-button color="accent" (click)="cancel()">{{ 'Intellitrailer.Rental.Component.ExtendRental.cmdCancel' | translate : 'Cancel' }}</button>
    <button mat-raised-button color="primary" [disabled]="!extendButtonEnabled || booking.ReturnTime.toDate().toISOString() == _returnTime.toDate().toISOString() || priceLoading" (click)="extend()">{{ 'Intellitrailer.Rental.Component.ExtendRental.Extend' | translate : 'Extend' }}</button>
  </view-actions>
  
