<div class="wizard">
<div class="wrapper d-flex align-items-center" (click)="close()">
  <div class="box" (click)="preventClose()">
    <div class="finish">
      <button mat-button mat-raised-button *ngIf="activeStep !== numberOfSteps.length - 1" (click)="nextTab()" cdkFocusInitial>{{ 'Intellitrailer.Component.NewUserInfo.Next' | translate : 'Next' }}</button>
      <button mat-button mat-raised-button *ngIf="activeStep === numberOfSteps.length - 1" (click)="close()" cdkFocusInitial>{{ 'Intellitrailer.Component.NewUserInfo.TakeMeHome' | translate : 'Take me to the homepage' }}</button>
    </div>
    <mat-tab-group class="new-user-info-tab-group" [(selectedIndex)]="activeStep" (touchstart)="swipeStart($event)" (touchend)="swipeEnd($event)">
      <mat-tab>
        <div class="box-content">
          <div class="container">
            <div class="row text-center">
              <div class="image-placeholder" style="margin-top: -50px">
                <img class="logo" src="/Styles/{{appSettings.settings.theme}}/Logo.png" style="max-width: 250px; max-height: 250px;" />
              </div>
             <!-- <p>{{ 'Intellitrailer.Component.StartWizars.WelcomeNewUser' | translate : 'Welcome to Book, Swipe, Go!' }}</p>-->
              <p>{{ 'Intellitrailer.SelectFamily.txtWelcomeMessage.' + theme | translate: 'Welcome to ' + theme + '!' }}</p>
            </div>
          </div>
          <!-- SVG Animation fully functional, but might encourage a swipe-behaviour the rest of the app doesn't use.
            <svg viewBox="-60 -12 80 25" id="animation-box">
            <circle id="show-swipe-right" r="3"></circle>
          </svg>-->
        </div>
      </mat-tab>
      <mat-tab>
        <div class="box-content">
          <div class="container">
            <div class="row text-center">
              <div class="image-placeholder start-wizard-step1">
              </div>
              <p>{{ 'Intellitrailer.Component.StartWizars.Step0' | translate : 'Enable Bluetooth and location services on your device and make sure the app has access to them.' }}</p>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab>
        <div class="box-content">
          <div class="container">
            <div class="row text-center">
              <div class="image-placeholder start-wizard-step2"></div>
              <p>{{ 'Intellitrailer.Component.StartWizars.Step1' | translate : 'Use the app to book and unlock a trailer' }}</p>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab>
        <div class="box-content">
          <div class="container">
            <div class="row text-center">
              <div class="image-placeholder start-wizard-step1"></div>
              <p>{{ 'Intellitrailer.Component.StartWizars.Step2' | translate : 'Pick your trailer up at any of our locations' }}</p>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab>
        <div class="box-content">
          <div class="container">
            <div class="row text-center">
              <div class="image-placeholder start-wizard-step3"></div>
              <p>{{ 'Intellitrailer.Component.StartWizars.Step3' | translate : 'Return the trailer in the same condition you found it in.' }}</p>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
    <div class="box-step">
      <div class="step-circle" *ngFor="let option of numberOfSteps; let i = index" [ngClass]="activeStep == i ? 'normal-circle' : 'active-circle'"></div>
      <button type="button" *ngIf="activeStep != numberOfSteps.length - 1" id="skip" class="btn btn-link" (click)="close()">
        {{ 'Intellitrailer.Component.NewUserInfo.Skip' | translate : 'Skip' }}
      </button>
    </div>
  </div>
</div>
</div>
