import { Component, OnInit, ElementRef } from '@angular/core';
import { PageBase, CustomerInfo } from 't4core';
import { NavigationService } from 'app-components';
import { RentalAgreementComponent } from '../../dialogs/rental-agreement/rental-agreement.component';
import { AppSettingsService } from 't4-app-integration';
import { BookingService } from 'app-components';

@Component({
  selector: 'app-setup-customer',
  templateUrl: './setup-customer.component.html',
  styleUrls: ['./setup-customer.component.css']
})
export class SetupCustomerComponent extends PageBase implements OnInit {
  public customer: CustomerInfo = null;
  public validCustomer = false;

  public hasGDPR: boolean = true;
  public confirmGDPR: boolean = false;
  public errGdpr: boolean = false;

  public labels: any = {
    title: '',
    gdpr: '',
    confirmGdpr:'',
    next:''
  };
  
  constructor(private navigator: NavigationService,
    el: ElementRef,
    private appSettings: AppSettingsService,
    private bookingService: BookingService
  ) {
    super(el);
  }

  async ngOnInit() {
    var token = this.UI.beginLoading("Intellitrailer.Booking.SetupCustomer.msgLoadingCustomer", "Loading customer", null);
    // Setup the customer
    var cus = await this.Api.get<CustomerInfo>("/Rental/FindCustomerByUser");

    if (!cus) cus = new CustomerInfo();

    this.bookingService.Query.Customer = cus;
    this.UI.loaderCompleted(token);


    // Skip if no customer settings
    if (!this.Settings.clientSettings.CustomerSettings) this.navigator.executeCommand("Next")

    // Skip i no visible fields other than Email & MobilePhone
    if (!this.Settings.clientSettings.CustomerSettings.Fields.some(x => x.FieldId != "Email" && x.FieldId != "MobilePhone" && x.Visible)) this.navigator.executeCommand("Next")

    //GPDR link if has GDPR terms
    this.hasGDPR = this.appSettings.settings.GDPRTerms ? true : false;


    this.customer = cus;

    this.labels.gdpr = 'GDPR';
    this.labels.title = await this.Translate.translate('turbo.core.RentalDialog.CustomerMyDetails', 'My details');
    this.labels.confirmGdpr = await this.Translate.translate('Intellitrailer.MyPage.ValidateAccount.ConfirmGDPR', 'I authorize the use of my personal data in compliance with GDPR.');
    this.labels.next = await this.Translate.translate('Intellitrailer.Navigation.Next', 'Next');
  }

  public async next(isFormValid: boolean) { // Validate
    if (this.hasGDPR && !this.confirmGDPR) {
      this.errGdpr = true;
      return;
    }
    else if (!isFormValid) {
      return;
    }
    await this.saveCustomer();

    this.navigator.executeCommand("Next");
  }

  public async showTerms() {
    this.Dialog.open(RentalAgreementComponent, { data: { agreementType: "GDPR" } });
  }

  private async saveCustomer(): Promise<boolean> {
    let obj = await this.bookingService.saveCustomer(false);
    return obj != null;
  }
}
