<div class="container">
  <div class="row">
    <mat-form-field class="col-3 text-center">
      <input #vf0
             matInput
             type="tel"
             [(ngModel)]="verificationCode0"
             autocomplete="off"
             maxlength="1"
             class="text-center"
             (keyup)="focusNext(0, $event)" style="color: black" />
    </mat-form-field>
    <mat-form-field class="col-3 text-center">
      <input #vf1
             matInput
             type="tel"
             [(ngModel)]="verificationCode1"
             autocomplete="off"
             maxlength="1"
             class="text-center"
             (keyup)="focusNext(1, $event)" style="color: black" />
    </mat-form-field>
    <mat-form-field class="col-3 text-center">
      <input #vf2
             matInput
             type="tel"
             [(ngModel)]="verificationCode2"
             autocomplete="off"
             maxlength="1"
             class="text-center"
             (keyup)="focusNext(2, $event)"  style="color: black"/>
    </mat-form-field>
    <mat-form-field class="col-3 text-center">
      <input #vf3
             matInput
             type="tel"
             [(ngModel)]="verificationCode3"
             autocomplete="off"
             maxlength="1"
             class="text-center"
             (keyup)="focusNext(3, $event)"  style="color: black"/>
    </mat-form-field>
    <div class="col-12" style="font-size: 14px; font-weight: bold; color: red" *ngIf="!validateCode">
      {{ 'Intellitrailer.MyPage.ValidateAccount.InvalidCode' | translate : 'The code entered was invalid.' }}
    </div>
  </div>
</div>
