<app-view-title Title="{{bookingState.booking.PrimaryType.Title}}"></app-view-title>
<div class="view-content no-padding">

  <div class="trailer-icon-big d-block" style="margin:20px;" [style.background-image]="'url(' + bookingState.booking.PrimaryType.PictureUri + ')'" *ngIf="bookingState.booking.PrimaryType.PictureUri"></div>
  <app-card style="text-align:center">
    <h5>{{ 'Intellitrailer.Booking.selectedObjectInfo.Title' | translate : 'Information' }} </h5>
    <br />
    <p>
      {{'Intellitrailer.Booking.selectedObjectInfo.txtInfo_' + bookingState.booking.PrimaryType.Id | translate:  'The varubil is not a part of the Use4Free concept other than the ability to make the booking through our app. Therefore, the car cannot be used for 3 hours for free.'}}
      <br /> <br />
       {{ 'Intellitrailer.Booking.selectedObjectInfo.txtThanks' | translate: 'Thank you for your understanding.' }}
    </p>
  </app-card>
</div>
<div class="view-actions">
  <button type="button" mat-raised-button (click)="next()">{{ 'Intellitrailer.Booking.selectedObjectInfo.Confirm' | translate : 'Confirm'
      }}</button>
</div>

