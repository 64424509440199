<app-view-title Title="{{ 'Intellitrailer.Rental.LockTrailer.Headline' | translate : 'Lock the trailer' }}"></app-view-title>
<div class="view-content centered-view">
  <app-information-screen image="/assets/images/photos/lock_mounted.jpg">
    {{ 'Intellitrailer.Rental.LockTrailer.ReturnProcessInfo' | translate : 'To complete the return process you must return the trailer to the return location and lock it using the provided cover and padlock.' }}
  </app-information-screen>
</div>
<div class="view-actions">
  <button mat-raised-button (click)="next()">{{ 'Intellitrailer.Rental.LockTrailer.cmdNext' | translate : 'Next' }}</button>
</div>

