import { Component, ElementRef } from '@angular/core';
import { BookingModel, BookingService, LayoutService, NavigationService } from 'app-components';
import { AppIntegrationService, AppSettingsService, AppTypeSettings } from 't4-app-integration';
import {  PageBase, T4RentalObjectService, TypeCategoryInfo } from 't4core';
import { Country, getCountryByLanguageCode, getCountryDataByName } from '../../../../lib/Models/Country';

@Component({
  selector: 'select-family-component',
  templateUrl: './select-family-type.html',
  styleUrls: ['./select-family-type.css']
})
export class SelectFamilyType extends PageBase {

  public filterdCategoryList: TypeCategoryInfo[] = [];
  public typeCategoryList: TypeCategoryInfo[] = [];
  public bookingState: BookingModel;
  public typeSettings: AppTypeSettings;
  public userClosed: boolean = false;

  public theme: string;
  public language: string = this.appSettings.getLanguage();

  public anticipatedUserCountry: any;
  public filterCountry: Country;

  public loading: boolean = false;

  logoSrc: string = "/Styles/" + this.appSettings.settings.theme + "/Logo.png";
  logoFallback: string = "/Styles/" + this.appSettings.settings.theme + "/Logo.png";

  constructor(private navigator: NavigationService, el: ElementRef, private appService: AppIntegrationService, private BookingDataSerivce: BookingService, public layout: LayoutService, private objSvc: T4RentalObjectService, public appSettings: AppSettingsService, private bookingSvc: BookingService) {
    super(el);

    this.typeSettings = this.appSettings.settings.typeSettings;
    this.userClosed = localStorage.getItem('newInfoClosed') ? true : false;
  }

  async ngOnInit() {
    //Get theme.
    this.theme = this.appSettings.settings.theme || 'Default';

    this.loading = true;

    await this.BookingDataSerivce.startSession();

    // Skip this step if location is already selected
    if (this.appSettings.getParam("pickupLocationId")) {
      this.navigator.executeCommand("Map");
    }
    this.bookingState = this.BookingDataSerivce.getBookingState();

    this.anticipatedUserCountry = this.appSettings.getAnticipatedUserCountry();

    await this.getFamilyTypes();

    this.loading = false;
  }

  

  public async getFamilyTypes() {
    this.typeCategoryList = await this.BookingDataSerivce.getCategories();

    //Check if we have set a anticipated country, if we cold loaded into the map. etc
    //Location
    //Lanuguage
    //Brower Language
    if (this.anticipatedUserCountry) {
      this.filterCountry = getCountryDataByName(this.anticipatedUserCountry);
    }
    else {
      this.filterCountry = getCountryByLanguageCode(this.language);
    }
   

    //Filters on the Context, If they dont have a selected cotunry then it would be shown for all.
    //Set EN as a show all option for now.
    this.filterdCategoryList = this.typeCategoryList.filter(x => {
      return x.Countries == undefined || x.Countries.length == 0 || x.Countries.indexOf(this.filterCountry.shortName.toUpperCase()) !== -1
    })
  }

  public async selectType(category: TypeCategoryInfo) {

    this.bookingState.objectCategory = category;

    this.BookingDataSerivce.saveState(this.bookingState);

    var temp = await this.BookingDataSerivce.getBookingState();

    this.navigator.executeCommand("Map");
  }

  public back() {
    this.navigator.back();
  }


  public next() {

  }

  public skip() {
    this.navigator.executeCommand("Map");
  }

  public mybooking() {
    let user = this.appSettings.getUserId();
    if (!user) {
      this.navigator.executeCommand("SignIn");
    }
    else {
      this.navigator.executeCommand("Bookings");
    }
  }

  public toggleNewUserInfo(bool = true) {
    localStorage.setItem('newInfoClosed', 'true');
    this.userClosed = bool;
  }


  public logotypeFallback() {
    this.logoSrc = this.logoFallback;
  }
}

