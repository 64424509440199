<div class="photoView" (click)="takePhoto()">
  <mat-progress-spinner mode="indeterminate" diameter="50" *ngIf="working"></mat-progress-spinner>

  <div class="angle-image-container" *ngIf="!working">
    <ng-container *ngIf="photo.data">
      <mat-icon class="delete-icon mat-icon-class" (click)="deletePhoto($event)">cancel</mat-icon>
      <img [src]="'data:image/jpg;base64,' + photo.data" class="image-box" />
    </ng-container>
    <ng-container *ngIf="!photo.data && photo.taken">
      <img [src]="fallbackImage" class="image-box" />
    </ng-container>
    <ng-container *ngIf="!photo.data && !photo.taken">
      <img *ngIf="photo.angle" src="{{ photo.angle.ImageUri }}" class="image-box-default" />
      <div class="photo-icon" *ngIf="!photo.angle"></div>
    </ng-container>

  </div>
</div>

