import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ComponentBase } from 't4core';
import { LayoutService } from '../../Services/LayoutService/layout.service';

@Component({
  selector: 'app-view-title',
  template: ''
})
export class ViewTitleComponent extends ComponentBase implements OnInit {

  @Input() public Title: string;

  @Input() public showHeader: boolean;
  @Input() public showBack: boolean;
  @Input() public showMenu: boolean;
  @Input() public overlay: boolean;

  @Input() public isPrimaryColor: boolean;
  @Input() public isSplitColor: boolean;
  @Input() public isGreenColor: boolean;
  @Input() public isRedColor: boolean;


  constructor(private layout:LayoutService) { super(); }

  ngOnInit(): void {
    setTimeout(() => {
      this.layout.update(
        {
          title: this.Title,
          header: {
            isPrimaryColor: this.isPrimaryColor,
            isGreen: this.isGreenColor,
            isRed: this.isRedColor,
            showBack: this.showBack,
            showMenu: this.showMenu,
            visible: this.showHeader,
            overlay: this.overlay,
            isSplitColor: this.isSplitColor
          }
        });
    }, 1);
  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      this.layout.update(
        {
          title: this.Title,
          header: {
            isPrimaryColor: this.isPrimaryColor,
            isGreen: this.isGreenColor,
            isRed: this.isRedColor,
            showBack: this.showBack,
            showMenu: this.showMenu,
            visible: this.showHeader,
            overlay: this.overlay,
            isSplitColor: this.isSplitColor 
          }
        });
    }, 100);
  }
}
