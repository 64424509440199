import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { PageBase } from 't4core';

@Component({
  selector: 'app-on-load',
  templateUrl: './on-load.component.html',
  styleUrls: ['./on-load.component.css']
})
export class OnLoadComponent extends PageBase implements OnInit {
    @Input()
    get load(): string {
        return this.load;
    }

    set load(load: string) {
        setTimeout(() => { this.loaded.emit(load); }, 5000);
    }

    @Output() loaded = new EventEmitter<string>();

  constructor(el: ElementRef) { super(el) }

  ngOnInit(): void {
  }

}
