import { Component, OnInit, ElementRef } from '@angular/core';
import { PageBase, RentalInfo, ReturnValidation, ReturnResultAction, ReturnValidationResult } from 't4core';
import { NavigationService } from 'app-components';
import { ActiveRentalService } from 'app-components';
import * as moment from 'moment';

@Component({
  selector: 'app-return-payment',
  templateUrl: './return-payment.component.html'
})
export class ReturnPaymentComponent extends PageBase implements OnInit {

    public returnValidation: ReturnValidation;
    public booking: RentalInfo;

    public returnValidationResult = ReturnValidationResult;

  constructor(el: ElementRef, private aviationService: NavigationService, private rentalService: ActiveRentalService) { super(el); }

    async ngOnInit(): Promise<void> {
      this.booking = await this.rentalService.getBooking();
      this.returnValidation = await this.Api.post("Rental/ValidateForReturn", null, { rentalId: this.booking.Id, returnTime: moment().toISOString(), returnLocation: this.booking.ReturnLocationId });

      // If validation success (no extra payment) continue immediately
      if (this.returnValidation.Result == ReturnValidationResult.Valid)
        this.aviationService.executeCommand("Confirm", { rentalId: this.booking.Id });
      else {
        this.Api.get<boolean>("/Rental/SetRentalEvent", { rentalId: this.booking.Id, content: "Presenting return fee: " + this.returnValidation?.ReturnPrice?.DiscountedTotalPrice + "kr" });
      }
    }

  public async next() {
    this.aviationService.executeCommand("Pay", { rentalId: this.booking.Id });
  }
}
