<app-view-title [showHeader]="false"></app-view-title>
<div class="view-content-full centered-view" *ngIf="loading">
  <div class="row mb-3">
    <div class="col-12">
      <div>
        <mat-progress-spinner diameter="75" mode="indeterminate" class="d-inline-block"></mat-progress-spinner>
        <div class="fetching-location-progress">{{ 'Intellitrailer.Booking.CreateBooking.SavingBookingTitle' | translate : 'Saving booking' }}...</div>
        <div class="fetching-location-info" innerHTML="{{ 'Intellitrailer.Booking.CreateBooking.SavingBookingExplanation' | translate : 'Saving the booking, please wait.' }}"></div>
        <div>
          <button mat-raised-button color="primary" (click)="back()">{{ 'Intellitrailer.Rental.DetectLock.Cancel' | translate : 'Cancel' }}</button>
        </div>
      </div>
    </div>
  </div>
</div>

