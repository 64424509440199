<app-view-title Title="{{ 'Intellitrailer.Booking.SelectCategory.TitleSelectType' | translate : 'Select trailer type' }}"></app-view-title>
<div class="view-content">

  <div class="view-title">
    <div class="icon-wrapper-left" (click)="back()">
      <span class="material-icons">
        west
      </span>
    </div>
    <div class="title">Välj släptyp</div>
    <div class="icon-wrapper-right">
      <span class="material-icons">
        close
      </span>
    </div>
  </div>

  <div *ngIf="true" class="title-without-divider first-title">
    <div class="title">{{ 'Intellitrailer.Booking.BeginBooking.ChooseTrailerType' | translate : 'Choose trailer type' }}</div>
  </div>

  <div class="category-wrapper" *ngIf="categories">
    <app-category *ngFor="let category of categories; let i = index" (click)="selectCategory(category)" [category]="category" [checked]="bookingState && bookingState.objectCategories[0] && bookingState.objectCategories[0].Id == category.Id && bookingState.booking && bookingState.booking.Id"></app-category>
  </div>

  <div *ngIf="priceInfo" class="title-without-divider first-title">
    <div class="title">{{ 'Intellitrailer.Booking.BeginBooking.PriceInfo' | translate : 'PriceInformation' }} trailer</div>
  </div>

  <div class="price-info mb-2" *ngIf="priceInfo">
    <div class="price-info-item" *ngFor="let price of priceInfo" [ngStyle]="{'width': 100 / priceInfo.length + '%'}">
      <div class="price-info-item-title-wrapper">
        <div class="price-info-item-title">{{ price.name }}</div>
      </div>
      <div class="price-info-item-value">{{ price.value }}</div>
    </div>
  </div>
  <div style="font-size: 13px; text-align: center;" *ngIf="priceInfoAsHTML" [innerHTML]="priceInfoAsHTML">
  </div>
  <div style="height: 90px"></div>
</div>
<div class="view-actions white-fade-out-bg" [ngClass]="(bookingState && bookingState.objectCategories && bookingState.objectCategories[0] && bookingState.booking && bookingState.booking.Id) ? 'show' : 'hide'">
  <button type="button" mat-raised-button (click)="next()" [disabled]="false">{{ 'Intellitrailer.Navigation.Next' | translate : 'Next' }}</button>
</div>

