import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { PageBase, RentalInfo, RentalStatus } from 't4core';
import { AppIntegrationService, AppSettingsService } from 't4-app-integration';
import { NavigationResolver, NavigationService } from 'app-components';
import { ActiveRentalService } from 'app-components';
import { LayoutService } from 'app-components';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-noke-lock',
  templateUrl: './noke-lock.component.html',
  styleUrls: ['./noke-lock.component.css']
})

export class NokeLockComponent extends PageBase implements OnInit {

  public count = 0;
  public unlocked = false;

  public booking: RentalInfo;
  public status = RentalStatus;
  public firstLoad: boolean = true;
  public objectId: number;
  public unlockingInfo: string;
  public alternativesLoaded: boolean = false;
  public isAndroid: boolean = false;
  public isBauhaus: boolean = false;

  public event: string = ""; // Pickup/Return/Active etc.

  public ignoreBTCheck: boolean = false;
  public ignoreLocationCheck: boolean = false;

  // States
  public isWaiting: boolean = true;
  public isSearching: boolean = false;
  public isUnlocking: boolean = false;
  public lockNotFound: boolean = false;
  public hasFailed: boolean = false;
  public recentlyUnlocked: boolean = false;

  public failReason: number;

  private timerSubscription: Subscription;

  constructor(el: ElementRef, public appSvc: AppIntegrationService, private aviationService: NavigationService, private rentalService: ActiveRentalService, public layoutService: LayoutService, public resolver: NavigationResolver, public appSettings: AppSettingsService) {
    super(el);
    this.appSvc.requestDeviceState();

    this.event = this.aviationService.getParam("event");
    this.timerSubscription = this.appSvc.getSecondTimer().subscribe(x => {
      if (this.isWaiting) this.appSvc.requestDeviceState();
      if (this.isWaiting && (this.ignoreBTCheck || this.appSvc.Device.Bluetooth >= 2) && (this.ignoreLocationCheck || this.appSvc.Device.Location >= 2) && !this.recentlyUnlocked) this.beginUnlock();

      if (!this.isWaiting && !this.hasFailed) {

        if (this.count < 30) {
          this.count++;
        }
      }
    });
  }

  async ngOnInit() {
    this.booking = await this.rentalService.getBooking();
    this.Api.get<boolean>("/Rental/SetRentalEvent", { rentalId: this.booking.Id, content: "Begin unlock!" });

    this.recentlyUnlocked = await this.rentalService.checkAndUpdateLockTimer();

    this.objectId = this.booking.ObjectId;
    if (this.isWaiting && (this.ignoreBTCheck || this.appSvc.Device.Bluetooth >= 2) && (this.ignoreLocationCheck || this.appSvc.Device.Location >= 2) && !this.recentlyUnlocked) this.beginUnlock();
  }

  public next() {
    this.count = 0;
    if (this.isWaiting) {
      this.isWaiting = false;
      this.isSearching = true;
    } else if (this.isSearching) {
      this.isSearching = false;
      this.isUnlocking = true;
    } else {
      this.timerSubscription.unsubscribe();
      this.aviationService.executeCommand("Next");
    }
  }

  private fail(reason?: number) {
    if (this.hasFailed) return;
    this.hasFailed = true;

    if (this.isSearching) {
      this.isSearching = false;
      this.lockNotFound = (reason == null);
    } else if (this.isUnlocking) {
      this.isUnlocking = false;
    }

    this.hasFailed = true;

    if (reason)
      this.failReason = reason;
    else {
      if (this.appSvc.Device.Location < 2)
        this.failReason = 10;
      else if (this.appSvc.Device.Location < 3)
        this.failReason = 20
    }

    if (!reason && this.retryCount > 1) {
      this.failReason = -1;
    }
  }

  public skip() {
    this.aviationService.executeCommand("Skip");
  }

  private retryCount: number = 0;
  public retry() {
    this.retryCount++;

    this.count = 0;
    if (this.hasFailed) {
      this.lockNotFound = false;
      this.hasFailed = false;
      this.failReason = null;
      this.isWaiting = true;
      this.recentlyUnlocked = false;
      this.beginUnlock();
    }
  }

  public continueUnlock() {
    if (this.recentlyUnlocked) {
      this.recentlyUnlocked = false;
      this.beginUnlock();
    }
  }

  public openSettings() {
    this.appSvc.openPermissions();
  }

  private emulateUnlockProcess() {
    // Search for 5 seconds
    setTimeout(() => {
      this.next();
    }, 5000);

    // Unlock for another 5
    setTimeout(async () => {
      await this.next();
    }, 10000);

  }

  public async beginUnlock() {
    this.next();

    // Demo setting: Emulate unlock
    if (localStorage.getItem('bypassLock') && localStorage.getItem('bypassLock') == "true") {
      this.emulateUnlockProcess()
    }
    else {
      // Determine which locks to look for
      var lockMacs = this.booking.RentalObject.Locks.map(x => x.Mac).join(';');
      if (localStorage.getItem('unlockAnyLock') && localStorage.getItem('unlockAnyLock') == "true") {
        lockMacs = 'any';
      }

      // Ask the device to start searching
      this.Insights.logEvent("Looking for lock: " + lockMacs);
      var ts = new Date();
      var ret = await this.appSvc.findOneLockOfMany(lockMacs);
      var diff = new Date().getTime() - ts.getTime();
      this.Insights.logEvent("Timing: " + diff + "ms");

      // Handle result
      if (ret && ret.Success) {
        // Log finding
        var info = "Found lock: " + ret.Mac;
        if (this.booking) this.Api.get<boolean>("/Rental/SetRentalEvent", { rentalId: this.booking.Id, content: info });
        this.Insights.logEvent(info);

        // Continue process
        this.next();
      } else {
        this.fail(ret?.Reason);

        // Log
        var info = "Could not connect to the lock.";
        if (ret?.Reason == null) {


          if (this.appSvc.Device.Location < 2)
            info += ', Location not permitted';
          else if (this.appSvc.Device.Location < 3)
            info += ', Location not enabled';
          else
            info += ' Timed out';

          this.Insights.logEvent(info);
        }
        else if (ret?.Reason == 0) {
          this.Insights.logEvent("BT access denied");
          info += " Bluetooth permission is not granted"
        }
        else if (ret?.Reason == 1) {
          this.Insights.logEvent("BT not enabled");
          info += " Bluetooth is not enabled on device";
        }
        else {
          this.Insights.logEvent("Unknown lock error");
          info += " Unknown error";
        }

        if (this.booking) this.Api.get<boolean>("/Rental/SetRentalEvent", { rentalId: this.booking.Id, content: info });

        return;
      }

      // Unlock the device
      var unlockRet = await this.appSvc.unlockNoke(ret.Mac, this.booking.Id, ret.Session);

      if (unlockRet && unlockRet.Success) {
        this.appSvc.updateLockStatus(ret.Mac, (ret.Version ? ret.Version : null));
        this.Insights.logEvent("Unlocked" + ret.Mac);
        if (this.booking) this.Api.get<boolean>("/Rental/SetRentalEvent", { rentalId: this.booking.Id, content: "Unlocked!" });
        this.next()
      }
      else {
        this.Insights.logEvent("Unlocked failed!");
        if (this.booking) this.Api.get<boolean>("/Rental/SetRentalEvent", { rentalId: this.booking.Id, content: "Unlocked failed!" });
        this.fail();
      }
    }
  }

  public skipBTCheck() {
    this.ignoreBTCheck = true;
  }

  public skipLocationCheck() {
    this.ignoreLocationCheck = true;
  }
}
