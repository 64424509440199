import { Component, Input, OnInit } from '@angular/core';
import { RentalObjectInfo, ComponentBase, ObjectTypeInfo, PriceModel, RentalInfo, RentalStatus } from 't4core';
import * as moment from 'moment';
import { BookingService } from 'app-components';
import { AppIntegrationService } from 't4-app-integration';

@Component({
  selector: 'app-booking-option',
  templateUrl: './booking-option.component.html',
  styleUrls: ['./booking-option.component.css']
})
export class BookingOptionComponent extends ComponentBase implements OnInit {
 public object: RentalObjectInfo;
 public type: ObjectTypeInfo;
  @Input() public booking: RentalInfo;


  public typeImage: string;

  public showPrice: boolean = true;
  public Price: PriceModel;
  public loadingPrice: boolean = true;

  constructor(public bookingService: BookingService, private appService: AppIntegrationService) {
    super();
  }
  async ngOnInit() {
    var priceInfo = await this.bookingService.getPriceInfo();
    if (priceInfo && (priceInfo as any).UsesGpsBasedBookingFee) this.showPrice = false;
    this.getPrice();

    if (this.booking?.ObjectId) this.getObject();
    else this.getType();
  }

  private async getType() {
    if (this.booking.PrimaryType) {
      this.type = this.booking.PrimaryType;
    } else if (this.booking.PrimaryObjectTypeId) {
      this.type = this.booking.PrimaryType = await this.Api.get<ObjectTypeInfo>("/RentalObject/GetTypeInfo", { objectType: this.booking.PrimaryObjectTypeId });
    }

    this.typeImage = this.type.PictureUri;
  }

  private async getObject() {
    if (!this.booking.ObjectId) return;
    this.object = this.booking.RentalObject = await this.Api.get<RentalObjectInfo>("/RentalObject/GetObjectInfo", { objectId: this.booking.ObjectId });
  }

  private async getPrice() {
    if (!this.booking || !this.showPrice) return;

    this.loadingPrice = true;

    var currentCoordinates = await this.appService.getCoordinates();
    if (this._isDestroyed) return;

    // TODO Can the one property we actually use - discountableTotalPrice - be returned with GetAllTypes instead?
    let price: PriceModel = await this.Api.get<PriceModel>("/Price/GetRentalPrice",
      {
        pickupTime: this.booking.PickupTime?.toISOString() ?? moment().toISOString(),
        returnTime: this.booking.ReturnTime.toISOString(),
        objectTypeId: this.booking.PrimaryObjectTypeId,
        pickupLocationId: this.booking.PickupLocation.Id,
        returnLocationId: this.booking.ReturnLocation?.Id ?? this.booking.PickupLocation.Id,
        useInsurance: false,
        webBooking: true,
        actualReturnTime: null,
        createdAt: this.booking.CreatedAt?.toISOString() ?? moment().toDate().toISOString(),
        discountId: this.booking.DiscountId,
        addedProducts: this.booking.AddedProducts,

        //Customer current coordinated when creating the booking.
        bookingLat: currentCoordinates ? currentCoordinates.Latitude : null,
        bookingLng: currentCoordinates ? currentCoordinates.Longitude : null,
      });


    this.Price = price;
    this.loadingPrice = false;
  }
}
