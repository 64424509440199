<div class="item-container d-none d-md-block rounded-border-card-content" style="margin: 0 auto" *ngIf="type">
  <div class="container my-auto">
    <div class="image-container">
      <div class="item-image" style="height: 100px; background-image: url({{ typeImage }});"></div>
    </div>
    <div class="title-with-divider">
      <div class="title">{{type.Alias}}</div>
    </div>
    <div class="row">
      <div class="col-8 overflow-text">{{ 'Intellitrailer.Booking.Components.BookingOption.Length' | translate : 'Length'}}</div>
      <div class="col-4 text-md-right" style="white-space:nowrap">{{ type.Length }}cm</div>
    </div>
    <div class="row">
      <div class="col-8 overflow-text">{{ 'Intellitrailer.Booking.Components.BookingOption.Width' | translate : 'Width'}}</div>
      <div class="col-4 text-md-right" style="white-space:nowrap">{{ type.Width }}cm</div>
    </div>
    <div class="row">
      <div class="col-8 overflow-text">{{ 'Intellitrailer.Booking.Components.BookingOption.Height' | translate : 'Height'}}</div>
      <div class="col-4 text-md-right" style="white-space:nowrap" *ngIf="type.Height && type.Height.length > 1">{{ type.Height }}cm</div>
    </div>
    <div class="row">
      <div class="col-8 overflow-text">{{ 'tIntellitrailer.Booking.Components.BookingOption.TotalWeight' | translate : 'TotalWeight'}}</div>
      <div class="col-4 text-md-right" style="white-space:nowrap">{{ type.TotalWeight }}kg</div>
    </div>
    <div class="row">
      <div class="col-8 overflow-text">{{ 'tIntellitrailer.Booking.Components.BookingOption.LoadWeight' | translate : 'LoadWeight'}}</div>
      <div class="col-4 text-md-right" style="white-space:nowrap">{{ type.MaxLoad }}kg</div>
    </div>
    <div class="title-with-divider">
      <div class="title" *ngIf="showPrice">
        <mat-progress-spinner diameter="25" mode="indeterminate" *ngIf="loadingPrice" style="margin:0"></mat-progress-spinner>
        <ng-container *ngIf="!loadingPrice">{{ this.Price?.DiscountedTotalPrice | currency }}</ng-container>
      </div>
    </div>
    <div class="row">
      <button type="button" class="col-12" style="min-width: 100px; margin: 0 auto" mat-raised-button>
        <strong>
          {{ 'tIntellitrailer.Booking.Components.BookingOption.Select' | translate : 'Select'}}
        </strong>
      </button>
    </div>
  </div>
</div>
<div class="minimal-item-container d-blok d-md-none" *ngIf="type">
  <div class="container">
    <div class="row">
      <div class="col-3 image-container">
        <!-- " -->
        <div class="item-image" style="background-image: url({{ typeImage }});"></div>
      </div>
      <div class="col-9">
        <div class="row propertyRow">
          <div class="col-9 booking-option-title">{{ type.Alias }}</div>
          <div class="col-3 booking-option-title" *ngIf="showPrice">
            <mat-progress-spinner diameter="25" mode="indeterminate" *ngIf="loadingPrice" style="margin:0"></mat-progress-spinner>
            <ng-container *ngIf="!loadingPrice">{{ this.Price?.DiscountedTotalPrice | currency }}</ng-container>
          </div>
          <!--<div class="col-3 booking-option-title" *ngIf="booking && booking.Price && showPrice">{{ booking.Price?.DiscountedTotalPrice | currency }}</div>-->
          <div class="col-12 booking-option-distance">L: {{ type.Length }}cm, W: {{ type.Width }}cm, H: {{ type.Height }}cm<br /> X: {{ type.TotalWeight }}kg, Y: {{ type.MaxLoad }}kg</div>
        </div>
      </div>
    </div>
  </div>
</div>
