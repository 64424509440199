import { Component, ElementRef, OnInit } from '@angular/core';
import { ActiveRentalService, BookingModel, BookingService, LayoutService, NavigationService } from 'app-components';
import { PageBase, RentalEventType } from 't4core';

@Component({
  selector: 'app-ask-purchase',
  templateUrl: './ask-purchase.html',
  styleUrls: ['./ask-purchase.css']
})
export class AskPurchaseComponent extends PageBase implements OnInit {
  private rentalId: number;
  public booking: BookingModel;

  constructor(el: ElementRef, private naviationService: NavigationService, private rentalService: ActiveRentalService, private layoutService: LayoutService, private bookingSvc: BookingService) {
    super(el);
    this.rentalId = parseInt(this.naviationService.getParam("rentalId"));
  }


  public async ngOnInit() {
  
  }

  public async cmdYes_Click() {

    await this.Api.post("/Rental/RentalEvent", 0, { rentalId: this.rentalId, type: RentalEventType.Unassigned, message: "Have customer made any purchases at the store while renting the trailer: Yes" })
    this.naviationService.executeCommand("Next");
  }

  public async cmdNo_Click() {
    await this.Api.post("/Rental/RentalEvent", 0, { rentalId: this.rentalId, type: RentalEventType.Unassigned, message: "Have customer made any purchases at the store while renting the trailer: No" })
    this.naviationService.executeCommand("Next");
  }

}
