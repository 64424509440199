<app-view-title Title="" [showBack]="false" [showHeader]="true" [overlay]="true"></app-view-title>
<div class="bg" [ngStyle]="{ 'background-image': backgroundImageUri }" style="width: 100vw; height: 100vh; background-size: cover; background-position: top left; background-repeat: no-repeat; position: absolute; text-align: center;">
  <div style="
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.25) 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 212px;
"></div>
  <button type="button" mat-raised-button color="accent" (click)="start()" style=" position: absolute; bottom: 250px; left: calc(100vw / 2 - 125px); width: 250px; margin: 0;">Boka ett släp</button>
</div>
<div class="ow-logo" style=" position: absolute; top: 0; left: 0; background: url(/Styles/OneWay/Images/Logo_transparent.png); background-size: 175px; background-repeat: no-repeat; color: white; padding: 50px 0 0 10px; width: 175px; text-align: left !important; margin: 15px;">
  <h2 style="text-align: left; font-size: 23px; font-weight: normal; filter: drop-shadow(1px 1px 2px rgba(0,0,0,0.5));">Mot nya mål</h2>
</div>
<div class="bottom-nav">
  <div class="bottom-nav-home" *ngIf="typeSettings.isShowMenu">
    <button type="button" mat-raised-button class="home-button" color="accent" (click)="mybooking()">{{'Intellitrailer_Home_Home_cmdMyPage' | translate: 'My reservations'}}</button>
    <div (click)="toggleNewUserInfo(false)" class="link">{{'Intellitrailer_Home_Home_HowDoesItWork' | translate: 'How does it work?' }}</div>
  </div>
  <div class="bottom-nav-select-location" *ngIf="!typeSettings.isShowMenu">
    <div class="select-location">{{'Intellitrailer_Home_Home_cmdChooseALocation' | translate: 'Choose a location'}}</div>
  </div>
</div>

