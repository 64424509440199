<ng-container *ngIf="type">
  <img src="{{rentalObject.Image}}" />
  <h4>{{ rentalObject.Title }}</h4>
  <p>{{ rentalObject.Alias }}</p>
  <div class="propertyTable">
    <div class="row justify-content-center">
      <div class="col col-5">
        {{ 'turbo.core.RentalDialog.Width' | translate : 'Width'}}:
      </div>
      <div class="col col-5">
        {{type.Width}}
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col col-5">
        {{ 'turbo.core.RentalDialog.Length' | translate : 'Length'}}:
      </div>
      <div class="col col-5">
        {{type.Length}}
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col col-5">
        {{ 'turbo.core.RentalDialog.Height' | translate : 'Height'}}:
      </div>
      <div class="col col-5">
        {{type.Height}}
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col col-5">
        {{ 'turbo.core.RentalDialog.LoadWeight' | translate : 'LoadWeight'}}:
      </div>
      <div class="col col-5">
        {{type.MaxLoad}}
      </div>
    </div>
  </div>
</ng-container>
