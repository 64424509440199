import { Component, ElementRef, ViewChild, OnInit, Output, EventEmitter } from '@angular/core';
import { ComponentBase } from 't4core';
import { BookingService } from 'app-components';
import { animate, style, transition, trigger } from '@angular/animations';
import { Coordinates } from 't4-app-integration';

@Component({
  selector: 'app-place-search',
  templateUrl: './place-search.html',
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ marginLeft: '-200px' }),
            animate('0.2s ease-out',
              style({ marginLeft: '0' }))
          ]
        )
      ]
    )
  ]
})
export class PlaceSearchComponent extends ComponentBase implements OnInit {
  @Output()
  public placeSelected: EventEmitter<Coordinates> = new EventEmitter<Coordinates>();

  public showSearch: boolean = false;

  @ViewChild('search')
  private searchElement: ElementRef;
  private searchBox: google.maps.places.Autocomplete; 
  private listener: google.maps.MapsEventListener;

  constructor(public bookingService: BookingService) {
    super();
  }

  public ngOnInit() { }

  public toggleSearch() {
    this.showSearch = !this.showSearch;

    if (this.showSearch) {
      setTimeout(() => {
        this.initGoogle();
        this.searchElement.nativeElement.focus();
      }, 100);
    }
  }

  private async initGoogle() {
    const { } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;

    this.searchBox = new google.maps.places.Autocomplete(this.searchElement.nativeElement, {
      componentRestrictions: { country: ['NO', 'SE', 'DK', 'DE', 'IS'] },
      types: ['locality', 'neighborhood','sublocality'], 
      fields: ['geometry']
    });

    this.searchElement.nativeElement.focus();

    this.listener = this.searchBox.addListener("place_changed", () => {
      //get the place result
      let place: google.maps.places.PlaceResult = this.searchBox.getPlace();
      if (place.geometry) {
        this.placeSelected.emit({ Latitude: place.geometry.location.lat(), Longitude: place.geometry.location.lng() });
      }
    });
  }
}
