<app-view-title [isPrimaryColor]="true"></app-view-title>
<div class="start">
  <mat-progress-spinner diameter="75" mode="indeterminate" class="d-inline-block"></mat-progress-spinner>
  <h2>{{ 'Intellitrailer.Rental.keepinmind.title' | translate : 'Starting the journey' }}...</h2>
</div>

<ng-container>
  <app-card>
    <h1>{{ 'Intellitrailer.Rental.keepinmind.header2' | translate : 'Keep in mind' }}</h1>
    <div class="steps">
      <p *ngIf="stage > 0" class="keepInMindStep">{{ 'Intellitrailer.Rental.keepinmind.step_1a' | translate : 'Remember to lock the trailer on return.' }}</p>
      <p *ngIf="stage > 1" class="keepInMindStep">{{ 'Intellitrailer.Rental.keepinmind.step_2a' | translate : 'Don`t forget to end the rental in the app when you have returned the trailer.' }}</p>
    </div>
  </app-card>
</ng-container>

