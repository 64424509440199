<body [ngClass]="{ 'primary': layout.header.isPrimaryColor, 'split-color': layout.header.isSplitColor,  'green': layout.header.isGreen,  'red': layout.header.isRed }">
  <ng-container *ngIf="!isStarting">
    <div class="head" *ngIf="layout.header.visible" [ngClass]="{ 'overlay': layout.header.overlay }">
      <mat-icon class="back" (click)="back()" *ngIf="layout.header.showBack">west</mat-icon>
      <h2>{{ layout.title }}</h2>
      <mat-icon class="menu" (click)="showMenu()" *ngIf="layout.header.showMenu">menu</mat-icon>
    </div>
    <div class="content container-fluid">
      <main [class.app]="isApp">
        <router-outlet #outlet="outlet"></router-outlet>
      </main>
    </div>
    <div *ngIf="menuToggle" class="menu-container">
      <app-menu [toggle]="menuComponentToggle" (toggleMenu)="showMenu()"></app-menu>
      <bsg-outside-menu (click)="showMenu()"></bsg-outside-menu>
    </div>
    <div id="app-loader" *ngIf="UI.loading">
      <app-on-load [load]="loaded" (loaded)="displayCancelLoad()"></app-on-load>
      <div class="loader-content">
        <mat-spinner mode="indeterminate"></mat-spinner>
        <span class="loaderMessage"> {{ UI.loading.messageTranslationKey | translate : UI.loading.translationDefault }}</span>
        <div>
          <button style="margin: 5px; border-radius: 100px;height: 36px; width: 36px; padding: 5px;" matTooltip="{{ 'Intellitrailer.Booking.BeginBooking.Cancel' | translate : 'Cancel' }}" type="button" class="btn btn-default" (click)="UI.cancel();cancelLoad()" *ngIf="false && showCancelLoad">
            <mat-icon>cancel</mat-icon>
          </button>

          <button type="button" mat-raised-button (click)="UI.cancel();cancelLoad()" *ngIf="showCancelLoad" style="min-height: 40px; margin-top: 10px; margin-bottom: 10px;">
            {{ 'Intellitrailer.Booking.BeginBooking.Cancel' | translate : 'Cancel' }}
          </button>

        </div>
      </div>
    </div> 
  </ng-container>
</body>
<app-chatbot></app-chatbot>

<ng-container *ngIf="showTestMessage">
  <div *ngIf="settings.Environment == 1" style="position: absolute; background: rgba(255, 0, 0, 0.75); height: 30px; bottom: 0; left: 0; width: 100vw; color: white; font-size: 15pt; z-index: 1000000000; text-align: center; " (click)="hideTestMessage()">-- Development --</div>
  <div *ngIf="settings.Environment == 2" style="position: absolute; background: rgba(255, 0, 0, 0.75); height: 30px; bottom: 0; left: 0; width: 100vw; color: white; font-size: 15pt; z-index: 1000000000; text-align: center; " (click)="hideTestMessage()">-- Staging --</div>
  <div *ngIf="settings.Environment == 3" style="position: absolute; background: rgba(255, 0, 0, 0.75); height: 30px; bottom: 0; left: 0; width: 100vw; color: white; font-size: 15pt; z-index: 1000000000; text-align: center; " (click)="hideTestMessage()">-- Test --</div>
</ng-container>
<app-idle-timer></app-idle-timer>
