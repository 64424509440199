import { Component, OnInit, ElementRef } from '@angular/core';
import { PageBase } from 't4core';
import { NavigationService } from 'app-components';

@Component({
  selector: 'app-lock-trailer',
  templateUrl: './lock-trailer.component.html'
})
export class LockTrailerComponent extends PageBase implements OnInit {

  constructor(el: ElementRef, private aviationService: NavigationService) { super(el); }

  ngOnInit(): void {
  }

  public next() {
    this.aviationService.executeCommand("Next");
  }
}
