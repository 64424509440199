import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BookingModel, BookingService, LayoutService, NavigationService } from 'app-components';
import { LocationInfo, PageBase, RentalInfo } from 't4core';
import { LocationMapComponent } from '../../../../lib/Components/location-map/location-map.component';


@Component({
  selector: 'app-return-location', 
  templateUrl: './return-location.html'
})
export class ReturnLocationComponent extends PageBase implements OnInit {
  public bookingState: BookingModel;
  public rental: RentalInfo;

  public selectedLocation: LocationInfo;
  public locations: LocationInfo[];

  @ViewChild('map') public map: LocationMapComponent;


  constructor(private navigator: NavigationService, el: ElementRef,
    private aviationService: NavigationService, private rentalService: BookingService, public layoutService: LayoutService) {
    super(el);

    this.bookingState = this.rentalService.getBookingState();
    this.rental = this.bookingState.booking;
    
  }

  public async ngOnInit() {
    // Load all locations
    this.locations = await this.rentalService.getLocations();
  }



  public async selectLocation(): Promise<any> {
      this.bookingState.booking.ReturnLocation = this.selectedLocation;
      this.rentalService.saveState(this.bookingState);
      this.aviationService.executeCommand("Next");
  }

  public next() {
      this.navigator.executeCommand("Next");
  }
}
