<div class="category">
  <div class="category-inner item-container" [ngClass]="checked ? 'checked' : 'unchecked'">
    <div class="category-image" style="background-image: url({{category.ImageUri}});"></div>
    <div class="category-name">
      <span *ngIf="category.Name.indexOf('Turbo.') == -1">{{ category.Name }}</span>
      <span *ngIf="category.Name.indexOf('Turbo.') != -1">{{ category.Name | translate }}</span>
    </div>
    <div style="position: absolute; top: calc(50% - 20px); right: 25px; width: 40px; font-size: 30px">
      <span class="material-icons" style="display: inline-block; margin-left: 30px; margin-top: 0;">
        keyboard_arrow_right
      </span>
    </div>
  </div>
</div>

