import { Component, ElementRef, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BookingModel, BookingService, LayoutService, NavigationResolver, NavigationService, ActiveRentalService } from 'app-components';
import * as moment from 'moment';
import { AppIntegrationService, AppSettingsService } from 't4-app-integration';
import { BookingErrorCode, ObjectTypeInfo, PageBase, PriceModel } from 't4core';

@Component({
  selector: 'app-create-booking',
  templateUrl: './create-booking.component.html',
  styleUrls: ['./create-booking.component.css']
})
export class CreateBookingComponent extends PageBase implements OnInit {
  public bookingState: BookingModel;
  public user: string;
  public requireVerification: boolean = false;
  public useSavedCard: boolean = true;
  public terms: boolean = false;
  public quarterFromNow: moment.Moment = moment().add(15, 'minutes');
  public hasBookingId: boolean = false;
  public currentPrice: PriceModel = new PriceModel();
  public addableProducts: ObjectTypeInfo[];
  public addonsFromPopup: ObjectTypeInfo[];

  constructor(private navigator: NavigationService, el: ElementRef, private appService: AppIntegrationService, private BookingDataService: BookingService, public dialog: MatDialog, public resolver: NavigationResolver, public layout: LayoutService, public appSettings: AppSettingsService, private rentalService: ActiveRentalService) {
    super(el);
  }

  ngOnInit(): void {
    this.bookingState = this.BookingDataService.getBookingState();
    this.createBooking();
  }

  public async createBooking() {
    var validate = await this.BookingDataService.saveBooking(true, this.useSavedCard);
    if (validate.CustomerDenied) {
      return;
    }
    if (validate.AggregatedResult == BookingErrorCode.Valid) {
      await this.rentalService.setActiveRental(validate.BookingId);

      if (validate.RequirePayment) {
        this.navigator.executeCommand("Payment", { rentalId: validate.BookingId });
      }
      else {
        if (this.bookingState.booking.PickupTime <= this.quarterFromNow) {
          this.navigator.executeCommand("ConfirmationGoToBooking", { rentalId: validate.BookingId ? validate.BookingId : this.bookingState.existingBooking });
        }
        else {
          this.navigator.executeCommand("Next", { rentalId: validate.BookingId });
        }
      }
    }
    else if (validate.AggregatedResult == BookingErrorCode.LocationNotOpen) {
      var x = await this.UI.alert("Intellitrailer.Booking.UnconfirmedBooking.BookingErrorCodeNotOpenHead", "Invalid booking!", "Intellitrailer.Booking.UnconfirmedBooking.BookingErrorCodeNotOpenMsg", "The location is not open for pickup at the specified pickup time.");
    }
    else if (validate.AggregatedResult == BookingErrorCode.ObjectUnavailable) {
      var x = await this.UI.alert("Intellitrailer.Booking.UnconfirmedBooking.BookingErrorCodeObjectUnavailable", "Trailer Unavailable", "Intellitrailer.Booking.UnconfirmedBooking.BookingErrorUnavailableMsg", "The trailer you are trying to book has just been reserved by another user.");
   
      this.navigator.executeCommand("TrailerUnavailable")
    }
    else if (validate.AggregatedResult != BookingErrorCode.Valid && validate.ErrorCode == 2048) {
      var x = await this.UI.alert("Intellitrailer.Booking.UnconfrimedBooking.BookingErrorCodeCustomerIsBlocked", "Customer is Blocked", "Intellitrailer.Booking.UnconfirmedBooking.BookingErrorCustomerIsBlockedMsg", "We regret to inform you that the customer has been blocked and is unable to continue. Please contact customer support for further assistance or if you have any questions.");
      this.back();
    }
    else if (validate.AggregatedResult != BookingErrorCode.Valid && validate.ErrorCode == 4096) {
      var x = await this.UI.alert("Intellitrailer.Booking.UnconfrimedBooking.BookingErrorCodeMaxBookingsPerDay", "Customer has reached maximum limit of bookings per day", "Intellitrailer.Booking.UnconfirmedBooking.BookingErrorMaxBookingsPerDayMsg", "We regret to inform you that the customer has reached the maximum limit of bookings per day and is unable to continue. Please continue the booking tomorrow and if the issue persists, please contact customer support for further assistance or if you have any questions.");
      this.back();
    }
    else if (validate.AggregatedResult != BookingErrorCode.Valid && validate.ErrorCode == 8192) {
      var x = await this.UI.alert("Intellitrailer.Booking.UnconfrimedBooking.BookingErrorCodeMaximumConcurrentBookings", "Customer has reached maximum limit of concurrent bookings", "Intellitrailer.Booking.UnconfirmedBooking.BookingErrorMaximumConcurrentBookingsMsg", "We regret to inform you that the customer has reached the maximum limit of concurrent bookings and is unable to continue. Please complete the ongoing bookings and if the issue persists after that, please contact customer support for further assistance or if you have any questions.");
      this.back();
    }
    else {
      var x = await this.UI.alert("Intellitrailer.Booking.UnconfirmedBooking.BookingErrorCodeHead", "Something went wrong!", "Intellitrailer.Booking.UnconfirmedBooking.BookingErrorCodeMsg", "Please try again, or contact support if the problem persists.");
    }
  }

  public async back() {
    this.navigator.back();
  }

}
