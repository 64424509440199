<app-view-title Title="{{ 'Intellitrailer.Booking.UnConfirmedBooking.TitleMyBooking' | translate : 'My booking' }}" [isPrimaryColor]="true"></app-view-title>

<div class="view-content-full centered-view">

  <div class="rounded-border-card-title">
    {{ 'Intellitrailer.Rental.BookingInfo.Reservation' | translate : 'Reservation' }}
  </div>
  <div class="rounded-border-card-content">
    <app-rental-box *ngIf="booking" [booking]="booking" [hasLock]="hasLock" [allowUnmanned]="allowUnmanned" (rentalAction)="recieveRentalAction($event)"></app-rental-box>
  </div>
</div>

