import { Component, Inject, ViewChild } from '@angular/core';
import { DialogBase } from 't4core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgModel, UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-import-booking',
  templateUrl: './import-booking.component.html',
  styleUrls: ['./import-booking.component.css']
})
export class ImportBookingComponent extends DialogBase {
  public nextClicked: boolean = false;
  public loading: boolean = false;
  public form: UntypedFormGroup;
  @ViewChild("txtSSN", { static: false }) public txtMobilePhone: NgModel;

  constructor(
    public dialogRef: MatDialogRef<ImportBookingComponent>
  ) {
    super("ImportBookingComponent");
    this.loading = true;
    this.form = this.createForm();
    this.loading = false;
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public next() {
    if (this.nextClicked) 
      this.importBooking();
    else 
      this.nextClicked = true;
  }

  private async importBooking() {
    if (this.form.controls['BookingCode'].valid) {
      this.loading = true;
      let success = await this.Api.post<boolean>("Rental/ImportBooking", null, {
        bookingCode: this.form.controls['BookingCode'].value,
        ssn: this.form.controls['SSN'].value
      });
      if (success) {
        this.dialogRef.close(true);
      }
      else {
        this.form.controls['BookingCode'].setValue('');
      }
      this.loading = false;
    }
  }

  private createForm(): UntypedFormGroup {
    let builder = new UntypedFormBuilder().group({});
    builder.addControl('SSN', new UntypedFormControl('', [Validators.required, Validators.minLength(8)]));
    builder.addControl('BookingCode', new UntypedFormControl('', [Validators.required, Validators.minLength(8)]));
    return new UntypedFormGroup(builder.controls, { updateOn: 'blur' });
  }
}
