import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { ComponentBase, PageBase, RentalInfo, RentalStatus } from 't4core';
import { AppIntegrationService, AppSettingsService } from 't4-app-integration';
import { NavigationResolver, NavigationService, ActiveRentalService, LayoutService } from 'app-components';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-igloo-lock',
  templateUrl: './igloo-lock.component.html',
  styleUrls: ['./igloo-lock.component.css']
})
export class IglooLockComponent extends ComponentBase implements OnInit {

  public booking: RentalInfo;
  public event: string = ""; // Pickup/Return/Active etc.
  public unlockPin: string = "";
  public iglooLock: string = "";
  public isWaiting: boolean = true;
  public isUnlockPin: boolean = false;
  public hasFailed: boolean = false;
  public failReason: number;
  

  private timerSubscription: Subscription;
  constructor(public appSvc: AppIntegrationService, private aviationService: NavigationService, private rentalService: ActiveRentalService, public layoutService: LayoutService, public resolver: NavigationResolver, public appSettings: AppSettingsService) {
    super();
    this.appSvc.requestDeviceState();

    this.event = this.aviationService.getParam("event");
    this.timerSubscription = this.appSvc.getSecondTimer().subscribe(x => {
      if (this.isWaiting) this.appSvc.requestDeviceState();
      if (this.isWaiting) {
        this.beginIglooUnlock();        
      }

    });
  }

  async ngOnInit() {
    this.booking = await this.rentalService.getBooking();
    this.Api.get<boolean>("/Rental/SetRentalEvent", { rentalId: this.booking.Id, content: "Begin igloo unlock!" });

    this.iglooLock = this.booking.RentalObject.Locks.map(x => x.Id)[0];
    
    if (this.isWaiting) {
      this.beginIglooUnlock();
    }
  }

  public skip() {
    this.aviationService.executeCommand("Skip");
  }

  private fail(reason?: number) {
    if (this.hasFailed)
      return;
    this.hasFailed = true;
    if (reason)
      this.failReason = reason;   
  }

  private retryCount: number = 0;
  public retry() {
    this.retryCount++;

    if (this.hasFailed) {
      this.hasFailed = false;
      this.failReason = null;
      this.isWaiting = true;
      this.beginIglooUnlock();      
    }
  }

  public async continueIgloo() {
    this.timerSubscription.unsubscribe();
    this.aviationService.executeCommand("Next");
  }

  public async beginIglooUnlock() {
    this.isWaiting = false;

    var lockId = this.iglooLock;
    var timezoneId = "Europe/Stockholm";
    var startDate = new Date().toLocaleString();
    //var date = new Date();
    //var startDate = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear() + ', ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds() + ' ' + (date.getHours() >= 12 ? 'PM' : 'AM');
    var timeDuration = 1; //The pin will be valid for 1 hour.

    // Retrieve the unlock pin.
    var unlockRet = await this.Api.get<string>("/Rental/UnlockIglooLockOTP", { rentalId: this.booking.Id, lockId: lockId, timezoneId: timezoneId, startDate: startDate, timeDuration: timeDuration });

    if (unlockRet == null || unlockRet.trim() === '') {
      this.Insights.logEvent("Igloo unlock pin not received!");
      if (this.booking) this.Api.get<boolean>("/Rental/SetRentalEvent", { rentalId: this.booking.Id, content: "Igloo unlock pin not received!" });
      this.fail(2);
    }
    else {
      this.unlockPin = unlockRet;
      this.isUnlockPin = true;
      //Update the status in the database for tracking the unlock count for the lock. This is done for tracking the battery level.
      await this.Api.get<any>("Rental/UpdateIglooLockStatus", { lockId: lockId });
      await this.Api.get<any>("Rental/SaveLockActivity", { lockId: lockId, type: 1, version: 1 });
      this.Insights.logEvent("Unlock pin received" + lockId);
      if (this.booking) this.Api.get<boolean>("/Rental/SetRentalEvent", { rentalId: this.booking.Id, content: "Igloo unlock pin received!" });
    }
  }

  public async troubleshootIglooLock() {
    this.fail(3);
  }
}
