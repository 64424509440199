import { Component, OnInit, ElementRef } from '@angular/core';
import { NavigationService } from 'app-components';
import { ActiveRentalService } from 'app-components';
import { PageBase } from 't4core';
import { LayoutService } from 'app-components';

@Component({
  selector: 'app-return-info',
  templateUrl: './return-info.component.html'
})
export class ReturnInfoComponent extends PageBase implements OnInit {

  constructor(el: ElementRef, private aviationService: NavigationService, private rentalService: ActiveRentalService, private layoutService: LayoutService) {
    super(el);
  }

  ngOnInit(): void {
  }

  public next() {
    this.rentalService.fetchSecurity(false);
    this.aviationService.executeCommand("Next");
  }
}
