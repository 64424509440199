import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BookingModel, BookingService, NavigationService } from 'app-components';
import { AppSettingsService, AppTypeSettings } from 't4-app-integration';
import { LocationInfo, PageBase } from 't4core';
import { LocationMapComponent } from '../../../../lib/Components/location-map/location-map.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent extends PageBase implements OnInit {
  public bookingState: BookingModel;
  public userClosed: boolean = false;
  public initialStep: boolean = false;
  public userLoggedIn: boolean = false;
  public showSearch: boolean = false;
  public selectedLocation: any;
  public typeSettings: AppTypeSettings;
  public locations: LocationInfo[];

  //Google maps
  @ViewChild('map', { static: true }) gmapElement: LocationMapComponent;

 

  constructor(
    el: ElementRef,
    private aviationService: NavigationService,
    private BookingDataSerivce: BookingService,
    private appSettings: AppSettingsService)
  {
    super(el);
    this.typeSettings = this.appSettings.settings.typeSettings;
    this.userClosed = localStorage.getItem('newInfoClosed') ? true : false;

    this.userLoggedIn = appSettings.getUserId() ? true : false;
    this.bookingState = this.BookingDataSerivce.getBookingState();
  }

  async ngOnInit() {
    await this.appSettings.waitForAuthentication();
   // await this.BookingDataSerivce.startSession();
    this.bookingState = this.BookingDataSerivce.getBookingState();

    // Load all locations
    //this.locations = await this.BookingDataSerivce.getLocations(null, null, true);
    this.locations = await this.BookingDataSerivce.getLocations();
    if (this.bookingState.booking.PickupLocation)
      this.selectedLocation = this.bookingState.booking.PickupLocation;

    console.log("Current state");
    console.log(this.bookingState.booking);
  }

  public toggleSearch() {
    this.showSearch = !this.showSearch; 
  }

  public async selectLocation():Promise<any> {
    if (this.selectedLocation) {
      this.BookingDataSerivce.setPickupLocation(this.selectedLocation);
      this.aviationService.executeCommand("NewBooking");
    }
  }

  public mybooking() {
    let user = this.appSettings.getUserId();
    if (!user) {
      this.aviationService.executeCommand("SignIn");
    }
    else {
      this.aviationService.executeCommand("Bookings");
    }
  }

  public toggleNewUserInfo(bool = true) {
    localStorage.setItem('newInfoClosed', 'true');
    this.userClosed = bool;
  }

  public back() {
    this.aviationService.back();
  }
}
