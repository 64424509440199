import { Component, OnInit, ElementRef } from '@angular/core';
import { PageBase } from 't4core';
import { NavigationService } from 'app-components';

@Component({
  selector: 'app-unlock-after-check',
  templateUrl: './unlock-after-check.component.html'
})
export class UnlockAfterCheckComponent extends PageBase implements OnInit {

  constructor(el: ElementRef, private aviationService: NavigationService) { super(el); }

  ngOnInit(): void {
  }

  public next() {
    this.aviationService.executeCommand("Next");
  }

  public async runAlternatives() {

  } 
}
