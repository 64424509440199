import { Component, ElementRef, OnInit } from '@angular/core';
import { BookingModel, BookingService, LayoutService, NavigationService } from 'app-components';
import { AppIntegrationService, AppSettingsService } from 't4-app-integration';
import { ObjectTypeInfo, PageBase, TypeCategoryInfo } from 't4core';

@Component({
  selector: 'app-select-category',
  templateUrl: './select-category.component.html',
  styleUrls: ['./select-category.component.css']
})
export class SelectCategoryComponent extends PageBase implements OnInit {
    public categories: TypeCategoryInfo[];
    public bookingState: BookingModel;
    public priceInfo: [{ name: string; value: string }];
    public priceInfoAsHTML: string;

    constructor(private navigator: NavigationService, el: ElementRef, private appService: AppIntegrationService, private BookingDataSerivce: BookingService, public layout: LayoutService, public appSetings: AppSettingsService) {
      super(el);
      this.navigator.executeCommand('Next');

        //var stepper: Stepper = {
        //    totalSteps: [1, 2, 3, 4, 5],
        //    title: "släptyp",
        //    step: 1
        //}

        //var l: LayoutModel = {
        //    hidebackButton: true,
        //    hideMenu: true,
        //    hideTitle: true,
        //    stepper: stepper,
        //    headerClass: "header underline"
        //}
        //layout.setLayout(l);

    }

    async ngOnInit() {
        this.bookingState = this.BookingDataSerivce.getBookingState();

        //Price list/information from AppSettingsService
      this.priceInfo = this.appSetings.settings.priceInfo;
      this.priceInfoAsHTML = this.appSetings.settings.priceInfoAsHTML;

        var token = this.UI.beginLoading("Intellitrailer.App.Navigation.Loading", "Preparing view", null);
      await this.getCategories();
      this.UI.loaderCompleted(token);
    }

    public back() {
        this.navigator.back();
    }

    public async getCategories() {
        this.categories = await this.BookingDataSerivce.getCategories();

        if (this.bookingState.booking.Id) {
            for (let category of this.categories) {
                var typesInCategory = await this.Api.get<ObjectTypeInfo[]>("RentalObject/GetAvailableTypes", { locationId: this.bookingState.booking.PickupLocation.Id, categoryId: category.Id });
                if (typesInCategory.find(x => x.Id == this.bookingState.booking.PrimaryObjectTypeId)) {
                    this.bookingState.objectCategories = [category];
                }
            }
        }
    }

    public async selectCategory(selectedCategory: TypeCategoryInfo) {
        this.bookingState.objectCategories = [selectedCategory];
        await this.next();
    }

    public async next() {
        await this.BookingDataSerivce.saveState(this.bookingState);
        this.navigator.executeCommand("Next");
    }
}
