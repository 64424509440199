import { Component, Input, OnInit } from '@angular/core';
import { ComponentBase, TypeCategoryInfo } from 't4core';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent extends ComponentBase implements OnInit {

  @Input() checked: boolean = false;
  @Input() category: TypeCategoryInfo;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
