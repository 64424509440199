<div class="minimal-item-container d-blok d-md-none" *ngIf="booking">
  <div class="container" [ngClass]="{ unavailable: unavailable }">
    <div class="row">
      <div class="col-3 image-container">
        <img src="{{ type?.PictureUri }}" />
        <div class="price">
          <span *ngIf="unavailable"><mat-icon>cancel</mat-icon><br />Finns ej</span>
          <ng-container *ngIf="!unavailable">
            <div *ngIf="booking.Type == 3"><mat-icon>person</mat-icon></div>
            <div *ngIf="booking.Type == 0"><mat-icon style="color: var(--yellow)">star</mat-icon></div>
            <div *ngIf="booking.Type != 0 && booking.Type != 3"><mat-icon>alt_route</mat-icon></div>
          </ng-container>
        </div>
      </div>
      <div class="col-9">
        <div>
          <div class="propertyRow">
            <div class="booking-option-title" [ngClass]="{ changed: booking.HasObjectTypeChanged,  unchanged: !booking.HasObjectTypeChanged }">{{ type?.Alias }}</div>

            <div class="booking-option-distance">
              <b>Hämtas: </b><br />
              <span [ngClass]="{ changed: booking.HasPickupLocationChanged,  unchanged: !booking.HasPickupLocationChanged }">{{pickupLocation.Name}}</span><br /><span [ngClass]="{ changed: booking.HasPickupTimeChanged,  unchanged: !booking.HasPickupTimeChanged }">{{booking.PickupTime | date : 'dd MMM HH:mm'}}</span>
            </div>
            <div class="booking-option-distance">
              <b>Återlämnas: </b><br />
              <span [ngClass]="{ changed: booking.HasReturnLocationChanged,  unchanged: !booking.HasReturnLocationChanged }">{{returnLocation.Name}}</span><br /><span [ngClass]="{ changed: booking.HasReturnTimeChanged,  unchanged: !booking.HasReturnTimeChanged }">{{booking.ReturnTime | date : 'dd MMM HH:mm'}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12" style="text-align: center;">

        <ng-container *ngIf="!unavailable">
          <div *ngIf="booking.IsPreliminary && booking.PredictedBindDate">Beräknad ankomst: {{ booking.PredictedBindDate | date: 'dd/MM HH:mm' }}</div>
          <button mat-raised-button *ngIf="booking.IsPreliminary" (click)="select()">Preliminärboka {{ booking.Price?.DiscountedTotalPrice | currency }}</button> 
          <button mat-raised-button *ngIf="!booking.IsPreliminary" (click)="select()">Boka {{ booking.Price?.DiscountedTotalPrice | currency }}</button>
        </ng-container>


      </div>
    </div>
  </div>
</div>
<!--<div class="item-container d-none d-md-block rounded-border-card-content" style="margin: 0 auto">
  <div class="container my-auto">
    <div class="image-container">
      <div class="item-image" style="height: 100px; background-image: url({{ type?.PictureUri }});"></div>
    </div>
    <div class="title-with-divider">
      <div class="title" [ngClass]="{ changed: booking.HasObjectTypeChanged,  unchanged: !booking.HasObjectTypeChanged }">{{type?.Alias}}</div>
    </div>
    <div class="row">
      <div class="col">{{ 'Intellitrailer.OW.Booking.BookingOption.Pickup' | translate : 'Pickup'}}</div>
      <div class="col text-md-right">
        
      </div>
    </div>
    <div class="row">
      <div class="col">{{ 'Intellitrailer.OW.Booking.BookingOption.Return' | translate : 'Return'}}</div>
      <div class="col text-md-right">
        <span [ngClass]="{ changed: booking.HasReturnLocationChanged,  unchanged: !booking.HasReturnLocationChanged }">{{returnLocation.Name}}</span><br /><span [ngClass]="{ changed: booking.HasReturnTimeChanged,  unchanged: !booking.HasReturnTimeChanged }">{{booking.ReturnTime | date : 'dd MMMM HH:nn'}}</span>
      </div>
    </div>
    <div class="title-with-divider">
      <div class="title">
        {{ booking.Price?.DiscountedTotalPrice | currency }}
      </div>
    </div>
    <div class="row">
      <button type="button" class="col-12" style="min-width: 100px; margin: 0 auto" mat-raised-button>
        <strong>
          {{ 'tIntellitrailer.Booking.Components.BookingOption.Select' | translate : 'Select'}}
        </strong>
      </button>
    </div>
  </div>
</div>-->
