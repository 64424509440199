<app-view-title Title="{{ 'Intellitrailer.Booking.SelectObject.TitleType' | translate : 'Choose trailer type' }}"></app-view-title>
<div class="view-content no-padding">
  <div class="trailer-icon-big d-block d-md-none" *ngIf="!categorySelected"></div>
  <div class="trailer-icon-big d-block d-md-none" [style.background-image]="'url(' + bookingState.objectCategory.ImageUri + ')'" *ngIf="categorySelected && bookingState.objectCategory.ImageUri"></div>
  <div class="trailer-measurement-info d-block d-md-none">X: {{ 'Intellitrailer.Booking.SelectObject.TotalWeight' | translate : 'Total weight' }}, Y: {{ 'Intellitrailer.Booking.SelectObject.LoadWeight' | translate : 'Load weight' }}</div>
  <div class="trailer-measurement-info d-block d-md-none" *ngIf="categorySelected">L: {{ 'Intellitrailer.Booking.SelectObject.Length' | translate : 'Length' }}, W: {{ 'Intellitrailer.Booking.SelectObject.Width' | translate : 'Width' }}, H: {{ 'Intellitrailer.Booking.SelectObject.Height' | translate : 'Height' }}</div>

  <div class="object-wrapper">
    <div class="select-object-info-heading">
      <div class="select-object-info-location">
        {{ bookingState.booking.PickupLocation.Name }}
      </div>
      <div class="select-object-info-text">
        <p *ngIf="availableOptions.length > 0" class="text-center">
          {{ 'Intellitrailer.Booking.SelectObject.SelectObject' | translate : 'Select trailer' }}
        </p>
        <p *ngIf="availableOptions.length === 0" style="height: 70vh" class="text-center">
          {{ 'Intellitrailer.Booking.SelectObject.NoAvailableObject' | translate : 'No available trailers' }}
        </p>
      </div>
    </div>
    <div *ngIf="availableOptions.length > 0" class="select-object-divider d-block d-md-none"></div>
    <div style="display: flex; flex-wrap: wrap;" class="justify-content-center">
      <app-booking-option *ngFor="let option of availableOptions"
                          class="col-md-4 type-group"
                          [booking]="option"
                          (click)="selectOption(option)">
      </app-booking-option>
    </div>
  </div>
  <!--<div class="view-actions white-fade-out-bg" *ngIf="(bookingState && bookingState.booking && bookingState.booking.Id && bookingState.booking.PrimaryObjectTypeId)">
    <button *ngIf="!loading" type="button" mat-raised-button (click)="next()" [disabled]="!bookingState.booking.PrimaryType">{{ 'Intellitrailer.Navigation.Next' | translate : 'Next' }}</button>
  </div>-->
</div>

