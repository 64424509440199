import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ComponentBase, RentalObjectInfo, ObjectTypeInfo } from 't4core';


@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.css']
})
export class LoadingScreenComponent extends ComponentBase implements OnInit {
  @Input() public title: string;
  @Input() public description: string;

  @Output() public viewLoaded = new EventEmitter<string>();

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.viewLoaded.emit('Done');
    }, 1000);
  }
}
