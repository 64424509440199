import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ComponentBase, RentalObjectInfo, ObjectTypeInfo } from 't4core';


@Component({
  selector: 'view-actions',
  templateUrl: './view-actions.html',
  styleUrls: ['./view-actions.css']
})
export class ViewActionsComponent extends ComponentBase implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }
}
