import { Component, OnInit, ElementRef, EventEmitter, Output, Input } from '@angular/core';
import { PageBase } from 't4core';
import { AppIntegrationService } from 't4-app-integration';
import { NavigationService } from 'app-components';

@Component({
  selector: 'app-cameraPermissionRequired',
  templateUrl: './cameraPermissionRequired.html'
})
export class CameraPermissionRequired extends PageBase implements OnInit {

  @Input() permissionAsked: boolean = false;

  @Output()
  public next: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(el: ElementRef, private appService: AppIntegrationService, private aviationService: NavigationService) {
    super(el);
  }

  ngOnInit(): void { }

  public askForPermission() {
    this.next.emit(true);
    //this.appService.takePhoto('','');
    this.permissionAsked = true;
  }

  public openSettings() {
    this.appService.openPermissions();
  }

  public skipTakePictures() {
    if (this.appService.Device.Camera < 2) {
      this.aviationService.executeCommand("Confirm");
    }
  }
}
