<div class="image-wrapper" *ngIf="image">
  <img class="img" src="{{image}}" />
</div>
<div class="information-screen-wrapper">
  <div class="information-screen-title">{{title}}</div>
  <div class="information-screen-primary-content">{{primaryContent}}</div>
  <div class="information-screen-secondary-content">{{secondaryContent}}</div>
</div>
<div class="info-screen-content">
  <ng-content></ng-content>
</div>
