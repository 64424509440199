<app-view-title Title="{{ 'Intellitrailer.MyPage.Overview.TitleMyBookings' | translate : 'My bookings' }}" [isPrimaryColor]="true"></app-view-title>
<div class="view-content-full centered-view" style="padding: 0px">

  <div class="toggle-tabs-container">
    <div class="toggle-tabs">
      <div class="toggle-tab-container" (click)="toggleTab(0)">
        <div class="toggle-tab" [ngClass]="currentTab == 0 ? 'toggle-tab-checked' : 'toggle-tab-unchecked'">
          {{ 'Intellitrailer.MyPage.Overview.ActiveBookings' | translate : 'Active bookings' }}
        </div>
      </div>
      <div class="toggle-tab-container" (click)="toggleTab(1)">
        <div class="toggle-tab" [ngClass]="currentTab == 1 ? 'toggle-tab-checked' : 'toggle-tab-unchecked'">
          {{ 'Intellitrailer.MyPage.Overview.History' | translate : 'History' }}
        </div>
      </div>
    </div>

    <div class="col-12">
      <mat-tab-group [(selectedIndex)]="currentTab" class="header-tabs">
        <mat-tab>

          <div *ngIf="!loading && activeRents.length > 0" class="title-without-divider" style="background-color: transparent">
            <div class="title main-color-font" style="background-color: transparent">{{ 'Intellitrailer.MyPage.Overview.ActiveBookings' | translate : 'Active bookings' }}</div>
          </div>
          <div class="row" *ngFor="let booking of activeRents" style="margin-top: 15px;">
            <div class="active-booking-wrapper col-12">
              <app-booking-overview [booking]="booking" (navigate)="navigate($event)" (cancelled)="cancelled($event)"></app-booking-overview>
            </div>
          </div>

          <div *ngIf="!loading && futureRents.length > 0" class="title-without-divider" style="background-color: transparent; margin-top: 30px">
            <div class="title main-color-font" style="background-color: transparent">{{ 'Intellitrailer.MyPage.Overview.FutureBookings' | translate : 'Future bookings' }}</div>
          </div>
          <div class="row" *ngFor="let booking of futureRents" style="margin-top: 15px;">
            <div class="future-booking-wrapper col-12">
              <app-booking-overview [booking]="booking" (navigate)="navigate($event)" (cancelled)="cancelled($event)"></app-booking-overview>
            </div>
          </div>
          <div class="row" *ngIf="!loading && futureRents.length == 0 && activeRents.length == 0" style=" color: var(--action-text-color);">
            <div class="col-12">
              <div class="col-12 text-center mt-4">
                <span class="material-icons" style="font-size: 50px">
                  calendar_today
                </span>
              </div>
              <div class="no-bookings-found">
                {{ 'Intellitrailer.MyPage.Overview.NoBookingsFound' | translate : 'No bookings found' }}
              </div>
              <div class="import-bookings-text">
                {{ 'Intellitrailer.MyPage.MyBookings.ImportExistingBooking' | translate : 'If you already have created a booking, you can' }}
                <br />
                <a [routerLink]="" class="import-link" (click)="importBooking()">
                  {{ 'Intellitrailer.MyPage.MyBookings.ImportHere' | translate : 'import it here' }}
                </a>
              </div>
            </div>
          </div>

        </mat-tab>
        <mat-tab>

          <div *ngIf="!loading && pastRents.length > 0" class="title-without-divider">
            <div class="title" style="background-color: transparent">{{ 'Intellitrailer.MyPage.Overview.CompletedRents' | translate : 'Completed rents' }}</div>
          </div>
          <div class="row" *ngFor="let booking of pastRents" style="margin-top: 15px;">
            <div class="active-booking-wrapper col-12">
              <app-booking-overview [booking]="booking" (navigate)="navigate($event)" (cancelled)="cancelled($event)"></app-booking-overview>
            </div>
          </div>

          <div *ngIf="!loading && canceledBookings.length > 0" class="title-without-divider">
            <div class="title" style="background-color: transparent">{{ 'Intellitrailer.MyPage.Overview.CanceledBookings' | translate : 'Canceled bookings' }}</div>
          </div>
          <div class="row" *ngFor="let booking of canceledBookings" style="margin-top: 15px;">
            <div class="future-booking-wrapper col-12">
              <app-booking-overview [booking]="booking" (navigate)="navigate($event)" (cancelled)="cancelled($event)"></app-booking-overview>
            </div>
          </div>
          <div class="row" *ngIf="!loading && canceledBookings.length == 0 && pastRents.length == 0" style=" color: var(--action-text-color);">
            <div class="col-12">
              <div class="col-12 text-center mt-4">
                <span class="material-icons" style="font-size: 50px">
                  calendar_today
                </span>
              </div>
              <div class="no-bookings-found">
                {{ 'Intellitrailer.MyPage.Overview.NoBookingsFound' | translate : 'No bookings found' }}
              </div>
              <div class="import-bookings-text">
                {{ 'Intellitrailer.MyPage.MyBookings.ImportExistingBooking' | translate : 'If you already have created a booking, you can' }}
                <a [routerLink]="" class="import-link"  (click)="importBooking()">
                  {{ 'Intellitrailer.MyPage.MyBookings.ImportHere' | translate : 'import it here' }}
                </a>
              </div>
            </div>
          </div>

        </mat-tab>
      </mat-tab-group>
    </div>
    <div style="height: 90px"></div>
  </div>
</div>

  <div class="view-actions">
    <button mat-raised-button color="accent" (click)="refreshBookings()">
      <mat-icon>refresh</mat-icon>
      {{ 'Intellitrailer.MyPage.Dialog.Update' | translate : 'Update' }}
    </button>
  </div>
  

