import { Component, OnInit, Inject } from '@angular/core';
import { DialogBase } from 't4core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppIntegrationService } from 't4-app-integration';

@Component({
  selector: 'gps-dialog',
  templateUrl: './gps-dialog.component.html'
})
export class GPSDialogComponent extends DialogBase implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<GPSDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private AppService: AppIntegrationService)
  {
    super("GPSDialogComponent");
  }

  public async ngOnInit() {
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public async openSettings() {
    //this.AppService.openAppSettings();
  }
}
