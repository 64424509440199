import { Type } from "@angular/core";

export interface NavigationStep {
  Key: string; // The unique key identifying this step
  Path: string; // The path used to navigate to this step ex: Booking/DateTime
  Component: Type<any>; // The Name of the componnt to load for this step
  Params?: any | undefined; // An object containing hard coded parameters to pass to this component ex { Color: "Red", IsEnable: true }
  Actions: NavigationStepAction[]; // A list of commands that can be executed for this step
  Context?: ApplicationContext; // The context to use when executing this step

  AllowColdStart?: Boolean; // Can the user start on this step? Or should he be redirected to the start page

  // Ask the user to confirm when leaving this view
  ConfirmOnLeave?: {
    TitleKey: string, // Translation key for the title
    TitleText: string, // Default value for the title (English)
    ContentKey: string,// Translation key for the message
    ContentText: string,// Default value for the message (English)
    DisplayOn: NavType[] // When should this message be shown
  };
}

export interface NavigationStepAction {
  Command: string; // The command that will be executed
  TargetStep: string; // A reference to the key of the step to navigate to
}


export enum ApplicationContext {
  Owner = "Owner",
  Customer = "Customer",
  Region = "Region"
}

export enum NavType {
  BackNavigation,
  MenuNavigation,
  NormalNavigation
}
