<div class="item-container" [ngClass]="{ yellow: booking.Status == 100, red: booking.Status == 150 }" style="border-bottom-left-radius: 0; border-bottom-right-radius: 0; font-size: 14px">
  <div class="booking-info" *ngIf="booking">
    <div class="container my-auto">
      <div style="display: inline-block; vertical-align: top; width: calc(100% - 0px);">
        <div class="row" *ngIf="!booking.IsOneWay">
          <div *ngIf="appSettings.appType === 'app'">
            <div class="col-6 image-container image-container-overview" style="text-align: center; margin: 0 auto;">
              <div class="item-image-overview" *ngIf="booking.PrimaryType?.PictureUri"
                   style="background-image: url({{ booking.PrimaryType.PictureUri }});"></div>
            </div>
            <div class="col-12 text-container-overview">
              <div class="row propertyRow" *ngIf="booking.Status == 100 || booking.Status == 150" style="display: flex; flex-wrap: wrap;">
                <div class="col-5">{{'Intellitrailer.MyPage.BookingOverview.lblReturnTo' | translate : 'Return to' }}</div>
                <div class="col-7"><strong>{{booking.PickupLocation.Name}}</strong></div>
                <div class="col-5">{{'Intellitrailer.MyPage.BookingOverview.lblTime' | translate : 'Time' }}</div>
                <div class="col-7">{{booking.ReturnTime | date : 'yyyy-MM-dd HH:mm'}}</div>
                <div class="col-5" *ngIf="booking.Status == 100">{{'Intellitrailer.MyPage.BookingOverview.lblTimeLeft' | translate : 'Time left' }}</div>
                <div class="col-5" *ngIf="booking.Status == 150" [ngClass]="{ red: booking.Status == 150 }">{{'Intellitrailer.MyPage.BookingOverview.lblDelay' | translate : 'Delayed' }}</div>
                <div class="col-7" [ngClass]="{ red: booking.Status == 150 }">
                  {{ countdown?.locale(Settings.clientSettings.Language).humanize() }}
                </div>
                <div class="col-5">{{'Intellitrailer.MyPage.BookingOverview.lblBooking' | translate : 'Booking' }}</div>
                <div class="col-7">{{booking.Id}}</div>
              </div>
              <div class="row propertyRow" *ngIf="booking?.Status != 100 && booking?.Status != 150" style="display: flex; flex-wrap: wrap;">
                <div class="col-5" *ngIf="booking?.PickupLocation?.Name">{{'Intellitrailer.MyPage.BookingOverview.lblPlace' | translate : 'Place' }}</div>
                <div class="col-7" *ngIf="booking?.PickupLocation?.Name"><strong>{{booking.PickupLocation.Name}}</strong></div>
                <div class="col-5" *ngIf="booking?.PickupTime">{{'Intellitrailer.MyPage.BookingOverview.lblStartTime' | translate : 'Start time' }}</div>
                <div class="col-7" *ngIf="booking?.PickupTime">{{booking.PickupTime | date : 'yyyy-MM-dd HH:mm'}}</div>
                <div class="col-5" *ngIf="booking?.ReturnTime">{{'Intellitrailer.MyPage.BookingOverview.lblEndTime' | translate : 'End time' }}</div>
                <div class="col-7" *ngIf="booking?.ReturnTime">{{booking.ReturnTime | date : 'yyyy-MM-dd HH:mm'}}</div>
                <div class="col-5" *ngIf="booking?.Id">{{'Intellitrailer.MyPage.BookingOverview.lblBooking' | translate : 'Booking' }}</div>
                <div class="col-7" *ngIf="booking?.Id">{{booking.Id}}</div>
              </div>
            </div>
          </div>
          <div *ngIf="appSettings.appType != 'app'">
          <div class="col-3 image-container image-container-overview">
            <div class="item-image" *ngIf="booking.PrimaryType?.PictureUri" style="background-image: url({{ booking.PrimaryType.PictureUri }});"></div>
          </div>
          <div class="col-9 text-container-overview">
            <div class="row propertyRow" *ngIf="booking.Status == 100 || booking.Status == 150">
              <div class="col col-5">{{'Intellitrailer.MyPage.BookingOverview.lblReturnTo' | translate : 'Return to' }}</div>
              <div class="col col-7"><strong>{{booking.PickupLocation.Name}}</strong></div>
              <div class="col col-5">{{'Intellitrailer.MyPage.BookingOverview.lblTime' | translate : 'Time' }}</div>
              <div class="col col-7">{{booking.ReturnTime | date : 'yyyy-MM-dd HH:mm'}}</div>
              <div class="col col-5" *ngIf="booking.Status == 100">{{'Intellitrailer.MyPage.BookingOverview.lblTimeLeft' | translate : 'Time left' }}</div>
              <div class="col col-5" *ngIf="booking.Status == 150" [ngClass]="{ red: booking.Status == 150 }">{{'Intellitrailer.MyPage.BookingOverview.lblDelay' | translate : 'Delayed' }}</div>
              <div class="col col-7" [ngClass]="{ red: booking.Status == 150 }">
                {{ countdown?.locale(Settings.clientSettings.Language).humanize() }}
              </div>
              <div class="col col-5">{{'Intellitrailer.MyPage.BookingOverview.lblBooking' | translate : 'Booking' }}</div>
              <div class="col col-7">{{booking.Id}}</div>
            </div>
            <div class="row propertyRow" *ngIf="booking?.Status != 100 && booking?.Status != 150">
              <div class="col col-5" *ngIf="booking?.PickupLocation?.Name">{{'Intellitrailer.MyPage.BookingOverview.lblPlace' | translate : 'Place' }}</div>
              <div class="col col-7" *ngIf="booking?.PickupLocation?.Name"><strong>{{booking.PickupLocation.Name}}</strong></div>
              <div class="col col-5" *ngIf="booking?.PickupTime">{{'Intellitrailer.MyPage.BookingOverview.lblStartTime' | translate : 'Start time' }}</div>
              <div class="col col-7" *ngIf="booking?.PickupTime">{{booking.PickupTime | date : 'yyyy-MM-dd HH:mm'}}</div>
              <div class="col col-5" *ngIf="booking?.ReturnTime">{{'Intellitrailer.MyPage.BookingOverview.lblEndTime' | translate : 'End time' }}</div>
              <div class="col col-7" *ngIf="booking?.ReturnTime">{{booking.ReturnTime | date : 'yyyy-MM-dd HH:mm'}}</div>
              <div class="col col-5" *ngIf="booking?.Id">{{'Intellitrailer.MyPage.BookingOverview.lblBooking' | translate : 'Booking' }}</div>
              <div class="col col-7" *ngIf="booking?.Id">{{booking.Id}}</div>
            </div>
          </div>
          </div>
        </div>
        <div class="row" *ngIf="booking.IsOneWay">
          <div class="col col-12" style="text-align: center"><strong>{{booking.Id}}</strong></div>
            <div class="col-12 image-container image-container-overview" style="min-height: 100px; padding: 15px;">
              <div class="item-image" *ngIf="booking.PrimaryType?.PictureUri" style="background-image: url({{ booking.PrimaryType.PictureUri }});"></div>
            </div>
            <div class="col-12 text-container-overview" style="text-align: center">
              <div class="row propertyRow" *ngIf="booking.Status == 100 || booking.Status == 150">
                <div class="col col-5">{{'Intellitrailer.MyPage.BookingOverview.lblReturnTo' | translate : 'Return to' }}</div>
                <div class="col col-7"><strong>{{booking.PickupLocation.Name}}</strong></div>
                <div class="col col-5">{{'Intellitrailer.MyPage.BookingOverview.lblTime' | translate : 'Time' }}</div>
                <div class="col col-7">{{booking.ReturnTime | date : 'yyyy-MM-dd HH:mm'}}</div>
                <div class="col col-5" *ngIf="booking.Status == 100">{{'Intellitrailer.MyPage.BookingOverview.lblTimeLeft' | translate : 'Time left' }}</div>
                <div class="col col-5" *ngIf="booking.Status == 150" [ngClass]="{ red: booking.Status == 150 }">{{'Intellitrailer.MyPage.BookingOverview.lblDelay' | translate : 'Delayed' }}</div>
                <div class="col col-7" [ngClass]="{ red: booking.Status == 150 }">
                  {{ countdown?.locale(Settings.clientSettings.Language).humanize() }}
                </div>
                <div class="col col-5">{{'Intellitrailer.MyPage.BookingOverview.lblBooking' | translate : 'Booking' }}</div>
                <div class="col col-7">{{booking.Id}}</div>
              </div>
              <ng-container *ngIf="booking?.Status != 100 && booking?.Status != 150">
                <div class="title-with-divider">
                  <div class="title">{{'Intellitrailer.MyPage.BookingOverview.lblPickup' | translate : 'Pickup' }}</div>
                </div>
                <div class="row">
                  <div class="col">
                    <strong *ngIf="booking?.PickupLocation?.Name">{{booking.PickupLocation.Name}}<br /></strong>
                    {{booking.PickupTime | date : 'yyyy-MM-dd HH:mm'}}
                  </div>
                </div>
                <div class="title-with-divider">
                  <div class="title">{{'Intellitrailer.MyPage.BookingOverview.lblReturn' | translate : 'Return' }}</div>
                </div>
                <div class="row">
                  <div class="col">
                    <strong *ngIf="booking?.ReturnLocation?.Name">{{booking.ReturnLocation.Name}}<br /></strong>
                    {{booking.ReturnTime | date : 'yyyy-MM-dd HH:mm'}}
                  </div>
                </div>
                <div class="title-with-divider">
                  <div class="title">{{'Intellitrailer.MyPage.BookingOverview.lblStatus' | translate : 'Status' }}</div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <strong *ngIf="booking?.Status == 0">{{'Intellitrailer.MyPage.BookingOverview.valStatus0' | translate : 'Preliminary' }}</strong>
                    <strong *ngIf="booking?.Status == 50">{{'Intellitrailer.MyPage.BookingOverview.valStatus50' | translate : 'Confirmed' }}</strong>
                  </div>
                  <ng-container *ngIf="booking?.Status == 0">
                    <div class="col-12">{{'Intellitrailer.MyPage.BookingOverview.lblEstimatedConfirmationDate' | translate : 'Estimated confirmation date' }}<br/>
                      2022-03-05 <mat-icon style="font-size: 13px; height: 13px; width: 13px">help</mat-icon></div>
                  </ng-container>
                </div>
                <div class="row" style="margin-top:15px;">
                  <div class="col">Ditt släp befinner sig just nu i <br /><strong>Örebro <mat-icon style="font-size: 13px; height: 13px; width: 13px">help</mat-icon></strong></div>
                </div>
              </ng-container>
            </div>
          </div>
      </div>
      <div style="display: none; position: absolute; top: calc(50% - 27px); right: 25px; width: 0px; font-size: 30px !important">
        <span class="material-icons" style="display: inline-block; margin-left: 30px; margin-top: 0;">
          keyboard_arrow_right
        </span>
      </div>
    </div>
    </div>
</div>
<div style="margin-top: -7px"></div>
<div class="item-bottom-button-left" (click)="openBooking()" *ngIf="booking?.Status != status.Canceled && booking?.Status < status.Returned">
  <span class="material-icons" style="vertical-align: top">
    launch
  </span>
  <span style="vertical-align: top; display: inline-block; margin-top: 2px; margin-left: 7px">{{'Intellitrailer.MyPage.BookingOverview.lblOpen' | translate : 'Open' }}</span>
</div>
<div [matMenuTriggerFor]="menu" class="item-bottom-button-right" *ngIf="booking?.Status != status.Canceled && booking?.Status < status.Returned">
  <span class="material-icons" style="vertical-align: top">
    expand_more
  </span>
  <span style="vertical-align: top; display: inline-block; margin-top: 2px; margin-left: 5px">{{'Intellitrailer.MyPage.BookingOverview.lblMore' | translate : 'More' }}</span>
</div>
<div [matMenuTriggerFor]="menu" class="item-bottom-button-center" *ngIf="booking?.Status == status.Canceled || booking?.Status == status.Returned">
  <span class="material-icons" style="vertical-align: top">
    expand_more
  </span>
  <span style="vertical-align: top; display: inline-block; margin-top: 2px; margin-left: 5px">{{'Intellitrailer.MyPage.BookingOverview.lblMore' | translate : 'More' }}</span>
</div>
<mat-menu #menu="matMenu" class="bookingOverview">
<mat-divider *ngIf="false && booking?.Status != status.Canceled && booking?.Status < status.Returned"></mat-divider>
  <!--<button *ngIf="booking?.Status == status.Reserved ||  booking?.Status == status.ReservedAndBound" mat-menu-item (click)="change()"><mat-icon>edit</mat-icon>{{ 'Intellitrailer.MyPage.BookingOverview.ChangeBooking' | translate : 'Change booking' }}</button>-->
  <button *ngIf="booking?.Status == status.Reserved ||  booking?.Status == status.ReservedAndBound" mat-menu-item (click)="cancel()"><mat-icon>cancel</mat-icon>{{ 'Intellitrailer.MyPage.BookingOverview.CancelBooking' | translate : 'Cancel booking' }}</button>
  <button mat-menu-item (click)="sendEmail()"><mat-icon>receipt</mat-icon>{{ 'Intellitrailer.MyPage.BookingOverview.SendReceipt' | translate : 'Send receipt to email' }}</button>
  <button mat-menu-item (click)="openTrailerInfo()"><mat-icon>info</mat-icon>{{ 'Intellitrailer.MyPage.BookingOverview.TrailerInfo' | translate : 'Trailer information' }}</button>
  <button mat-menu-item *ngIf="false" (click)="createFaultReport()"><mat-icon>report</mat-icon>{{ 'Intellitrailer.MyPage.BookingOverview.FaultReport' | translate : 'Fault report' }}</button>
</mat-menu>

