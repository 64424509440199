import { Component, ElementRef, OnInit } from '@angular/core';
import { PageBase, RentalInfo, RentalStatus } from 't4core';
import { NavigationService } from 'app-components';
import { BookingService } from 'app-components';
import { ActiveRentalService } from 'app-components';
import * as moment from 'moment';
import { LayoutService } from 'app-components';
import { ImportBookingComponent } from '../../Dialogs/import-booking/import-booking.component';

@Component({
  selector: 'app-my-bookings',
  templateUrl: './my-bookings.component.html',
  styleUrls: ['./my-bookings.component.css']
})
export class MyBookingsComponent extends PageBase implements OnInit {
  public bookings: RentalInfo[];
  public activeRents: RentalInfo[] = [];
  public futureRents: RentalInfo[] = [];
  public pastRents: RentalInfo[] = [];
  public canceledBookings: RentalInfo[] = [];
  public status = RentalStatus;
  public currentTab = 0;
  public showAdminUnlock: boolean = false;
  public firstName: string;

  public allowEarlyPickup: boolean;

  // public EarlyPickupLimitInMinutes: any; /// T4C.Current.Settings.EarlyPickupLimitInMinutes;

  constructor(private navigator: NavigationService, el: ElementRef, private aviationService: NavigationService, private rentalService: ActiveRentalService, private bookingService: BookingService, public layoutService: LayoutService) {
    super(el);
    };

  async ngOnInit(): Promise<void> {
    await this.getMyActiveAndFutureBookings();

    this.allowEarlyPickup;

  }

  public async getMyActiveAndFutureBookings() {
    var token = this.UI.beginLoading("Intellitrailer.MyPaage.Overview.LoadingBookingsMsg", "Loading bookings", null);
    this.loading = true;
    this.activeRents = [];
    this.futureRents = [];
    var activeOrFutureBookings = await this.Api.get<RentalInfo[]>("Rental/GetMyActiveAndFutureBookings");

    if (activeOrFutureBookings) {
      for (var booking of activeOrFutureBookings) {
        if (booking.Status == RentalStatus.Active || booking.Status == RentalStatus.Late) this.activeRents.push(booking);
        if (booking.Status == RentalStatus.Reserved || booking.Status == RentalStatus.ReservedAndBound) this.futureRents.push(booking);
      }

    }

    //Show in descending order
    this.activeRents = this.activeRents.reverse();
    this.futureRents = this.futureRents.reverse();
    this.loading = false;
    this.UI.loaderCompleted(token);

    if (this.navigator.firstTimeShowActiveBookings) {
      this.navigator.firstTimeShowActiveBookings = false;
      let activeBooking: RentalInfo;
      let futureBooking: RentalInfo;

      let numberOfCloseFutureBookings: number = 0;
      let numberOfActiveBookings: number = 0;

      for (var booking of activeOrFutureBookings) {
        console.log(booking.Status);
        if (booking.Status == RentalStatus.Active || booking.Status == RentalStatus.Late) {
          activeBooking = booking;
          numberOfActiveBookings++;
        }
        if (booking.Status == RentalStatus.Reserved && booking.PickupTime < moment().add(30, 'minute')) {
          futureBooking = booking;
          numberOfCloseFutureBookings++;
        }
      }
    }
  }

  public async navigate($event) {
    console.log($event);
    if ($event.navigate == "Rental.InFlight" || $event.navigate == "Rental.BookingInfo") {
      await this.rentalService.setActiveRental($event.booking.Id);
    }

    if ($event.event) this.navigator.executeCommand($event.navigate, { event: $event.event, rentalId: $event.booking.Id });

    else this.navigator.executeCommand($event.navigate, { rentalId: $event.booking.Id });
  }

  public async cancelled(bookingId: number) {
    await this.sortBookingsAfterCancelling(bookingId);
  }

  public async sortBookingsAfterCancelling(bookingId: number): Promise<boolean> {
    for (let i = 0; i < this.futureRents.length; i++) {
      if (this.futureRents[i].Id == bookingId) {
        this.canceledBookings.unshift(await this.Api.get<RentalInfo>("Rental/GetRentalInfo", { bookingId: bookingId }));
        this.futureRents.splice(i, 1);
        return true;
      }
    }
    for (let i = 0; i < this.activeRents.length; i++) {
      if (this.activeRents[i].Id == bookingId) {
        this.canceledBookings.unshift(await this.Api.get<RentalInfo>("Rental/GetRentalInfo", { bookingId: bookingId }));
        this.activeRents.splice(i, 1);
        return true;
      }
    }
    return false;
  }

  public toggleTab(number) {
    this.currentTab = number;

    if (this.currentTab == 1) this.getMyBookingHistory();
  }

  public async getMyBookingHistory(reloadAll: boolean = false) {
    if (!this.bookings || reloadAll) {
      var token = this.UI.beginLoading("Intellitrailer.MyPaage.Overview.LoadingBookingsMsg", "Loading bookings", null);
      this.loading = true;
      this.bookings = await this.Api.get<RentalInfo[]>("Rental/GetMyCompletedAndDeletedBookings");

      if (this.bookings) {
        for (var booking of this.bookings) {
          if (booking.Status == RentalStatus.Returned) this.pastRents.push(booking);
          if (booking.Status == RentalStatus.Canceled) this.canceledBookings.push(booking);
        }

      }
      this.loading = false;
      this.UI.loaderCompleted(token);
    }
  }

  public async newBooking() {
    var success = await this.bookingService.startSession();
    console.log(success);
    if (success) {
      this.navigator.executeCommand("NewBooking");
    }
  }

  public async refreshBookings() {
    if (this.currentTab == 0) {
      this.getMyActiveAndFutureBookings();
    }
    else {
      this.getMyBookingHistory(true);
    }
  }

  public async importBooking() {
    const dialogRef = this.Dialog.open(ImportBookingComponent, {
      width: '350px',
      position: { top: '50px' },
      data: { verified: false, isBooking: false }
    });

    var result = await dialogRef.afterClosed().toPromise();
    if (result) {
      this.aviationService.reload();
    }
  }

}
