import { Component, OnInit, ElementRef } from '@angular/core';
import { PageBase } from 't4core';
import { LayoutService } from 'app-components';
import { NavigationService } from 'app-components';
import { ActiveRentalService } from 'app-components';

@Component({
  selector: 'app-confirm-lock',
  templateUrl: './confirm-lock.component.html',
  styleUrls: ['./confirm-lock.component.css']
})
export class ConfirmLockComponent extends PageBase implements OnInit {

    public explanation: string;

  constructor(el: ElementRef, private aviationService: NavigationService, private rentalService: ActiveRentalService, private layoutService: LayoutService) {
    super(el);
  }

    public async ngOnInit() {
        this.explanation = await this.translate('Intellitrailer.Rental.ConfirmLock.ReturnProccessInfo', 'To complete the return process you must return the trailer to the return location and lock it using the provided cover and padlock.');
  }

    public cmdYes_Click() {
      this.rentalService.fetchSecurity(false);
        this.aviationService.executeCommand("Yes", {event: "Return"});
  }

  public cmdNo_Click() {
    this.aviationService.executeCommand("No");
  }

}
