<app-view-title Title="" [isPrimaryColor]="true"></app-view-title>
<div class="view-content-full centered-view" *ngIf="returnAction">
  <app-information-screen *ngIf="paymentFailed" class="horizontal-vertical-center font-secondary-color"
                          title="{{ 'Intellitrailer.Booking.PaymentFailed.PaymentNotCompletedTitle' | translate : 'Payment not completed' }}"
                          primaryContent="{{ 'Intellitrailer.Booking.PaymentFailed.PaymentCancelled' | translate : 'The payment was cancelled. Please try again.' }}"
                          secondaryContent="">
  </app-information-screen>
</div>
<div class="view-content-full centered-view" *ngIf="!returnAction">
  <ng-container *ngIf="redirecting">
    <mat-progress-spinner diameter="75" mode="indeterminate" class="d-inline-block"></mat-progress-spinner>
    <h2>{{ 'Intellitrailer.Booking.Payment.PreparingPayment' | translate : 'Preparing payment' }}...</h2>
    <div innerHTML="{{ 'Intellitrailer.Booking.Payment.msgPreparingPayment' | translate : 'You are being redirected to payment' }}"></div>
  </ng-container>
  <app-information-screen *ngIf="!redirecting && amount" class="horizontal-vertical-center font-secondary-color"
                          title="{{ 'Intellitrailer.Booking.PaymentFailed.InsufficientFunding' | translate : 'This booking is not fully paid' }}"
                          primaryContent="{{ 'Intellitrailer.Booking.PaymentFailed.UnsettledAmount' | translate : 'An additional {0} needs to paid.' : [ amount ] }}"
                          secondaryContent="{{ 'Intellitrailer.Booking.PaymentFailed.UnsettledDescription' | translate : 'Either the booking has changed since it was booked or the credit card you added could not be charged.' }}">
  </app-information-screen>
</div>

<div class="view-actions">
  <div *ngIf="paymentFailed">
    <button mat-raised-button (click)="retry()" color="accent">{{ 'Intellitrailer.Booking.Paymen.cmdRetry' | translate : 'Retry' }}</button>
  </div>
  <div *ngIf="!returnAction && !redirecting"> 
    <button mat-raised-button (click)="redirect()" color="accent">{{ 'Intellitrailer.Booking.Paymen.cmdPay' | translate : 'Pay' }}</button>
  </div>
</div>

