<div [ngClass]=" displayMap ? 'location-wrapper' : ''" *ngIf="location">
  <div class="test" *ngIf="displayMap">

  </div>
  <div class="map" *ngIf="displayMap">
    <img class="map" #map *ngIf="displayMap" src="{{mapUri}}" />
    <div class="search" *ngIf="false">
      <span class="search-text">{{ 'Intellitrailer.Booking.BeginBooking.SearchLocation' | translate : 'Search location' }}</span>
      <span class="material-icons">
        search
      </span>
    </div>
  </div>
  <span></span>
  <span *ngIf="!displayMap" class="material-icons">location_on</span>
  <div class="address">
    <p>
      <strong>{{location?.Name}}</strong><br />
      {{location.Address}}, {{location.City}}
    </p>

    <p *ngIf="displayOpenHours">
      {{ 'Intellitrailer.Component.LocationInformation.OpenHours' | translate : 'Open hours' }}<br />
      {{location?.OpenHours}}
    </p>
  </div>
</div>
