<div class="information-screen">
  <h1>{{ 'Intellitrailer.LocationPermissionInfo.Title' | translate : 'Enable location services'}}</h1>

  <mat-icon>location_on</mat-icon>

  <h2>{{'Intellitrailer.LocationPermissionInfo.SubTitle' | translate : 'We need your location to unlock.' }}</h2>
  <p>{{ 'Intellitrailer.LocationPermissionInfo.Text' | translate : 'In order to determine that you are at the correct location for pickup/return of the trailer and to connect to the bluetooth lock we need to access your location. This information is not stored in any way.'}}</p>
  <button mat-raised-button color="accent" (click)="askForPermission()" *ngIf="!permissionAsked">{{ 'Intellitrailer.LocationPermissionInfo.cmdAskPermission' | translate : 'I understand' }}</button>
  <button mat-raised-button color="accent" (click)="openSettings()" *ngIf="permissionAsked">{{ 'Intellitrailer.LocationPermissionInfo.cmdOpenSettings' | translate : 'Open settings' }}</button>
</div>
