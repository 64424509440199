<h3 class="text-center" mat-dialog-title>{{ 'Intellitrailer.MyPage.Dialog.GPSDialog.Title' | translate : 'Activate location access' }}</h3>
<div mat-dialog-content>
  <div class="container" style="overflow: hidden; background-color: transparent; border: 0;">
    <div class="row"></div>
    <div class="row" *ngIf="loading">
      <div class="componentLoader">
        <mat-progress-spinner diameter="25" mode="indeterminate"></mat-progress-spinner>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        {{ 'Intellitrailer.MyPage.Dialog.GPSDialog.PleaseActivate' | translate : 'Please activate location access to be able to carry through with bookings with the app.' }}
      </div>
    </div>
  </div>
</div>
<div class="wide-dialog-buttons" mat-dialog-actions>
  <button mat-button mat-raised-button (click)="openSettings()">{{ 'Intellitrailer.MyPage.Dialog.GPSDialog.openSettings' | translate : 'Open settings' }}</button>
  <button mat-button mat-raised-button (click)="onNoClick()">{{ 'Intellitrailer.MyPage.Dialog.GPSDialog.Close' | translate : 'Close' }}</button>
</div>
