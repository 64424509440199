<div *ngIf="loader" class="componentLoader">
  <mat-progress-spinner diameter="25" mode="indeterminate"></mat-progress-spinner>
</div>
<div>
  <span *ngIf="disabled && selectedTime">{{ selectedTime.format("HH:mm") }}</span>
  <div #mnuOptions class="timeSelector-app" *ngIf="!loader && anyValidTimes">
    <div class="timeOption" id="time{{t.text}}" *ngFor="let t of options"
         [ngClass]="
           { 'isValid': t.isValid && !t.collidingBooking,
           'collidingBooking': t.collidingBooking,
           'selected': t.isSelected && t.isValid,
           'selectable': t.isValid && !t.collidingBooking || overrideTimeLimits && !t.collidingBooking,
           'showOptions': t.showOptions}"
         (click)="toggleHourOptions(t);">
      <div *ngIf="!t.showOptions">{{ t.text }}</div>
      <div *ngIf="t.showOptions" style="position: relative; z-index: 11; width: 100%;" id="time{{t.text}}">{{ t.text }}</div>
      <div class="suboption-panel" *ngIf="t.showOptions">
        <div *ngFor="let option of t.options" class="timeOption" id="time{{t.text}}"
             [ngClass]="
               {'isValid': option.isValid && !option.collidingBooking,
               'collidingBooking': option.collidingBooking,
               'selected': option.isSelected,
               'selectable': option.isValid && !option.collidingBooking || overrideTimeLimits && !option.collidingBooking}"
             (click)="selectTime(option);">
          {{ option.text }}
        </div>
      </div>
      <div *ngIf="t.showOptions" class="suboption-backfill"></div>
    </div>
  </div>
  <div id="loader" *ngIf="!anyValidTimes && !loader && !isClosed">{{ 'Intellitrailer.Component.TimePicker.NoAvailableTimes' | translate : 'No available times for date' }}</div>
  <div id="loader" *ngIf="!anyValidTimes && !loader && isClosed">{{ 'Intellitrailer.Component.TimePicker.StoreClosed' | translate : 'Sorry, the store is closed on this day.' }}</div>
</div>
