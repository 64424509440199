import { Component, ElementRef, OnInit } from '@angular/core';
import { NavigationService } from 'app-components';
import { AppIntegrationService, AppSettingsService } from 't4-app-integration';
import { PageBase } from 't4core';

@Component({
  selector: 'app-return-complete',
  templateUrl: './return-complete.component.html'
})
export class ReturnCompleteComponent extends PageBase implements OnInit {
  public theme: string;
  constructor(el: ElementRef, private aviationService: NavigationService, public appSettings: AppSettingsService, private appIntegrationService: AppIntegrationService) {
    super(el);
  }

  async ngOnInit() {
    this.theme = this.appSettings.settings.theme || 'Default';
    this.appIntegrationService.requestAppReview();
  }


  public confirm() {
    this.aviationService.executeCommand("TakeMeHome");
  }
}
