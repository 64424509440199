<app-view-title [isPrimaryColor]="true" Title="{{ 'Intellitrailer.Booking.Components.BookingSummary.Trailer' | translate : 'Trailer'}}: {{ booking?.RentalObject?.Title }}"></app-view-title>
<view-content [centered]="true" [fullscreen]="true">
  <app-btPermissionRequired *ngIf="!ignoreBTCheck && appSvc.Device.Bluetooth < 2"></app-btPermissionRequired>
  <app-locationPermissionRequired *ngIf="(ignoreBTCheck || appSvc.Device.Bluetooth >= 2) && !ignoreLocationCheck && appSvc.Device.Location < 2"></app-locationPermissionRequired>
  <ng-container *ngIf="(ignoreBTCheck || appSvc.Device.Bluetooth >= 2) && (ignoreLocationCheck || appSvc.Device.Location >= 2)">
    <ng-container *ngIf="!hasFailed">
      <app-card>
        <div class="spinner-container" *ngIf="!recentlyUnlocked">
          <mat-progress-spinner diameter="75" class="spinner" mode="determinate" value="{{count * (3 + 1.0/3.0)}}">
          </mat-progress-spinner>
          <h1 style="font-size: 24px">{{ 30 - count }}</h1>
        </div>
        <ng-container *ngIf="isSearching">
          <h2>{{ 'Intellitrailer.Rental.NokeLock.Searching' | translate : 'Searching for lock' }}...</h2>
          <p>{{ 'Intellitrailer.Rental.NokeLock.ActiveLock' | translate : 'Gently press on the bottom part of the lock to activate it. ' }}</p>
          <div class="press-sensor-image"></div>
        </ng-container>
        <ng-container *ngIf="isUnlocking">
          <h2>{{ 'Intellitrailer.Rental.NokeLock.Unlocking' | translate : 'Unlocking' }}...</h2>
          <p>{{ 'Intellitrailer.Rental.NokeLock.UnlockingInfo' | translate: 'This process normally takes between 5 and 10 seconds' }}</p>
        </ng-container>
        <ng-container *ngIf="recentlyUnlocked">
          <h2>{{ 'Intellitrailer.Rental.NokeLock.TitleLockNotOpen' | translate: 'Did the lock not open?' }}</h2>
          <div class="shake-lock-image"></div>
          <h2>{{ 'Intellitrailer.Rental.NokeLock.Title2LockNotOpen' | translate: 'Check the shackle' }}</h2>
          <p>{{ 'Intellitrailer.Rental.NokeLock.txtLockNotOpen1' | translate: 'Sometimes the shackle gets stuck, if it does try this:'}}</p>
          <p>{{ 'Intellitrailer.Rental.NokeLock.txtLockNotOpen2' | translate: 'Shake the lock for 5-10 seconds.'}}</p>
          <p>{{ 'Intellitrailer.Rental.NokeLock.txtLockNotOpen3' | translate: 'Pull the lock downward at the same time you unlock it in the app.'}}</p>
          <button mat-raised-button (click)="continueUnlock()"> {{ 'Intellitrailer.Rental.NokeLock.ButtonLockNotOpen' | translate: 'Continue' }} </button>
        </ng-container>
      </app-card>
    </ng-container>

    <app-card *ngIf="hasFailed && failReason == null">
      <h2>{{ 'Intellitrailer.Rental.NokeLock.NotFound' | translate : 'Lock was not found' }}</h2>
      <p>{{ 'Intellitrailer.Rental.NokeLock.NotFoundInfo' | translate : 'Make sure you are at the correct trailer.' }}</p>
      <button mat-raised-button (click)="retry()">{{ 'Intellitrailer.Rental.NokeLock.Retry' | translate : 'Retry' }}</button>
    </app-card>

    <app-card *ngIf="hasFailed && failReason == -1">
      <h2>{{ 'Intellitrailer.Rental.NokeLock.headBluetoothError-1b' | translate : 'Could not find the lock' }}</h2>
      <p>{{ 'Intellitrailer.Rental.NokeLock.msgBluetoothError-1b' | translate : 'Verify that the light on the bottom of the lock lights upp when you press it. If this problem persists, please restart the app.' }}</p>
      <button mat-raised-button (click)="retry()">{{ 'Intellitrailer.Rental.NokeLock.Retry' | translate : 'Retry' }}</button>
    </app-card>

    <app-card *ngIf="hasFailed && failReason == 0">
      <h2>{{ 'Intellitrailer.Rental.NokeLock.headBluetoothError0' | translate : 'Bluetooth access denied' }}</h2>
      <p>{{ 'Intellitrailer.Rental.NokeLock.msgBluetoothError0' | translate : 'Access to bluetooth is required to be able to unlock the lock. Please grant the permissions in you phone settings. Or re-install the app and it will ask you for the permission again.' }}</p>
      <button mat-raised-button (click)="retry()">{{ 'Intellitrailer.Rental.NokeLock.Retry' | translate : 'Retry' }}</button>
      <button mat-raised-button color="accent" (click)="openSettings()" *ngIf="appSettings.meetsMinimumAppVersion(3.0)">{{ 'Intellitrailer.Rental.NokeLock.cmdPermissions' | translate : 'Open settings' }}</button>
    </app-card>

    <app-card *ngIf="hasFailed && failReason == 1">
      <h2>{{ 'Intellitrailer.Rental.NokeLock.headBluetoothError1' | translate : 'Bluetooth is not enabled' }}</h2>
      <p>{{ 'Intellitrailer.Rental.NokeLock.msgBluetoothError1' | translate : 'Access to bluetooth is required to be able to unlock the lock. Please enable bluetooth and try again.' }}</p>
      <button mat-raised-button (click)="retry()">{{ 'Intellitrailer.Rental.NokeLock.Retry' | translate : 'Retry' }}</button>
    </app-card>

    <app-card *ngIf="hasFailed && failReason == 10">
      <h2>{{ 'Intellitrailer.Rental.NokeLock.headLocationError0' | translate : 'Location access denied' }}</h2>
      <p>{{ 'Intellitrailer.Rental.NokeLock.msgLocationError0' | translate : 'Access to location is required to be able to unlock the lock. Please grant the permissions in you phone settings. Or re-install the app and it will ask you for the permission again.' }}</p>
      <button mat-raised-button (click)="retry()">{{ 'Intellitrailer.Rental.NokeLock.Retry' | translate : 'Retry' }}</button>
      <button mat-raised-button color="accent" (click)="openSettings()" *ngIf="appSettings.meetsMinimumAppVersion(3.0)">{{ 'Intellitrailer.Rental.NokeLock.cmdPermissions' | translate : 'Open settings' }}</button>
    </app-card>

    <app-card *ngIf="hasFailed && failReason == 20">
      <h2>{{ 'Intellitrailer.Rental.NokeLock.headLocationError1' | translate : 'Location services is not enabled' }}</h2>
      <p>{{ 'Intellitrailer.Rental.NokeLock.msgLocationError1' | translate : 'Access to Location is required to be able to unlock the lock. Please enable Location and try again.' }}</p>
      <button mat-raised-button (click)="retry()">{{ 'Intellitrailer.Rental.NokeLock.Retry' | translate : 'Retry' }}</button>
    </app-card>
  </ng-container>
</view-content>
<view-actions>
  <a (click)="skipBTCheck()" *ngIf="!ignoreBTCheck && appSvc.Device.Bluetooth < 2">{{ 'Intellitrailer.Rental.NokeLock.cmdSkip' | translate : 'Skip' }}</a>
  <a (click)="skipLocationCheck()" *ngIf="(ignoreBTCheck || appSvc.Device.Bluetooth >= 2) && !ignoreLocationCheck && appSvc.Device.Location < 2">{{ 'Intellitrailer.Rental.NokeLock.cmdSkip' | translate : 'Skip' }}</a>
  <button mat-raised-button color="accent" *ngIf="event != 'Active' && hasFailed" (click)="skip()">{{ 'Intellitrailer.Rental.NokeLock.cmdSkip' | translate : 'Skip' }}</button>
</view-actions>

