<app-view-title Title="Ready to roll!" [isGreenColor]="true"></app-view-title>
<div class="view-content centered-view">
  <app-information-screen class="horizontal-vertical-center font-secondary-color"
                          primaryContent="{{ 'Intellitrailer.Rental.PickupComplete.Ready' | translate : 'You have now successfully started your rent.' }}"
                          image="/assets/Images/big-checkbox-white.png">
  </app-information-screen>
</div>
<div class="view-actions">
  <button mat-raised-button color="accent" (click)="next()">{{ 'Intellitrailer.Rental.PickupComplete.cmdNext' | translate : 'Ok! Let´s go!' }}</button>
</div>

