<app-view-title [isPrimaryColor]="true" Title="{{ 'Intellitrailer.Booking.Components.BookingSummary.Trailer' | translate : 'Trailer'}}: {{ booking?.RentalObject?.Title }}"></app-view-title>
<view-content [centered]="true" [fullscreen]="true">
  <ng-container>
    <ng-container *ngIf="!hasFailed">
      <app-card>
        <ng-container>
          <h2>{{ 'Intellitrailer.Rental.IglooLock.IglooUnlock' | translate : 'Unlock' }}</h2>
          <div class="lock-keypad-image"></div>
          <p *ngIf="!isUnlockPin">{{ 'Intellitrailer.Rental.IglooLock.IglooUnlockWaiting' | translate: 'Please wait while we generate the unlock pin' }}...</p>
          <p *ngIf="isUnlockPin">{{ 'Intellitrailer.Rental.IglooLock.IglooUnlockInfo1' | translate: 'Please enter the below pin to unlock.' }}</p>
          <div class="unlock-pin" *ngIf="isUnlockPin">
            <ng-container *ngFor="let pin of unlockPin.split(''); let i = index">
              <span>{{ pin }}</span>
              <ng-container *ngIf="(i + 1) % 3 === 0 && i !== unlockPin.length - 1">
                <span class="space"> </span>
              </ng-container>
            </ng-container>
            <span><mat-icon style="transform:scaleX(-1);">lock_open</mat-icon></span>
          </div>
        </ng-container>
        <button class="unlock-button" mat-raised-button *ngIf="isUnlockPin" (click)="continueIgloo()">{{ 'Intellitrailer.Rental.IglooLock.ContinueIgloo' | translate : 'Continue' }}</button>
        <div *ngIf="isUnlockPin" (click)="troubleshootIglooLock()" class="troubleshoot-link">{{ 'Intellitrailer.Rental.IglooLock.TitleLockNotOpen' | translate: 'Did the lock not open?' }}</div>
      </app-card>
    </ng-container>

    <app-card *ngIf="hasFailed">
      <h2 *ngIf="failReason == 0">{{ 'Intellitrailer.Rental.IglooLock.IglooPinNotGenerated' | translate : 'Could not find the pin to unlock' }}</h2>
      <h2 *ngIf="failReason != 0">{{ 'Intellitrailer.Rental.IglooLock.TitleLockNotOpen' | translate: 'Did the lock not open?' }}</h2>
      <p>{{ 'Intellitrailer.Rental.IglooLock.IglooNotFoundInfo2' | translate : 'Please make sure you are at the correct trailer.' }}</p>
      <p>{{ 'Intellitrailer.Rental.IglooLock.IglooNotFoundInfo1' | translate : 'Verify the lock number provided below with the trailer lock to ensure you are at the correct trailer.' }}</p>
      <p><b>{{ 'Intellitrailer.Rental.IglooLock.Igloo.NotFoundLockNo' | translate : 'Lock'}}: {{iglooLock}}</b></p>
      <button mat-raised-button (click)="retry()">{{ 'Intellitrailer.Rental.IglooLock.Retry' | translate : 'Retry' }}</button>
    </app-card>
  </ng-container>
</view-content>
<view-actions>
  <button mat-raised-button color="accent" *ngIf="event != 'Active' && hasFailed" (click)="skip()">{{ 'Intellitrailer.Rental.IglooLock.cmdSkip' | translate : 'Skip' }}</button>
</view-actions>

