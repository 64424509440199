<app-view-title Title="" [showHeader]="true" [overlay]="true"></app-view-title>
  <location-map #map mode="Local" [locations]="locations" [(selectedLocation)]="selectedLocation" (locationConfirmed)="selectLocation()"></location-map>

  <div class="bottom-nav">
    <div class="bottom-nav-home" *ngIf="typeSettings.isShowMenu">
      <button type="button" mat-raised-button class="home-button" color="accent" (click)="mybooking()">{{'Intellitrailer_Home_Home_cmdMyPage' | translate: 'My reservations'}}</button>
      <div (click)="toggleNewUserInfo(false)" class="link">{{'Intellitrailer_Home_Home_HowDoesItWork' | translate: 'How does it work?' }}</div>
    </div>
  </div>

  <app-new-user-info class="new-user-info d-flex align-items-center" *ngIf="!userClosed && typeSettings.isShowGuide" (showInfo)="toggleNewUserInfo($event)"></app-new-user-info>
  
