import { SecurityCheckPoint } from './SecurityCheckPoint';

export class SecurityCheckModel {
  public CheckPoints: SecurityCheckPoint[] = [
    new SecurityCheckPoint("NoseWheel"),
    new SecurityCheckPoint("SecurityWire"),
    new SecurityCheckPoint("Outside"),
    new SecurityCheckPoint("Inside"),
    new SecurityCheckPoint("Others", true),
  ];

  public Remarks: string = "";

  public get IsValid(): boolean {
    return this.CheckPoints.some(x => !x.Ok && !x.Failed);
  }

  public get HasDeviation(): boolean {
    return this.CheckPoints.some(x => x.Failed);
  }
}
