<app-view-title Title="" [showBack]="false" [showHeader]="false"></app-view-title>

<div class="wizard">
  <div class="wrapper d-flex align-items-center" (click)="close()">
    <div class="box" (click)="preventClose()">
      <div class="finish">
        <button mat-button mat-raised-button *ngIf="activeStep !== numberOfSteps.length - 1" (click)="nextTab()" cdkFocusInitial>{{ 'Intellitrailer.Component.NewUserInfo.Next' | translate : 'Next' }}</button>
        <button mat-button mat-raised-button *ngIf="activeStep === numberOfSteps.length - 1" (click)="close()" cdkFocusInitial>{{ 'Intellitrailer.Component.OWNewUserInfo.TakeMeHome' | translate : 'Lets go!' }}</button>
      </div>
      <mat-tab-group class="new-user-info-tab-group" [(selectedIndex)]="activeStep" (touchstart)="swipeStart($event)" (touchend)="swipeEnd($event)">
        <mat-tab>
          <div class="box-content">
            <div class="container">
              <div class="row text-center">
                <div class="image-placeholder" style="margin-top: -50px">
                  <img class="logo" src="/Styles/{{appSettings.settings.theme}}/Logo.png" style="max-width: 250px; max-height: 250px;" />
                </div>
                <h2>Boka</h2>
                <p>I appen gör du enkelt en bokningsförfrågan som matchar dina önskemål om tid, plats och storlek. När du har hittat rätt anger du dina kontaktuppgifter och registrerar ett betalkort.</p>
                <p>Betalning sker först när du hämtar ut ditt släp.</p>
              </div>
            </div>
            <!-- SVG Animation fully functional, but might encourage a swipe-behaviour the rest of the app doesn't use.
              <svg viewBox="-60 -12 80 25" id="animation-box">
              <circle id="show-swipe-right" r="3"></circle>
            </svg>-->
          </div>
        </mat-tab>
        <mat-tab>
          <div class="box-content"> 
            <div class="container">
              <div class="row text-center">
                <div class="image-placeholder">
                  <img src="/Styles/{{appSettings.settings.theme}}/Images/Wait.webp" />
                </div>
                <h2>Låt oss göra jobbet</h2>
                <p>Vi arbetar stenhårt för att matcha ihop din bokning med ett släp. Bokar du inom en vecka kan vi i de flesta fall bekräfta din bokning direkt. Kan vi inte det kommer vi aktivt bevaka din bokning tills vi hittar ett släp som passar dina önskemål.</p>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab>
          <div class="box-content">
            <div class="container">
              <div class="row text-center">
                <div class="image-placeholder">
                  <img src="/Styles/{{appSettings.settings.theme}}/Images/Confirm.webp" />
                </div>
                <h2>Bekräftelse</h2>
                <p>När vi hittat ett passande släp till dig kommer vi att reservera det och bekräfta din bokning. Du kan lugnt luta dig tillbaka och fortsätta packa.</p>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab>
          <div class="box-content">
            <div class="container">
              <div class="row text-center">
                <div class="image-placeholder">
                  <img src="/Styles/{{appSettings.settings.theme}}/Images/Book.webp" />
                </div>
                <h2>Kör!</h2>
                <p>Bege dig till stationen på bokad tid och hämta ut ditt släp.</p>
                <p><b>Kör försiktigt!</b></p>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
      <div class="box-step">
        <div class="step-circle" *ngFor="let option of numberOfSteps; let i = index" [ngClass]="activeStep == i ? 'normal-circle' : 'active-circle'"></div>
        <button type="button" *ngIf="activeStep != numberOfSteps.length - 1" id="skip" class="btn btn-link" (click)="close()">
          {{ 'Intellitrailer.Component.NewUserInfo.Skip' | translate : 'Skip' }}
        </button>
      </div>
    </div>
  </div>
</div>

