<app-view-title [showBack]="false" [showHeader]="this.userClosed" [isSplitColor]="true"></app-view-title>
<div class="view-content no-padding">

  <div class="d-none d-md-block" style="background-color: var(--main-bg-color)">
    <div *ngIf="appSettings.appType === 'app'">
      <br />
      <div *ngIf="appSettings.appType === 'app'" style="text-align:center">
        <img class="logo" [src]="logoSrc" (error)="logotypeFallback()" />
      </div><br />
      <div class="object-wrapper">
        <div class="select-object-info" style="height:85px;">
          <div class="select-object-info-location">
            {{'Intellitrailer.SelectFamily.txtWelcomeMessage.' + theme | translate: 'Welcome to ' + theme + '!'}}
          </div>
          <div class="select-object-info-text">
            <p class="text-center">
              {{'Intellitrailer.SelectFamily.txtSelectType' | translate: 'Select trailer type'}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex; flex-wrap: wrap; margin-top:20px;" class="justify-content-center ">
      <div *ngFor="let type of filterdCategoryList" (click)="selectType(type)">
        <div class="rounded-border-card-content item-container">
          <div class="container my-auto">
            <div class="image-container">
              <div class="item-image" style="height: 100px; background-image: url({{ type.ImageUri }});"></div>
            </div>
            <div style="white-space:nowrap; text-align:center;">{{'Intellitrailer.SelectFamily.txtFamilyName_' + type.Id | translate: type.Name }}</div>
            <div class="item-desc">{{'Intellitrailer.SelectFamily.txtFamilyDescription_' + type.Id | translate: type.Description}}</div>
            <div class="title-with-divider"></div>
          </div>
            <button type="button" class="col-12" style="min-width: 100px; margin: 0 auto" mat-raised-button>
              <strong>
                {{ 'tIntellitrailer.Booking.Components.BookingOption.Select' | translate : 'Select'}}
              </strong>
            </button>
        </div>
      </div>
    </div>
  </div>

  <div class="d-md-none" style="background-color: var(--main-bg-color)">
    <br />
    <div *ngIf="appSettings.appType === 'app'" style="text-align:center">
      <img class="logo" [src]="logoSrc" (error)="logotypeFallback()" style="max-width: 250px; max-height: 125px;" />
    </div><br />
    <div class="object-wrapper">
      <div class="select-object-info">
        <div class="select-object-info-location">
          {{'Intellitrailer.SelectFamily.txtWelcomeMessage.' + theme | translate: 'Welcome to ' + theme + '!'}}
        </div>
        <div class="select-object-info-text">
          <p class="text-center">
            {{'Intellitrailer.SelectFamily.txtSelectType' | translate: 'Select trailer type'}}
          </p>
        </div>
      </div>
      <div class="select-object-divider"></div>
      <div *ngFor="let type of filterdCategoryList" (click)="selectType(type)">
        <div class="minimal-item-container">
          <div class="container">
            <div class="row">
              <div class="col-3 image-container" style="min-height:75px">
                <div class="item-image" style="min-height: 75px; background-image: url({{ type.ImageUri }});"></div>
              </div>
              <div class="col-9" style="display:flex; align-items:center;">
                <div class="row propertyRow">
                  <div class="col-12 booking-option-title" style="color:black;">{{'Intellitrailer.SelectFamily.txtFamilyName_' + type.Id | translate: type.Name }}</div>
                  <div class="col-12 booking-option-distance" style="color:black;">{{'Intellitrailer.SelectFamily.txtFamilyDescription_' + type.Id | translate: type.Description}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="loading" style="display:flex; justify-content:center; margin:15px;">
    <mat-spinner diameter="70"></mat-spinner>
  </div>
  <div style="display:flex; justify-content:center; color:white;">
    <button type="button" class="increased-font-lg" mat-button (click)="skip()"> {{'Intellitrailer.SelectFamily.txtAllTrailers' | translate: 'All trailers'}}</button>
  </div>
</div>

<div class="bottom-nav" style="filter: drop-shadow(0px -5px 10px rgba(200,200,200,1)); z-index: 10;">
  <div class="bottom-nav-home" *ngIf="typeSettings.isShowMenu">
    <button type="button" mat-raised-button class="home-button" color="accent" (click)="mybooking()">{{'Intellitrailer_Home_Home_cmdMyPage' | translate: 'My reservations'}}</button>
    <div (click)="toggleNewUserInfo(false)" class="link">{{'Intellitrailer_Home_Home_HowDoesItWork' | translate: 'How does it work?' }}</div>
  </div>
</div>
<app-new-user-info class="new-user-info d-flex align-items-center" *ngIf="!userClosed && typeSettings.isShowGuide" (showInfo)="toggleNewUserInfo($event)"></app-new-user-info>

