<mat-form-field appearance="outline" *ngIf="settings.Visible" style="margin-bottom: 10px;">
  <mat-label>{{ 'turbo.core.forms.field.' + settings.FieldId | translate : settings.Label}}</mat-label>
  <input [id]="settings.FieldId"
         autocomplete="off"
         matInput
         [required]="settings.Required"
         [formControl]="control"
         [placeholder]="settings.Placeholder"
         (blur)="onBlur()">
  <mat-error *ngIf="control.hasError('required')">{{ errors?.required }}</mat-error>
  <mat-error *ngIf="control.hasError('minlength')">{{ errors?.minLength }}</mat-error>
  <mat-error *ngIf="control.hasError('maxlength')">{{ errors?.minLength }}</mat-error>
  <mat-error *ngIf="control.hasError('pattern')">{{ errors?.pattern }}</mat-error>
</mat-form-field>

