<div class="information-screen">
  <h1>{{ 'Intellitrailer.BTPermissionInfo.Title' | translate : 'Enable bluetooth'}}</h1>

  <mat-icon>bluetooth</mat-icon>

  <h2>{{'Intellitrailer.BTPermissionInfo.SubTitle' | translate : 'Bluetooth is required to unlock the trailer' }}</h2>
  <p>{{ 'Intellitrailer.BTPermissionInfo.Text' | translate : 'Our trailers are equipped with a bluetooth lock. In order to unlock it you need to allow the app to connect to bluetooth devices.'}}</p>
  <button mat-raised-button color="accent" (click)="askForPermission()" *ngIf="!permissionAsked">{{ 'Intellitrailer.BTPermissionInfo.cmdAskPermission' | translate : 'I understand' }}</button>
  <button mat-raised-button color="accent" (click)="openSettings()" *ngIf="permissionAsked">{{ 'Intellitrailer.BTPermissionInfo.cmdOpenSettings' | translate : 'Open settings' }}</button>
</div>
