import { Component, Input, OnInit } from '@angular/core';
import { ComponentBase, RentalObjectInfo, ObjectTypeInfo } from 't4core';

@Component({
  selector: 'app-object-info',
  templateUrl: './object-info.component.html',
  styleUrls: ['./object-info.component.css']
})
export class ObjectInfoComponent extends ComponentBase implements OnInit {

  @Input() public rentalObject: RentalObjectInfo;
  @Input() public type: ObjectTypeInfo;

    constructor() { super(); }

    ngOnInit(): void { 
    }

}
