<app-view-title Title="" [isPrimaryColor]="true"></app-view-title>
<div class="view-content-full centered-view">

  <div class="rounded-border-card-content-center-center">
    <div class="rounded-border-card-content-title">{{ 'Intellitrailer.Rental.ConfirmLock.Confirm' | translate : 'Confirm' }}</div>
    <div class="rounded-border-card-primary-text">
      {{ 'Intellitrailer.Rental.ConfirmLock.ReturnProccessInfo' | translate : 'To complete the return process you must return the trailer to the return location and lock it using the provided cover and padlock.' }}
    </div>
    <div class="rounded-border-card-content-title">{{ 'Intellitrailer.Rental.ConfirmLock.Headline' | translate : 'Did you lock the trailer?' }}</div>
    <button class="smaller" mat-raised-button color="accent" (click)="cmdYes_Click()">{{ 'Intellitrailer.Rental.ConfirmLock.cmdYes' | translate : 'Yes' }}</button>
    <button class="smaller" mat-raised-button color="accent" (click)="cmdNo_Click()">{{ 'Intellitrailer.Rental.ConfirmLock.cmdNo' | translate : 'No' }}</button>
  </div>

  </div>

