import { Component, ElementRef, OnInit } from '@angular/core';
import { PageBase, CustomerInfo, CustomerType, User } from 't4core';
import { VerifyAccountComponent } from '../../../MyPage/Components/verify-account/verify-account.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { AppSettingsService } from 't4-app-integration';
import { PinCodeDialog } from '../../Dialogs/pin-code-dialog/pin-code-dialog';
import { UntypedFormControl } from '@angular/forms';
import { NavigationService } from 'app-components';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent extends PageBase implements OnInit {
  public customerType = CustomerType;
  public settingsChanged: boolean = true;
  public languages: string[][] = [["sv", "Svenska"], ["da", "Dansk"], ["de", "Deutsch"], ["en", "English"], ["no", "Norsk"]];
  public language: string = "en";
  public user: User;
  public customer: CustomerInfo;
  public validCustomer = false;

  public Email: string;
  public MobilePhone: string;

  public outline: string = "outline";

  public isCustomerChanged: boolean;

  public openDialogCounter: number = 0;
  public demoSettings: boolean = false;
  public atLocation: boolean = false;
  public demoPhotos: boolean = false;
  public bypassLock: boolean = false;
  public unlockAnyLock: boolean = false;
  public unshackle: boolean = false;


  public userLoggedIn: boolean = false;

  constructor(
    el: ElementRef,
    public appSettings: AppSettingsService,
    private navigationService: NavigationService
  ) {
    super(el);
  }

  async ngOnInit() {

    this.userLoggedIn = this.appSettings.userLoggedIn;
    console.log(this.userLoggedIn);

    if (this.userLoggedIn) {
      await this.getSettings();
      await this.getCustomerInfo();
    }

    this.language = this.getLanguage();

    this.updateDemoSettings();
  }

  public async toggleDemoSettings(event: MatSlideToggleChange) {
    console.log(event);

    var passedVerification = true;

    if (event.checked) {
      passedVerification = await this.verifyDemoSettingsUnlock();
      this.demoSettings = passedVerification;
    }
    else {
      this.demoSettings = false;
    }

    if (event.checked && passedVerification) {
      localStorage.setItem('demoSettings', 'true');
      localStorage.setItem('atLocation', 'false');
      localStorage.setItem('takePhoto', 'false');
      localStorage.setItem('bypassLock', 'false');
      localStorage.setItem('unlockAnyLock', 'false');
    }
    else {
      localStorage.removeItem('demoSettings');
      localStorage.removeItem('atLocation');
      localStorage.removeItem('takePhoto');
      localStorage.removeItem('bypassLock');
      localStorage.removeItem('unlockAnyLock');
    }

    this.updateDemoSettings();

    event.source.checked = passedVerification;

  }

  public updateDemoSettings() {
    if (localStorage.getItem('demoSettings')) {
      this.demoSettings = localStorage.getItem('demoSettings') ? true : false;
    }
    else {
      this.demoSettings = false;
    }

    if (localStorage.getItem('atLocation')) {
      this.atLocation = localStorage.getItem('atLocation') == "true" ? true : false;
    }
    else {
      this.atLocation = false;
    }

    if (localStorage.getItem('takePhoto')) {
      this.demoPhotos = localStorage.getItem('takePhoto') == "demo" ? true : false;
    }
    else {
      this.demoPhotos = false;
    }

    if (localStorage.getItem('bypassLock')) {
      this.bypassLock = localStorage.getItem('bypassLock') == "true" ? true : false;
    }
    else {
      this.bypassLock = false;
    }

    if (localStorage.getItem('unlockAnyLock')) {
      this.unlockAnyLock = localStorage.getItem('unlockAnyLock') == "true" ? true : false;
    }
    else {
      this.unlockAnyLock = false;
    }

    if (localStorage.getItem('unshackle')) {
      this.unshackle = localStorage.getItem('unshackle') == "true" ? true : false;
    }
    else {
      this.unshackle = false;
    }
  }

  public async changeBookingType(event: MatSlideToggleChange) {
    console.log(event);
    this.atLocation = event.checked;

    if (event.checked) localStorage.setItem('atLocation', 'true');
    else localStorage.setItem('atLocation', 'false');
  }

  public async changeLockBypass(event: MatSlideToggleChange) {
    console.log(event);
    this.bypassLock = event.checked;

    if (event.checked) localStorage.setItem('bypassLock', 'true');
    else localStorage.setItem('bypassLock', 'false');
  }

  public async changePhotoType(event: MatSlideToggleChange) {
    console.log(event);
    this.demoPhotos = event.checked;

    if (event.checked) localStorage.setItem('takePhoto', 'demo');
    else localStorage.setItem('takePhoto', 'false');
  }

  public async toggleAdminUnlockAccess(event: MatSlideToggleChange) {
    console.log(event);
    this.unlockAnyLock = event.checked;

    if (event.checked) localStorage.setItem('unlockAnyLock', 'true');
    else localStorage.setItem('unlockAnyLock', 'false');
  }

  public async toggleUnshackleAccess(event: MatSlideToggleChange) {
    console.log(event);
    this.unshackle = event.checked;

    if (event.checked) localStorage.setItem('unshackle', 'true');
    else localStorage.setItem('unshackle', 'false');
  }

  public async getSettings() {
      this.user = this.Settings.getUser();
      let userDetails = await this.Api.get<any>("Settings/getUserDetails");
      this.Email = userDetails.Email;
      this.MobilePhone = userDetails.MobilePhone;
  }

  private getLanguage(): string {
    //If we have a local storge Language, use that.
    if (this.appSettings.getLanguage()) {
      return this.appSettings.getLanguage();
    }
    if (this.Settings?.clientSettings?.Language) {
      return this.Settings.clientSettings.Language
    }
    return AppSettingsService.GetBrowserLanguage();
  }

  private async getCustomerInfo(): Promise<void> {
    this.customer = await this.Api.get<CustomerInfo>("Rental/FindCustomerByUser", { userID: this.Settings.getUser().Username });
    this.validCustomer = (this.customer != null);
  }

  public phoneChange(control: UntypedFormControl) {
    this.MobilePhone = control.value || this.MobilePhone;
  }

  public async openDialog(newPhone: string = null): Promise<any> {
    const dialogRef = this.Dialog.open(VerifyAccountComponent, {
      width: '350px',
      position: { top: '50px' },
      data: { verified: false, isBooking: true, MobilePhone: this.MobilePhone, Email: this.Email, newPhone: newPhone }
    });
    var result = await dialogRef.afterClosed().toPromise();
    return result;
  }

  public async saveChanges() {
    if (this.validCustomer) {
      var token = this.UI.beginLoading("Intellitrailer.MyPage.Settings.msgLoadingOptions", "Saving settings", null);
      await this.Api.post<boolean>("Account/EditUserInfo", null,
        { username: this.user.Username, email: this.customer.Email, language: this.language });
      await this.saveCustomer();
      this.UI.loaderCompleted(token);
    }
    await this.saveLanguage();
    this.setAsChanged(false);
  }

  public setAsChanged(value: boolean) {
    this.settingsChanged = value;
  }



  public async deleteAccount() {
    if (await this.UI.confirm("Intellitrailer.Menu.headConfirmDeleteUser2", "Confirm deletion", "Intellitrailer.Menu.msgConfirmDeleteUser2", "Do you want do delete your account? If you have any active bookings those will be automaitcally canceled.")) {

      var respons = await this.Api.post<any>("Customer/DeleteCustomerDataIfEligible", this.customer);
      console.log(respons);
      if (respons == 0) {
        await this.UI.alert(
          "Intellitrailer.Menu.headAccountDeleted",
          "Your account has been removed!",
          "Intellitrailer.Menu.msgAccountDeleted",
          "Please note that your account will be recreated if you try to sign in again.");

        if (await this.navigationService.requiresLogin("Start") && !this.appSettings.getUserId()) {
          this.navigationService.menuNavigate("MyPage.SignIn", 'C');
        } else {
          this.navigationService.menuNavigate("Start", 'D');
        }
         this.appSettings.dumpUser();
      }
      else if (respons == 3) {
        await this.UI.alert(
            "Intellitrailer.Menu.headAccountDeletedPendingBooking",
            "Unable to delete your account",
            "Intellitrailer.Menu.msgAccountDeletedPendingBooking",
            "Unable to delete your account, you have an active or pending booking.");
      }
      else if (respons == 4) {
        await this.UI.alert(
          "Intellitrailer.Menu.headAccountDeletedRecentBooking",
          "Unable to delete your account",
          "Intellitrailer.Menu.msgAccountDeletedRecentBooking",
          "Unable to delete your account, you have had an active booking in last 30 days.");
      }
      else
        await this.UI.alert(
          "Intellitrailer.Menu.headAccountDeleteUnknownError",
          "Unable to delete your account",
          "Intellitrailer.Menu.msgAccountDeletedUnknownError",
          "Unable to delete your account, something went wrong.");
      }
   
    }



  private async saveCustomer(): Promise<boolean> {
    console.log("Save Customer Lanuched");
    if (!this.customer || !this.validCustomer) {
      this.UI.alert(
        "Intellitrailer.MyPage.Settings.headInvalidCustomerInformation",
        "The customer could not be saved",
        "Intellitrailer.MyPage.Settings.msgInvalidCustomerInformation",
        "The customer is missing or contains invalid information.");
      return false;
    }
    var savedCustomer = await this.Api.post<CustomerInfo>("Rental/SaveCustomer", this.customer, { isOneWay: this.Settings.clientSettings.IsOneWay });
    this.customer = savedCustomer;
    this.appSettings.customer = savedCustomer;
    this.appSettings.getUser();
  }

  public async verifyDemoSettingsUnlock(): Promise<boolean> {
    const dialogRef = this.Dialog.open(PinCodeDialog, {
      width: '100%',
      height: 'calc(100% - 100px)',
      position: { top: '50px' },
      disableClose: true,
      data: { verified: false, isBooking: false, contextChange: true }
    });
    var result = await dialogRef.afterClosed().toPromise();
    return result == '9600';
  }

  private async saveLanguage() {
    this.Translate.setPreferedLanguage(this.language);
    this.Translate.changeLanguage(this.language);
    this.appSettings.setLanguage(this.language);
    await this.Api.get<boolean>("Settings/ChangeLanguage", { language: this.language });
  }

  public openTestVersion() {
    let testUri = `https://t4-booking-test.azurewebsites.net/?externalId=${this.appSettings.contextId}&privateKey=${this.appSettings.privateKey}&scheme=${this.appSettings.appScheme}&appVersion=${this.appSettings.appVersion}&appType=${this.appSettings.appType}`;
    window.location.href = testUri;
  }
}
