<h3 class="text-center" mat-dialog-title>{{ 'Intellitrailer.MyPage.Dialog.ImportBookingTtl' | translate : 'Import booking' }}</h3>
<div mat-dialog-content>
  <div class="container" style="overflow: hidden" *ngIf="!nextClicked">
    <div class="row" *ngIf="loading">
      <mat-progress-spinner class="componentLoader" diameter="25" mode="indeterminate"></mat-progress-spinner>
    </div>
    <div class="row" *ngIf="!loading && form">
      <span class="col-12 mb-2">
        {{ 'Intellitrailer.MyPage.Dialog.ImportBooking.Guide' | translate : 'If you have made a booking on the web you can import it to the app using the 6 digit code found at the end of your booking confirmation email.' }}
      </span>
      <img class="col-12 mb-2" src="/assets/Images/booking-code2.png" />
    </div>
  </div>
  <div class="container" style="overflow: hidden" *ngIf="nextClicked">
    <div class="row" *ngIf="loading">
      <mat-progress-spinner class="componentLoader" diameter="25" mode="indeterminate"></mat-progress-spinner>
    </div>
    <div class="row" *ngIf="!loading && form">
      <div class="col-12 mb-2">
        {{ 'Intellitrailer.MyPage.Dialog.ImportBooking.ExplanationSSNAndCode' | translate : 'To import a booking made on the website, please type in your SSN and the 6 digit code found at the end of your booking confirmation email.' }}
      </div>
      
      <form [formGroup]="form" class="col-12">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Intellitrailer.MyPage.Dialog.ImportBooking.txtPhone' | translate : 'Phone number (With country code)'}}</mat-label>
          <input autocomplete="off" matInput required formControlName="SSN">
          <mat-error *ngIf="form.controls.SSN.invalid">{{ 'Intellitrailer.MyPage.ValidateAccount.InvalidSSN' | translate : 'A valid phone number is required' }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Intellitrailer.MyPage.ValidateAccount.BookingCode' | translate : 'Booking code' }}</mat-label>
          <input autocomplete="off" matInput required formControlName="BookingCode" placeholder="XXXXXXXX">
          <mat-error *ngIf="form.controls.BookingCode.invalid">{{ 'Intellitrailer.MyPage.ValidateAccount.InvalidCode' | translate : 'The code entered was invalid.' }}</mat-error>
        </mat-form-field>
      </form>
    </div>
  </div>
</div>
<div class="wide-dialog-buttons" mat-dialog-actions>
  <button *ngIf="!nextClicked" mat-button mat-raised-button (click)="next()">
    {{ 'Intellitrailer.MyPage.Dialog.Next' | translate : 'Next' }}
  </button>
  <ng-container *ngIf="nextClicked">
    <button mat-button mat-raised-button (click)="next()" [disabled]="form.invalid" >
      {{ 'Intellitrailer.MyPage.Dialog.ImportTtl' | translate : 'Import' }}
    </button>
    <button mat-button mat-raised-button (click)="cancel()" style="margin-top: 10px">
      {{ 'Intellitrailer.Booking.Dialog.FindLocationDialog.Cancel' | translate : 'Cancel' }}
    </button>
  </ng-container>
</div>
