import { Component, OnInit, Inject, ElementRef } from '@angular/core';
import { PageBase, ExtendRentalOption, ProlongRentalResult, OpenHours, RentalInfo, TypeCategoryInfo, TimeSlot } from 't4core';
import * as moment from 'moment';
import { BookingService, NavigationService, TimeStep } from 'app-components';
import { ActiveRentalService } from 'app-components';
import { LayoutService } from 'app-components';
import { Moment } from 'moment';

@Component({
  selector: 'app-extend-rental-timepicker',
  templateUrl: './extend-rental-timePicker.html',
  styleUrls: ['./extend-rental-timePicker.css']
})
export class ExtendRentalTimePickerComponent extends PageBase implements OnInit {
  public extendOption: ExtendRentalOption;
  public extendOptions: ExtendRentalOption[] = [];
  public loader: boolean = false;
  public selected;
  public priceLoading: boolean = false;
  public selectedCategory: TypeCategoryInfo;

  private timeStep: TimeStep = { step: 15, unit: 'minutes' };
  public returnDate: Date = new Date();
  public pickupDate: Date = new Date();
  public _pickupTime: moment.Moment = moment();
  private returnOpens: moment.Moment;
  private returnCloses: moment.Moment = moment(new Date());
  public additionalCost: number = 0;
  public extendButtonEnabled: boolean = false;

  // -- Time Picker --
  public booking: RentalInfo;
  public minReturn: moment.Moment = moment().startOf('day');
  public maxReturn: moment.Moment = moment().endOf('day');
  public _returnTime: moment.Moment = moment();
  public returnSlots: TimeSlot[] = [];
  public timePickerLoading: boolean = false;
  public returnClosed: boolean = false;


  public bookingTimeLimit: Date;

  constructor(
    elementRef: ElementRef,
    private navigationService: NavigationService,
    private rentalService: ActiveRentalService
  ) {
    super(elementRef);
  }

  async ngOnInit(): Promise<void> {
    this.booking = await this.rentalService.getBooking();
    console.log(this.booking);

    //Start
    let openHours: OpenHours[] = await this.Api.get<OpenHours[]>("/Location/GetOpenHoursForPeriod", {
      locationId: this.booking.ReturnLocationId,
      startDate: this.booking.PickupTime.clone().toISOString(),
      endDate: this.booking.ReturnTime.clone().toISOString()
    });

    //Get the start time of the next booking. If there is none, we return this date + a year as max Limit.
     this.bookingTimeLimit = await this.Api.get<Date>("/Rental/CheckNextBookingForExtend", {
      objectId: this.booking.RentalObject.Id,
      rentalId: this.booking.Id
    })

    var returnT = openHours.find(x => x.Date.clone().startOf("day").isSame(moment(this.returnDate).clone().startOf('day')));
    if (returnT) {
      this.returnOpens = moment(returnT.Opens.clone().utc().format("YYYY-MM-DD HH:mm:ss.SSS"));
      this.returnCloses = moment(returnT.Closes.clone().utc().format("YYYY-MM-DD HH:mm:ss.SSS"));
      this.returnClosed = returnT.IsClosed;
    }

    if (this.booking.PickupTime) {
      this._pickupTime = moment(this.booking.PickupTime.clone().utc().format("YYYY-MM-DD HH:mm:ss.SSS"));
      this.pickupDate = this.booking.PickupTime.toDate();
    }
    if (this.booking.ReturnTime) {
      this.setReturnTime(this.booking.ReturnTime);
    }
    //Sets the date at the time date as the return time.
    this.returnDate = this._returnTime.toDate();

    this.minReturn = this.getMinReturn();
    this.maxReturn = this.getMaxReturn();
    this.getFreeTimeSlots();
  }


  
  public async setReturnTime(rTime: moment.Moment) {
    if (this.returnDate.getDay() != this._returnTime.toDate().getDay()) {
      this.extendButtonEnabled = false;
    }
    if (!rTime || rTime.isSame(this._returnTime)) return;

    if (!rTime) {
      this._returnTime = null;
      return;
    }

    this.extendButtonEnabled = true;
    this._returnTime = rTime;

    this.priceLoading = true;
    if (this._returnTime == this.booking.ReturnTime)
      this.additionalCost = 0;
    else {
      var extendToDate = rTime.clone().toDate().toISOString();
      this.additionalCost = await this.Api.get<number>("Rental/GetExtendedCost", { rentalId: this.booking.Id, extendTo: extendToDate })
    }

    this.priceLoading = false;
  }


  public async setReturnDate(rDate: Date, incrementDate: number = null) {
    if (this.isSameDay(this.returnDate, this.today.toDate()) && incrementDate && incrementDate < 0) {
      return;
    }
    if (rDate) {
      this.returnDate = moment(rDate).clone().toDate();
    }
    else if (incrementDate > 0 || (!this.isSameDay(this.returnDate, this.today.toDate()) && incrementDate < 0)) {
      var newDate = moment(this.returnDate);
      newDate.add(incrementDate, 'day');
      this.returnDate = newDate.clone().toDate();
    }
    this.UpdateOpenHours();
  }


  public async UpdateOpenHours() {
    var openHours: OpenHours[] = await this.Api.get<OpenHours[]>("/Location/GetOpenHoursForPeriod", {
      locationId: this.booking.PickupLocation.Id,
      startDate: moment(this.pickupDate).toISOString(true),
      endDate: moment(this.returnDate).add('days', 1).toISOString(true)
    });

    if (!openHours.length) return;

    var returnT = openHours.find(x => x.Date.clone().startOf("day").isSame(moment(this.returnDate).clone().startOf('day')));
    if (returnT) {
      this.returnOpens = moment(returnT.Opens.clone().utc().format("YYYY-MM-DD HH:mm:ss.SSS"));
      this.returnCloses = moment(returnT.Closes.clone().utc().format("YYYY-MM-DD HH:mm:ss.SSS"));
      this.returnClosed = returnT.IsClosed;
    }
    this.minReturn = this.getMinReturn();
    this.maxReturn = this.getMaxReturn();
    this.getFreeTimeSlots();
  }

  public isSameDay(firstDate: Date, secondDate: Date): boolean {
    return moment(firstDate).format("YYYY-MM-DD") === moment(secondDate).format("YYYY-MM-DD");
  }

  private getMinReturn(): moment.Moment {
    var min;
  
    if (this._pickupTime && (!min || this._pickupTime.toDate().getTime() > min.toDate().getTime())) min = this._pickupTime.clone().add(1, 'hour');
    if (this.returnOpens && (!min || this.returnOpens.toDate().getTime() > min.toDate().getTime())) min = this.returnOpens.clone();
    if (!min || this.roundToTimeStep(moment()).add(1, 'hour').toDate().getTime() > min.toDate().getTime()) min = this.roundToTimeStep(moment()).add(1, 'hour');

    //If its the same day, we set the minReturn as our current ReturnTime.
    if (this.booking.ReturnTime.toDate().getDate() == this.returnDate.getDate()) {
      console.log("SameDay");
      return this.booking.ReturnTime;
    }

    return min;
  }

  private getMaxReturn(): moment.Moment {
    let step: number = 15;

    if (!this.returnCloses) {
      return moment(this.returnDate).clone().endOf('day').subtract(step, 'minutes');
    }

    if (!this.isSameDay(this.returnCloses.toDate(), this.returnDate)) {
      return this.returnCloses.clone().add('second', -1);
    } else {
      return this.returnCloses.clone();
    }
  }

private async getFreeTimeSlots() {
  let maxTime = moment.min(moment(this.returnDate).endOf('day').subtract(this.timeStep.step, this.timeStep.unit), moment(this.bookingTimeLimit));

  this.returnSlots = [{
    From: moment(this.returnDate).startOf('day'),
    To: maxTime,
    SlotNo: 1,
    Date: moment(this.returnDate).startOf('day')
  }];
  return;
}


  private roundToTimeStep(timeStep: moment.Moment, goBackward: boolean = true): moment.Moment {
    timeStep.seconds(0);
    var step = 1;
    var quarters: number[] = [0, 15, 30, 45, 60];
    for (var i = 0; i < 6; i += step) {
      if (i < 4 && quarters[i] <= timeStep.minutes() && timeStep.minutes() < quarters[i + step]) {
        timeStep.minutes(quarters[i]);
        break;
      }
      else if (i == 4) {
        timeStep.minutes(quarters[i]);
      }
    }

    //Round to nearest timeStep backwards
    if (goBackward) {
      timeStep.subtract(1, 'seconds');
    }
    return timeStep.millisecond(0);
  }

  public async extend() {
    this.loader = true;
    this.extendButtonEnabled = false;
   
    var result = await  this.rentalService.extend(this._returnTime);
    this.loader = false;
    if (result == ProlongRentalResult.Success) {
      this.UI.alert("Intellitrailer.Rental.Component.ExtendRentalDialog.ExtendSucessful.Headline",
        "Confirmation!",
        "Intellitrailer.Rental.Component.ExtendRentalDialog.ExtendConfirmed.Message",
        "The booking has been extended.");
      this.loader = false;

      this.navigationService.executeCommand('Confirm', { rentalId: this.booking.Id, extended: true });
    }
    else {
      this.UI.alert("Intellitrailer.Rental.Component.ExtendRentalDialog.ExtendFailed.Headline",
        "Failed!",
        "Intellitrailer.Rental.Component.ExtendRentalDialog.ExtendFailed.Message",
        "The booking could not be extended. The extension collides with another booking.");
    }

    this.extendButtonEnabled = true;
    this.loader = false;
  }

  public cancel() {
    this.navigationService.executeCommand('Confirm');
  }
}
