<app-view-title [isPrimaryColor]="true"></app-view-title>
<div class="view-content centered-view">
    <app-information-screen class="horizontal-vertical-center font-secondary-color"
                            title="{{ 'Intellitrailer.Rental.StowLock.LockOpenTitle' | translate : 'The lock is open!' }}"
                            primaryContent="{{ 'Intellitrailer.Rental.StowLock.LockOpenInstructions' | translate : 'The padlock is now unlocked and you can now remove the cover from the tow ball hitch. Please bring both the padlock and the cover with you.' }}"
                            secondaryContent="{{ 'Intellitrailer.Rental.StowLock.CloseLockInstructions' | translate : 'Remember to close the lock when storing it.' }}">
    </app-information-screen>

</div>
<div class="view-actions">
  <button mat-raised-button color="accent" (click)="next()">{{ 'Intellitrailer.Navigation.Next' | translate : 'Next' }}</button>
</div>

