<app-view-title Title="{{ 'Intellitrailer.Booking.BeginBooking.SignIn ' | translate : 'Sign in' }}" [isPrimaryColor]="true"></app-view-title>
<div class="view-content-full no-padding">
  <div class="sign-in-logo" [ngClass]="{'sign-in-mini' : keyboardOpen, 'sign-in-verification': currentStep == 1}">
    <img class="logo" *ngIf="currentStep !== 1" [src]="'/Styles/' + (appSettings.settings.theme || 'Default') + '/Logo.png'" style="max-width: 250px; max-height: 250px;" />
    <div class="sign-in-verification-title" *ngIf="currentStep == 1">
      {{ 'Intellitrailer.Booking.BeginBooking.Verification ' | translate : 'Verification' }}
    </div>
    <div class="sign-in-verification-explanation">
      {{ 'Intellitrailer.Booking.BeginBooking.Explanation ' | translate : 'Enter the code from your sms' }}
    </div>
  </div>
  <div class="sign-in-content" [ngClass]="{'sign-in-maxi' : (keyboardOpen || currentStep == 1)}" style="padding-top: 30px; max-width:650px; margin: 0 auto">
    <div class="title-with-divider">
      <h1 class="title" *ngIf="fromPath === 'MyPage'">
        {{ 'Intellitrailer.Booking.BeginBooking.SignIn ' | translate : 'Sign in' }}
      </h1>
      <h1 class="title" *ngIf="fromPath === 'Booking'">
        {{ 'Intellitrailer.Booking.BeginBooking.CustomerInformation ' | translate : 'Customer information' }}
      </h1>
    </div>
    <div class="container">
      <div class="row" *ngIf="currentStep == 0">
        <app-customer-form-component #frmCustomer class="col-12" [showFields]="['Email', 'MobilePhone']" [(customer)]="customer"></app-customer-form-component>
      </div>

      <ng-container *ngIf="currentStep == 1">
        <app-verify-account #frmValidation (resendVerification)="sendVerificationCode($event)" (verificationCode)="verify($event)"></app-verify-account>
        <div class="col-12" style="font-size: 14px; font-weight: bold; color: red; text-align: center" *ngIf="validationFailed">
          {{ 'Intellitrailer.MyPage.ValidateAccount.InvalidCode' | translate : 'The code entered was invalid.' }}
        </div>
        <div class="col-12 mt-4 text-center" style="font-size: 14px">
          <p *ngIf="!codeSentAgain" style="margin-top: 10px">
            {{ 'Intellitrailer.MyPage.ValidateAccount.QuestionRecievedCode' | translate : 'Did you not recieve a code?' }}
          </p>
          <p>
            <a *ngIf="!codeSentAgain && firstCode" href="javascript:void(0)" (click)="sendVerificationCode(true)">
              {{ 'Intellitrailer.MyPage.ValidateAccount.SendNewCode' | translate : 'Send new code' }}
            </a>
          </p>
          <p *ngIf="!codeSentAgain && !firstCode">
            {{ 'Intellitrailer.MyPage.ValidateAccount.Sent' | translate : 'Sent!' }}
            <a [routerLink]="" (click)="sendVerificationCode(true)">({{ 'Intellitrailer.MyPage.ValidateAccount.SendAnotherCode' | translate : 'Send another code' }})</a>
          </p>
          <p *ngIf="codeSentAgain">
            {{ 'Intellitrailer.MyPage.ValidateAccount.SendingNewCode' | translate : 'Sending new code' }}...
          </p>
          <p>
            <a href="javascript:void(0)" (click)="currentStep = 0">
              {{ 'Intellitrailer.MyPage.ValidateAccount.ChangePhoneNumber' | translate : 'Change phone number' }}
            </a>
          </p>
        </div>

      </ng-container>
    </div>
  </div>
</div>
<div *ngIf="currentStep == 0" class="view-actions" style="position: absolute; bottom:0;">
  <button type="button" mat-raised-button (click)="confirmData(frmCustomer.validate())">{{ 'Intellitrailer.Navigation.Next' | translate : 'Next' }}</button>
</div>

