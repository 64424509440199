import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ComponentBase } from 't4core';
import { AppSettingsService } from 't4-app-integration';
import { LayoutService } from 'app-components';

interface Swipe {
    xPos: number;
    Time: number;
}

@Component({
  selector: 'app-new-user-info',
  templateUrl: './new-user-info.component.html',
  styleUrls: ['./new-user-info.component.css']
})

export class NewUserInfoComponent extends ComponentBase implements OnInit {
    @Output() showInfo: EventEmitter<boolean> = new EventEmitter();

    public numberOfSteps: number[] = [0, 0, 0, 0];
    public activeStep: number = 0;

    public swipePress: Swipe = { xPos: 0, Time: 0 };
    public swipeRelease: Swipe = { xPos: 0, Time: 0 };

    public theme: string;

  constructor(private layout: LayoutService, public appSettings: AppSettingsService) {
    super();
  }

  ngOnInit(): void {
    this.theme = this.appSettings.settings.theme || 'Default';
  }

  public close() {
        this.showInfo.emit(true);
    }

    public swipeStart(event: TouchEvent) {
        event.stopPropagation();
        this.swipePress.xPos = event.touches[0].clientX;
        this.swipePress.Time = event.timeStamp;
    }

    public swipeEnd(event: TouchEvent) {
        event.stopPropagation();
        this.swipeRelease.xPos = event.changedTouches[0].clientX;
        this.swipeRelease.Time = event.timeStamp;

        if (this.swipeRelease.xPos < (this.swipePress.xPos - 40) && this.activeStep < (this.numberOfSteps.length - 1)) this.activeStep++;
        else if (this.swipeRelease.xPos > (this.swipePress.xPos + 40) && this.activeStep > 0) this.activeStep--;
    }

    public preventClose() {
        event.stopPropagation();
    }

    public prevTab() {
        if (this.activeStep > 0) this.activeStep--;
    }

    public nextTab() {
        if (this.activeStep < (this.numberOfSteps.length - 1)) this.activeStep++;
    }
}
