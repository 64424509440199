<h3 class="text-center" mat-dialog-title>{{ 'Intellitrailer.Booking.Dialog.FindLocationDialogTitle' | translate : 'Search for a location'}}</h3>
<div mat-dialog-content>
  <div>
    <mat-form-field appearance="outline">
      <mat-label *ngIf="mapSearchFocus">{{ 'Turbo.OneWay.Map.lblLocationSearch' | translate : 'Find location'}}</mat-label>
      <mat-icon matPrefix>location_on</mat-icon>
      <input #txtMapSearch matInput type="text" autofocus (focus)="mapSearchFocus = true" (focusout)="mapSearchFocus = false" placeholder="" />
    </mat-form-field>
  </div>
  <div *ngIf="loader" class="componentLoader">
    <mat-progress-spinner diameter="25" mode="indeterminate"></mat-progress-spinner>
  </div>
  <div class="container" *ngIf="!loader">
  <div classs="row" *ngFor="let pickupLocation of nearbyLocations" (click)="setPickupLocation(pickupLocation)" [ngClass]="data.selected && data.selected.Id == pickupLocation.Id ? 'checked' : 'unchecked'">
    <div class="col-12 option">
      <div><strong>{{pickupLocation.Name}}</strong></div>
      <div><strong>{{fixDistance(pickupLocation.Distance)}}</strong></div>
      <div>{{pickupLocation.Address}}</div>
    </div>
  </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-raised-button [mat-dialog-close]="data">OK</button>
  <button mat-button mat-raised-button (click)="onNoClick()">{{ 'Intellitrailer.Booking.Dialog.FindLocationDialog.Cancel' | translate : 'Cancel'}}</button>
</div>

