import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ComponentBase, CustomerField } from 't4core';

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html'
})
export class TextFieldComponent extends ComponentBase implements OnInit, OnChanges {
  @Input() settings: CustomerField = new CustomerField();

  @Input() public value: string
  @Output() public valueChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() public form: UntypedFormGroup;
  public control: UntypedFormControl;

  public errors: ValidationMessages = {
    required: '',
    minLength: '',
    maxLength: '',
    pattern: ''
  };

  constructor() {
    super();

  }

  public async ngOnInit() {
    if (!this.settings.FieldId) return;

    if (!this.control) this.initControl();
  }

  onBlur() {
    if (this.control.value != null) {
      const trimmedValue = this.control.value.trim();
      this.control.setValue(trimmedValue);
      this.valueChange.emit(trimmedValue);
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (!this.control) this.initControl();
    if (changes.value && changes.value.currentValue != changes.value.previousValue) this.control.setValue(this.value);
  }

  private async initControl() {
    if (!this.form) return;

    this.control = new UntypedFormControl(this.settings.Value);
    this.control.valueChanges.subscribe(x => this.valueChange.emit(x));

    this.form.addControl(this.settings.FieldId, this.control);

    // Configure required validation
    if (this.settings.Required) {
      this.control.setValidators(Validators.required);
      this.errors.required = `A valid ${this.settings.FieldId} is required.`;
    }

    // Configure MinLength validation
    if (this.settings.MinLength >= 0) {
      this.control.setValidators(Validators.minLength(this.settings.MinLength));
      this.errors.minLength = `${this.settings.FieldId} must contain at least ${this.settings.MinLength} characters.`;
    }

    // Configure MaxLength validation
    if (this.settings.MaxLength >= 0) {
      this.control.setValidators(Validators.maxLength(this.settings.MaxLength));
      this.errors.minLength = `${this.settings.FieldId} must contain no more than ${this.settings.MaxLength} characters.`;
    }

    // Configure regex validation
    if (this.settings.RegEx) {
      this.control.setValidators(Validators.pattern(this.settings.RegEx));
      this.errors.pattern = `${this.settings.FieldId} must be a legitimate value`;
    }

    // Set default value
    if (this.settings.DefaultValue && !this.control.value) {
      this.control.setValue(this.settings.DefaultValue);
    }

    await this.translateErrorIfSet('required');
    await this.translateErrorIfSet('minLength');
    await this.translateErrorIfSet('pattern');

    if (this.hasTranslatedPlaceholder()) {
      this.settings.Placeholder = await this.Translate.translate(this.settings.Placeholder);
    }
  }

  protected async translateErrorIfSet(label:string) {
    if (this.errors[label]) {
      this.errors[label] = await this.Translate.translate(
        `Intellitrailer.Booking.CustomerForm.${this.settings.FieldId}.${label}`,
        this.errors[label]
      );
    }
  }

  protected hasTranslatedPlaceholder(): boolean {
    let placeholder:string = this.settings.Placeholder?.toLowerCase() ?? null;
    return placeholder && (placeholder.startsWith('turbo.') || placeholder.startsWith('intellitrailer.'));
  }
}
interface ValidationMessages {
  required: string,
  minLength: string,
  maxLength: string,
  pattern: string
}
