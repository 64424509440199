import { Component, ElementRef, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { LocationInfo, ComponentBase } from 't4core';
import { NavigationService } from 'app-components';
import { AppIntegrationService, Coordinates } from 't4-app-integration';

@Component({
  selector: 'location-information-component',
    templateUrl: './location-information.component.html'
})

export class LocationInformation extends ComponentBase {
    @Input() public locationId: number;
    @Input() public location: LocationInfo;
    @Output() locationChange = new EventEmitter<LocationInfo>();

    @Input() public displayMap: boolean = true;
    @Input() public displayOpenHours: boolean = true;
    @Input() public nearbyLocations: LocationInfo[];

    @ViewChild('map') map: ElementRef;

  public userLocation: Coordinates = { Latitude: 0, Longitude: 0 };
  public mapZoom = 11;
  public mapUri: string = "";

  private zoomDistances = [
    { zoom: 15, maxDistance: 0.25 },
    { zoom: 14 , maxDistance: 0.5 },
    { zoom: 13 , maxDistance: 1   },
    { zoom: 11 , maxDistance: 2   },
    { zoom: 10 , maxDistance: 4   },
    { zoom: 9  , maxDistance: 8   },
    { zoom: 9  , maxDistance: 16  },
    { zoom: 8  , maxDistance: 32  },
    { zoom: 7  , maxDistance: 64  },
    { zoom: 6  , maxDistance: 128 },
    { zoom: 5  , maxDistance: 256 },
    { zoom: 4  , maxDistance: 512 },
    { zoom: 3  , maxDistance: 1024},
    { zoom: 2  , maxDistance: 2048},
    { zoom: 1  , maxDistance: 4096}
  ];

  constructor(private navigator: NavigationService, private appService: AppIntegrationService) {
      super();
  }
    async ngOnInit() {
      this.update();
    }

    ngOnChanges(model: any) {
      this.update();
    }

  public async update() {
      if (this.locationId) {
          this.location = await this.Api.get<LocationInfo>("/Location/GetLocationInfo", { locationId: this.locationId });
    }
      var token = this.UI.beginLoading("Intellitrailer.AppService.FetchingPositionMsg", "Fetching position", null);
    this.userLocation = await this.appService.getCoordinates();
    if (this._isDestroyed) return;

      this.UI.loaderCompleted(token);
      var distance = this.appService.getDistance({ Latitude: this.location.Latitude, Longitude: this.location.Longitude }, this.userLocation);

    var zoom = this.zoomDistances.find(x => x.maxDistance > distance);
    this.mapZoom = zoom.zoom;

      //this.mapUri = "https://maps.googleapis.com/maps/api/staticmap?center=" + this.location.Latitude + "," + this.location.Longitude + "&zoom=" + this.mapZoom + "&size=300x220&maptype=roadmap&markers=color:green|" + this.location.Latitude + "," + this.location.Longitude + "&markers=color:blue|" + this.userLocation.Lat + "," + this.userLocation.Lng + "&key=AIzaSyAPITA4UuI9ERN_XiTDXZC1packZFkx-xE";
      if (this.displayMap && this.map.nativeElement) {
          this.mapUri = "https://maps.googleapis.com/maps/api/staticmap?center=" + this.location.Latitude + "," + this.location.Longitude + "&zoom=" + this.mapZoom + "&size=" + this.map.nativeElement.offsetWidth + "x" + this.map.nativeElement.offsetHeight + "&maptype=roadmap&markers=color:green|" + this.location.Latitude + "," + this.location.Longitude + "&markers=color:blue|" + this.userLocation.Latitude + "," + this.userLocation.Longitude + "&key=AIzaSyAPITA4UuI9ERN_XiTDXZC1packZFkx-xE";
      }
  }

    public fixDistance(distance: number): string {
        if (distance < 1) {
            //return +distance.toFixed(2) * 100 + " m"
            return +distance.toFixed(2) + " km";
        }

        return +distance.toFixed(2) + " km";
    }

    public selectChange(location: LocationInfo) {
        this.location = location;
        this.locationChange.emit(location);
    }

  minuteTick() {
    this.update();
  }
}
