<app-view-title Title="{{ 'Intellitrailer.Rental.Component.ExtendRental.Extend' | translate : 'Extend' }}" [isSplitColor]="true"></app-view-title>
<view-content [centered]="true" [fullscreen]="true">
        <h1>{{ 'Intellitrailer.Rental.Component.ExtendRental.lblHowMuchExtraTime' | translate : 'How much extra time do you need?'}}</h1>
        <div *ngIf="loader" class="componentLoader">
          <mat-progress-spinner diameter="75" mode="indeterminate"></mat-progress-spinner>
        </div>
        <mat-form-field *ngIf="!loader && extendOptions.length > 0" appearance="outline">
          <mat-select matNativeControl required [(ngModel)]="selected">
            <mat-option *ngFor="let option of extendOptions" [value]="option">{{option.Hours}} {{ 'Turbo.Common.Hours' | translate : 'hours' }} ({{option.Cost}} {{ this.Settings.clientSettings.Currency }})</mat-option>
          </mat-select>
        </mat-form-field>
  </view-content>
  <view-actions>
    <button mat-raised-button color="accent" (click)="cancel()">{{ 'Intellitrailer.Rental.Component.ExtendRental.cmdCancel' | translate : 'Cancel' }}</button>
    <button mat-raised-button color="primary" (click)="extend()">{{ 'Intellitrailer.Rental.Component.ExtendRental.Extend' | translate : 'Extend' }}</button>
  </view-actions>
  
