<div class="location-picker">
  <div class="search-button" *ngIf="!showSearch">
    <button mat-icon-button (click)="toggleSearch()" color="primary"><mat-icon>search</mat-icon></button>
  </div>
  <div class="search-area" *ngIf="showSearch" [@inOutAnimation]>
    <div class="searchBox">
      <input class="location-search" #search type="text" autofocus />
    </div>
    <button mat-icon-button (click)="toggleSearch()" color="primary"><mat-icon>close</mat-icon></button>
  </div>
</div>
