import { Component, ElementRef, OnInit } from '@angular/core';
import { LayoutService, NavigationService, ActiveRentalService, SecurityCheckModel, SecurityCheckPoint } from 'app-components';
import { AppIntegrationService, AppSettingsService } from 't4-app-integration';
import { PageBase } from 't4core';



@Component({
  selector: 'app-security-check',
  templateUrl: './security-check.component.html'
})
export class SecurityCheckComponent extends PageBase implements OnInit {

  public securityCheck = new SecurityCheckModel();
  public event: string = "";

  constructor(el: ElementRef, private aviationService: NavigationService, private RentalService: ActiveRentalService, public layout: LayoutService, private appService: AppIntegrationService, public appSettings: AppSettingsService) { super(el); }

  async ngOnInit() {
    this.event = this.aviationService.getParam("event");

    var appStore = this.appSettings.clientVersion + " (" + (this.appSettings.appVersion ? this.appSettings.appVersion : "") + ")";
    var info = "Security check. Event: " + this.event + " Device info: " + appStore + ". " + navigator.userAgent;
    let booking = await this.RentalService.getBooking();
    this.Api.get<boolean>("/Rental/SetRentalEvent", { rentalId: booking.Id, content: info });
  }

  public setCheck(p: SecurityCheckPoint, bValue: boolean) {
    p.Ok = bValue;
    p.Failed = !bValue;

    console.log(p.Ok);
  }

  public async confirm() {
    // Save security check
    var token = this.UI.beginLoading("Intellitrailer.Rental.SecurityCheck.msgSavingSecurityCheck", "Saving security check", null);
    var saveCheck = await this.RentalService.saveSecurityCheck(this.securityCheck, this.event);
    this.UI.loaderCompleted(token);

    // Execute command based on result
    if (saveCheck) {
      if (this.securityCheck.HasDeviation) {
        let booking = await this.RentalService.getBooking();
        this.Api.get<boolean>("/Rental/SetRentalEvent", { rentalId: booking.Id, content: "Security check completed: Deviations" });
        this.aviationService.executeCommand("ConfirmWithDeviations");
      }
      else {
        let booking = await this.RentalService.getBooking();
        this.Api.get<boolean>("/Rental/SetRentalEvent", { rentalId: booking.Id, content: "Security check completed: All good!" });
        this.aviationService.executeCommand("Confirm");
      }
    }
  }
}

