
<h4 class="text-center" mat-dialog-title>You still there?</h4>
<div mat-dialog-content>
  <p style="text-align:center;"> {{ 'Intellitrailer.IdleDialog.txtReturnToStartIn' | translate : 'You will be returned to the start page in' }} : {{ timeLeft }}s</p> 

</div>
<div mat-dialog-actions>
  <button mat-button mat-raised-button color="primary" (click)="clickImHere()">
    {{ 'Intellitrailer.IdleDialog.btnImHere' | translate : 'I´m here' }}
  </button>
  <button mat-button mat-raised-button color="accent" (click)="restartSession()">
    {{ 'Intellitrailer.IdleDialog.btnGoToStart' | translate : 'Go to start' }}
  </button>
</div>
