import { ObjectTypeInfo } from 't4core';

export interface TimeSlot {
  Types: ObjectTypeInfo[];
  From: moment.Moment;
  To: moment.Moment;
}

export interface TimeStep {
  step: number;
  unit: moment.unitOfTime.DurationConstructor;
}
export type TimeStepUnit = 'hour' | 'halfhour' | 'quarter'
export type TimeUnit = 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second' | 'millisecond';

export function advanceTimeByUnit(unit: TimeStepUnit): number {
  switch (unit) {
    case 'halfhour':
      return 30;
    case 'quarter':
      return 15;
    default:
      return 60;
  }
}
