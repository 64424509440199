<app-view-title Title="" [isPrimaryColor]="true"></app-view-title>
<div class="view-content-full centered-view">

  <div class="rounded-border-card-content-center-center">
    <div class="rounded-border-card-content-title">{{ 'Intellitrailer.Rental.AskPurchase.Headline' | translate : 'Did you buy anything in the store?' }}</div>
    <div class="rounded-border-card-primary-text">
      {{ 'Intellitrailer.Rental.AskPurchase.AskPurchaseInfo' | translate : 'Have you made any purchases at the store while renting the trailer?' }}
    </div>
    <button class="smaller" mat-raised-button color="accent" (click)="cmdYes_Click()">{{ 'Intellitrailer.Rental.AskPurchase.cmdYes' | translate : 'Yes' }}</button>
    <button class="smaller" mat-raised-button color="accent" (click)="cmdNo_Click()">{{ 'Intellitrailer.Rental.AskPurchase.cmdNo' | translate : 'No' }}</button>
  </div>

</div>

