<app-view-title Title="{{ 'Intellitrailer.MyPage.Settings.TitleSettings' | translate : 'Settings' }}"></app-view-title>
<div class="view-content">
  <div class="container">
    <h6>{{ 'Intellitrailer.MyPage.Settings.Language' | translate : 'Language' }}</h6>
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-select matNativeControl required [(ngModel)]="language" (selectionChange)="setAsChanged(true)">
          <mat-option *ngFor="let option of languages" [value]="option[0]">
            {{option[1]}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row mt-4">
      <div class="col-12" (click)="setAsChanged(true)">
        <app-customer-form-component *ngIf="customer"
                                     [(customer)]="customer"
                                     (isValidFormChange)="validCustomer">
        </app-customer-form-component>
        <ng-container *ngIf="customer">
          <h6>{{ 'Intellitrailer.Booking.Customerform.AccountDetails' | translate: 'Account details' }}</h6>
          <p>{{ 'Intellitrailer.Booking.Customerform.AccountInfo' | translate: 'Your mobile phone and email will be used when signing in to your account in the future.' }}</p>
          <app-customer-details *ngIf="customer" [customer]="customer"></app-customer-details>
        </ng-container>
        <div *ngIf="customer">
          <button style="margin-top: 15px; margin-bottom:15px; width:100%" mat-button (click)="deleteAccount()">Delete my account</button>
        </div>
      </div>
    </div>
  </div>

  <div class="container mt-4 mb-2" *ngIf="customer">
    <h6>
      {{ 'Intellitrailer.MyPage.Settings.DemoSettigs' | translate : 'Demo settings' }}
      <mat-slide-toggle style="margin-left: 5px" [checked]="demoSettings" (change)="toggleDemoSettings($event)" class="checkOk"></mat-slide-toggle>
    </h6>
    <div class="row" *ngIf="demoSettings">
      <div class="col-9" style="font-weight: bold">
        Simulate GPS
      </div>
      <div class="col-3 text-right">
        <mat-slide-toggle [checked]="atLocation" (change)="changeBookingType($event)" class="checkOk"></mat-slide-toggle>
      </div>
      <div class="col-9 mb-2" style="font-size: 13px">
        Your position will always be set to whichever location you rent at
      </div>

      <div class="col-9" style="font-weight: bold">
        Simulate unlocking process
      </div>
      <div class="col-3 text-right">
        <mat-slide-toggle [checked]="bypassLock" (change)="changeLockBypass($event)" class="checkOk"></mat-slide-toggle>
      </div>
      <div class="col-9 mb-2" style="font-size: 13px">
        The app will simulate the unlocking process. Removing the need to physically be at the lock.
      </div>

      <div class="col-9" style="font-weight: bold">
        Simulate photo
      </div>
      <div class="col-3 text-right">
        <mat-slide-toggle [checked]="demoPhotos" (change)="changePhotoType($event)" class="checkOk"></mat-slide-toggle>
      </div>
      <div class="col-9" style="font-size: 13px">
        All photos will be simulated.
      </div>
      <div class="col-12" style="text-align: center; padding:15px 0" *ngIf="Settings.Environment == 0">
        <button mat-stroked-button (click)="openTestVersion()">Test new functionality</button>
      </div>
    </div>
  </div>
</div>

<div class="view-actions">
  <button  mat-raised-button [disabled]="!settingsChanged" (click)="saveChanges()">
    {{ 'Intellitrailer.MyPage.Settings.Save' | translate : 'Save changes' }}
  </button>
</div>

