import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BookingModel, BookingService, NavigationService } from 'app-components';
import { AppSettingsService, AppTypeSettings } from 't4-app-integration';
import { LocationInfo, PageBase } from 't4core';
import { LocationMapComponent } from '../../../../lib/Components/location-map/location-map.component';

@Component({
  selector: 'app-Use4Free',
  templateUrl: './Use4Free.html'
})
export class Use4FreeComponent extends PageBase implements OnInit {

  constructor(el: ElementRef)
  {
    super(el);
  }

  async ngOnInit() {
  }

  public hinunterlassen() {
    window.open('https://r.turbo4.net/U4F');
  }
}
