<app-view-title Title="" [showBack]="true" [overlay]="true"></app-view-title>
<location-map #map *ngIf="rental?.PickupLocation" mode="Return" [locations]="locations" [selectedPickupLocation]="rental?.PickupLocation" [(selectedLocation)]="selectedLocation" (locationConfirmed)="selectLocation()"></location-map>

<div class="bottom-nav">
  <h2>{{'Intellitrailer_ReturnLocation_lblSelectReturnLocation' | translate: 'Select return location'}}</h2>
  <div *ngIf="selectedLocation">Reslängd: {{map.selectedDistance | number : '1.0-0'}}km</div>
  <div *ngIf="selectedLocation && map.selectedDuration > 1">Restid: {{map.selectedDurationHours | number : '1.0-0'}}:{{map.selectedDurationMinutes | number : '2.0-0'}}</div>
  <div *ngIf="selectedLocation && map.selectedDuration < 1">Restid: {{map.selectedDurationMinutes | number : '1.0-0'}} minuter</div>
</div>

