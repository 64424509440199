<app-view-title [showHeader]="false" [isPrimaryColor]="true"></app-view-title>
<div class="view-content-full centered-view">
  <div class="row mb-3">
    <div class="col-12">
      <ng-container *ngIf="loading">
        <app-loading-screen title="{{ 'Intellitrailer.Rental.Start.StartingRental' | translate : 'Starting rental' }}" description="{{ 'Intellitrailer.Rental.Start.StartingRental2' | translate : 'This will just take a moment' }}"></app-loading-screen>
      </ng-container>
      <ng-container *ngIf="!loading">
        <h2>{{ 'Intellitrailer.Rental.Start.Failed' | translate : 'Could not start rental' }}</h2>
        <p>Error code: {{ errorCode }}</p>
      </ng-container>
    </div>
  </div>
</div>

