<app-view-title [isPrimaryColor]="true"></app-view-title>
<div class="view-content centered-view">
  <app-information-screen class="horizontal-vertical-center font-secondary-color"
                          title="{{ 'Intellitrailer.Rental.ReturnInfo.Title' | translate : 'Return' }}"
                          primaryContent="{{ 'Intellitrailer.Rental.ReturnInfo.Message' | translate : 'Park the trailer at the designated area at the location.' }}"
                          secondaryContent="{{ 'Intellitrailer.Rental.ReturnInfo.Message2' | translate : 'Make sure that you have room to go over the trailer and take photos during the return process.' }}">
  </app-information-screen>

</div>
<div class="view-actions">
  <button mat-raised-button color="accent" (click)="next()">{{ 'Intellitrailer.Navigation.Next' | translate : 'Next' }}</button>
</div>

