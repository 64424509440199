<style>
  * {
    --mat-mdc-form-field-label-offset-x: 15px !important;
  }

</style>
<mat-form-field class="phone-number" appearance="outline">
  <mat-label>
    &nbsp;&nbsp;&nbsp;{{ 'turbo.core.RentalDialog.txtCellular' | translate : 'Mobile phone'}}
  </mat-label>
    <input autocomplete="off"
           type="tel"
           matInput
           placeholder="{{ country.phonePlaceholder }}"
           [formControl]="control"
           (keydown.space)="$event.preventDefault();"
           (change)="onChange($event.target.value)"
           style="color: black; margin-left: 15px;">
      <mat-select matPrefix style="margin-left: 10px; padding-right: 10px; border-right: 1px solid rgba(0, 0, 0, 0.38);"class="country-code-select" [(value)]="country" (selectionChange)="changePrefix()">
        <mat-select-trigger>
          <img width="32" height="32" src="{{ country.flagPath }}">
        </mat-select-trigger>
        <mat-option *ngFor="let c of countries" [value]="c">
          <img width="32" height="32" style="margin-right: 10px;" src="{{ c.flagPath }}">
          {{ 'Intellitrailer.MyPage.ValidateAccount.' + c.fullName | translate : c.fullName }} ({{ c.phonePrefix }})
        </mat-option>
      </mat-select>
    <mat-error *ngIf="control.hasError('required')">
      {{ errors?.required }}
    </mat-error>
    <mat-error *ngIf="control.hasError('minLength')">
      {{ errors?.minLength }}
    </mat-error>
    <mat-error *ngIf="control.hasError('pattern')">
      {{ errors?.pattern }}
    </mat-error>
  </mat-form-field>
