import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ComponentBase, RentalObjectInfo, ObjectTypeInfo } from 't4core';


@Component({
  selector: 'app-information-screen',
  templateUrl: './information-screen.component.html',
  styleUrls: ['./information-screen.component.css']
})
export class InformationScreenComponent extends ComponentBase implements OnInit {

  @Input() public image: string;
  @Input() public title: string;
  @Input() public primaryContent: string;
  @Input() public secondaryContent: string;
  @Output() public viewLoaded = new EventEmitter<string>();

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.viewLoaded.emit('Done');
    }, 1000);
  }
}
