<app-view-title Title="{{ 'Intellitrailer.Rental.LockTrailer.Headline' | translate : 'Lock the trailer' }}"></app-view-title>
<div class="view-content centered-view">
  <app-information-screen image="/assets/images/photos/lock_mounted.jpg">
    {{ 'Intellitrailer.Rental.UnlockCheck.UnlockOK' | translate : 'Did the lock unlock?' }}
  </app-information-screen>
</div>
<div class="view-actions">
  <button mat-raised-button (click)="next()">{{ 'Intellitrailer.Rental.UnlockCheck.UnlockOKYes' | translate : 'Yes' }}</button>
  <button mat-raised-button (click)="runAlternatives()">{{ 'Intellitrailer.Rental.UnlockCheck.UnlockOKNo' | translate : 'No' }}</button>
</div>

