import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { DialogBase } from 't4core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'pin-code-dialog',
  templateUrl: './pin-code-dialog.html',
  encapsulation: ViewEncapsulation.None
})
export class PinCodeDialog extends DialogBase implements OnInit
{
  public verificationCode0: string = "";
  public verificationCode1: string = "";
  public verificationCode2: string = "";
  public verificationCode3: string = "";

  @ViewChild('vf0') vf0: ElementRef;
  @ViewChild('vf1') vf1: ElementRef;
  @ViewChild('vf2') vf2: ElementRef;
  @ViewChild('vf3') vf3: ElementRef;

  constructor(public dialog: MatDialogRef<PinCodeDialog>) {
    super("PinCodeDialog");
  }

  public async ngOnInit() {
    setTimeout(() => this.vf0.nativeElement.focus(), 500);
  }


  public focusNext(field: number, event) {
    if (field === 3) this.sendVerificationCode();
    if (event.key == "Backspace") {
        if (field > 0) this["vf" + (field - 1)].nativeElement.focus();
    }
    else {
        if (field < 3) this["vf" + (field + 1)].nativeElement.focus();
    }
  }

  private sendVerificationCode(): void {
    let verificationCode = this.verificationCode0 + this.verificationCode1 + this.verificationCode2 + this.verificationCode3;

    this.dialog.close(verificationCode);
  }
}
