import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ComponentBase, RentalObjectInfo, ObjectTypeInfo } from 't4core';


@Component({
  selector: 'app-card',
  templateUrl: './app-card.html',
  styleUrls: ['./app-card.css']
})
export class AppCardComponent extends ComponentBase implements OnInit {
  @Input() public centered: boolean = false;
  @Input() public fullscreen: boolean = false; 

  constructor() {
    super();
  }

  ngOnInit(): void {
  }
}
