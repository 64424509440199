<h1 mat-dialog-title *ngIf="location">{{ location.Name }}</h1>
<div *ngIf="loader" class="componentLoader">
  <mat-progress-spinner diameter="25" mode="indeterminate"></mat-progress-spinner>
</div>
<div *ngIf="location && !loader" mat-dialog-content>
  <div>
    <h6 style="margin-bottom: 5px">{{'TO.Customers.Table.Address' | translate: 'Address' }}</h6>
    <div>{{ location.Address }}</div>
    <div>{{ location.ZipCode }}</div>
    <div>{{ location.City }}</div>
    <div>{{location.Phone}}</div>

    <h6 style="margin-top: 10px; margin-bottom: 5px">{{ 'Intellitrailer.Component.LocationInformation.OpenHours' | translate : 'Open hours' }}</h6>
    <div>{{location.OpenHours}}</div>
  </div>
</div>
<div mat-dialog-actions *ngIf="true">
  <button mat-button mat-raised-button [mat-dialog-close]="true">OK</button>
</div>

