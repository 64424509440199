import { Component, ElementRef, OnInit } from '@angular/core';
import { PageBase, RentalInfo } from 't4core';
import { LayoutService } from 'app-components';
import { NavigationService } from 'app-components';
import { ActiveRentalService } from 'app-components';
import { AppSettingsService } from 't4-app-integration';

@Component({
  selector: 'app-booking-updated',
  templateUrl: './booking-updated.component.html',
  styleUrls: ['./booking-updated.component.css']
})

export class BookingUpdatedComponent extends PageBase implements OnInit {
  public booking: RentalInfo;
  public theme: string;
  constructor(el: ElementRef, private aviationService: NavigationService, private rentalService: ActiveRentalService, private layoutService: LayoutService, public appSettings: AppSettingsService) {
    super(el);
  }

  async ngOnInit(): Promise<void> {
    this.booking = await this.rentalService.getBooking();
    this.theme = this.appSettings.settings.theme || 'Default';
  }

  public next() {
    this.aviationService.executeCommand("Next");
  }
}
