<h1 mat-dialog-title *ngIf="object">{{ object.Title }}</h1>
<div *ngIf="loader" class="componentLoader">
  <mat-progress-spinner diameter="25" mode="indeterminate"></mat-progress-spinner>
</div>
<div *ngIf="object && !loader" mat-dialog-content>
  <div class="container" style="background-color: transparent; border: 0">
    <div class="rounded-corners">
      <img src="{{object.Image}}" />
    </div>
    <div>
      <h6 style="margin-bottom: 5px">{{ 'Turbo.RentalObject.lblInterior' | translate:'Interior measurments' }}</h6>
      <div>
        <span class="info-title">{{ 'Turbo.RentalObject.lblLength' | translate:'Length' }}:</span>
        <span class="info-data">{{ object.Type.Length }}</span>
      </div>

      <div>
        <span class="info-title">{{ 'Turbo.RentalObject.lblWidth' | translate:'Width' }}:</span>
        <span class="info-data">{{ object.Type.Width }}</span>
      </div>

      <div>
        <span class="info-title">{{ 'Turbo.RentalObject.lblHeight' | translate:'Height' }}:</span>
        <span class="info-data">{{ object.Type.Height }}</span>
      </div>

      <h6 style="margin-top: 10px; margin-bottom: 5px">{{ 'Turbo.RentalObject.lblWeights' | translate:'Weights' }}</h6>
      <div>
        <span class="info-title">{{ 'Turbo.RentalObject.lblTotalWeight' | translate:'Total' }}:</span>
        <span class="info-data">{{ object.Type.TotalWeight }}</span>
      </div>

      <div>
        <span class="info-title">{{ 'Turbo.RentalObject.lblMaxLoad' | translate:'Max load' }}:</span>
        <span class="info-data">{{ object.Type.MaxLoad }}</span>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions *ngIf="true">
  <button mat-button mat-raised-button [mat-dialog-close]="true">OK</button>
</div>

