import { Component, OnInit, ElementRef } from '@angular/core';
import { PageBase } from 't4core';
import { AppIntegrationService } from 't4-app-integration';

@Component({
  selector: 'app-btPermissionRequired',
  templateUrl: './btPermissionRequired.html'
})
export class BTPermissionRequiredComponent extends PageBase implements OnInit {

  public permissionAsked: boolean = false;

  constructor(el: ElementRef, private appService: AppIntegrationService) {
    super(el);
  }

  ngOnInit(): void {
  }

  public askForPermission() {
    this.appService.ensureBluetooth();
    this.permissionAsked = true;
  }

  public openSettings() {
    this.appService.openPermissions();
  }
}
