import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ComponentBase, RentalObjectInfo, ObjectTypeInfo } from 't4core';


@Component({
  selector: 'app-h1',
  templateUrl: './app-h1.html',
  styleUrls: ['./app-h1.css']
})
export class AppH1Component extends ComponentBase implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }
}
