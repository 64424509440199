import { Component, ElementRef, OnInit } from '@angular/core';
import { PageBase, RentalInfo } from 't4core';
import { BookingModel, BookingService, NavigationService } from 'app-components';


@Component({
  selector: 'ow-type-selector', 
  templateUrl: './select-model.html',
  styleUrls: [ './select-model.css']
})
export class OWModelSelectorComponent extends PageBase implements OnInit {
  private bookingState: BookingModel;
  public rental: RentalInfo;

  constructor(private navigator: NavigationService, el: ElementRef, private rentalService: BookingService) {
    super(el);

    this.bookingState = this.rentalService.getBookingState();
    this.rental = this.bookingState.booking;
  }

  public async ngOnInit() {

  }

  public select(id: number) {
    this.bookingState.booking.PrimaryObjectTypeId = id;
    this.rentalService.saveState(this.bookingState);

    this.navigator.executeCommand("Next");
  }
}
