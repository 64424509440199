import { Component, ElementRef, OnInit } from '@angular/core';
import { PageBase, PickupResultCode } from 't4core';
import { ActivatedRoute, } from '@angular/router';
import { NavigationService } from 'app-components';
import { ActiveRentalService } from 'app-components';
import { LayoutService } from 'app-components';

@Component({
  selector: 'app-start-rental',
  templateUrl: './start-rental.html',
})
export class StartRentalComponent extends PageBase implements OnInit {
  public loading: boolean = true;
  public errorCode: string = "";


  constructor(private navigationService: NavigationService, private rentalService: ActiveRentalService, el: ElementRef, private route: ActivatedRoute,  private layoutService: LayoutService) {
    super(el);

    // Validate state
    //if (!this.rentalService.booking || this.rentalService.booking.Id == 0) {
      // Display error and abort
    //}
  }

  async ngOnInit() {
    var discloseResult = await this.rentalService.startRental();

    // Successful?
    if (discloseResult == PickupResultCode.SuccessWithoutPayment || discloseResult == PickupResultCode.SuccessWithPayment) {
      this.navigationService.executeCommand("Started");
    }

    // Payment failed
    else if (discloseResult == PickupResultCode.PaymentActionRequired || discloseResult == PickupResultCode.PaymentFailed) {
      let booking = await this.rentalService.getBooking();
      this.Api.get<boolean>("/Rental/SetRentalEvent", { rentalId: booking.Id, content: "Start failed: PaymentActionRequired" });
      this.navigationService.executeCommand("PaymentFailed", { rentalId: booking.Id });
    }

    // Something else is wrong. Display error and abort
    else {
      this.errorCode = discloseResult.toString();
      let booking = await this.rentalService.getBooking();
      this.Api.get<boolean>("/Rental/SetRentalEvent", { rentalId: booking.Id, content: "Start failed: " + this.errorCode});
      this.loading = false;
    }
  }
}
