import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, Inject } from '@angular/core';
import { DialogBase } from 't4core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'idle-dialog',
  templateUrl: './idle-dialog.html',
  styleUrls: ['./idle-dialog.css']
})
export class IdleDialog extends DialogBase implements OnInit {

  timeLeft: number = 60;
  interval: any;

  constructor(public dialog: MatDialogRef<IdleDialog>, @Inject(MAT_DIALOG_DATA) public data: { time: number }) {
    super("IdleDialog");

    this.timeLeft = this.data.time;
  }

  public async ngOnInit() {
    this.startTimer();
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.stopTimer();
        this.restartSession();
      }
    }, 1000);
  }

  stopTimer() {
    clearInterval(this.interval);
  }

  public restartSession() {
    this.stopTimer();
    this.dialog.close(false);
  }

  public clickImHere() {
    this.stopTimer();
    this.dialog.close(true)
  }

}
