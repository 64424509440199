<app-view-title [isPrimaryColor]="true" Title="{{ 'Intellitrailer.Rental.NFCNoLock.Headline' | translate : 'Scan the trailer'}}"></app-view-title>
<view-content [centered]="true" [fullscreen]="true">
  <app-h1>{{ 'Intellitrailer.Rental.NFCNoLock.lblYourTrailer' | translate : 'Your trailer'}}: {{ booking?.RentalObject?.Title }}</app-h1>
  <!-- Searching -->
  <app-card *ngIf="isWaiting">
    <h2>{{ 'Intellitrailer.Rental.NFCNoLock.headGuide_a' | translate: 'Scan the trailer with your phone' }}</h2>
    <img src="/Images/lockless.png" />
    <p>{{ 'Intellitrailer.Rental.NFCNoLock.msgGuide_b' | translate : 'This trailer is equipped with a system to identify it and to control the tracking mechanism.' }}</p>
    <p>{{ 'Intellitrailer.Rental.NFCNoLock.msgGuide_c' | translate : 'Please locate the corresponding area to scan on the front of the trailer. And click the button below.' }}</p>
  </app-card>

  <!-- Searching -->
  <app-card *ngIf="isSearching">
    <div class="spinner-container" *ngIf="count < 30">
      <mat-progress-spinner diameter="75" class="spinner" mode="determinate" value="{{count * (3 + 1.0/3.0)}}">
      </mat-progress-spinner>
      <h1 style="font-size: 24px">{{ 30 - count }}</h1>
    </div>
    <h2>{{ 'Intellitrailer.Rental.NFCNoLock.SearchingNFC4' | translate : 'Searching for trailer' }}...</h2>
    
    <ng-container *ngIf="device == 'iOS'">
      <p>{{ 'Intellitrailer.Rental.NFCNoLock.UnlockingInfoNFC_ios' | translate: 'Scan the trailer using the top of your device. You might need to move it around a bit.' }}</p>
      <img src="/Images/lockless_ios.png" />
    </ng-container>
    <ng-container *ngIf="device == 'Android'">
      <p>{{ 'Intellitrailer.Rental.NFCNoLock.UnlockingInfoNFC_android' | translate: 'Place the back of your phone on the correct area of the trailer. You might need to move it around a bit.' }}</p>
      <img src="/Images/lockless_android.png" />
    </ng-container>
    <ng-container *ngIf="device != 'iOS' && device != 'Android'">
      <p>{{ 'Intellitrailer.Rental.NFCNoLock.UnlockingInfoNFC_generic' | translate: 'Scan the trailer with your device. You might need to move it around a bit.' }}</p>
      <img src="/Images/lockless.png" />
    </ng-container>
  </app-card>

  <!-- Confirmation-->
  <app-information-screen class="horizontal-vertical-center font-secondary-color" *ngIf="hasCompleted"
                          title="{{ 'Intellitrailer.Rental.NFCLock.LockOpenTitle3' | translate : 'Thank you!' }}"
                          primaryContent="{{ 'Intellitrailer.Rental.NFCLock.LockOpenInstructions3' | translate : 'The trailer was scanned successfully' }}">
  </app-information-screen>

  <!-- No tag found -->
  <app-card *ngIf="hasFailed && failReason == 2">
    <h2>{{ 'Intellitrailer.Rental.NFCNoLock.TitleLockNotOpenNFC4' | translate: 'Failed to find the trailer' }}</h2>
    <p>{{ 'Intellitrailer.Rental.NFCNoLock.NotFoundInfo4' | translate : 'Make sure that you are holding your phone correctly, with the reader towards the trailer.' }}</p>
    <ng-container *ngIf="device == 'iOS'">
      <img src="/Images/lockless_ios.png" />
    </ng-container>
    <ng-container *ngIf="device == 'Android'">
      <img src="/Images/lockless_android.png" />
    </ng-container>
  </app-card>

  <!-- Wrong tag found -->
  <app-card *ngIf="hasFailed && failReason == 1"> 
    <h2>{{ 'Intellitrailer.Rental.NFCNoLock.headWrongTag4' | translate : 'Wrong trailer identified!' }}</h2>
    <p>{{ 'Intellitrailer.Rental.NFCNoLock.msgWrongTag4' | translate : 'The trailer you have scanned was not the expected one. Please verify that you are at the correct trailer and try again.' }}</p>
  </app-card>
</view-content>
<view-actions>
  <button mat-raised-button color="accent" *ngIf="isWaiting" (click)="beginSearch()">{{ 'Intellitrailer.Rental.NFCNoLock.cmdStart' | translate : 'Start scanning' }}</button>
  <button mat-raised-button color="accent" *ngIf="hasFailed" (click)="retry()">{{ 'Intellitrailer.Rental.NFCNoLock.Retry' | translate : 'Retry' }}</button>
  <button mat-raised-button color="accent" *ngIf="hasCompleted" (click)="next()">{{ 'Intellitrailer.Navigation.Next' | translate : 'Next' }}</button>
</view-actions>

