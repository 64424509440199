export class SecurityCheckPoint {
  public Key: string;
  public requireComment: Boolean;

  constructor(key: string, requireComment?: Boolean) { this.Key = key; this.requireComment = requireComment; }

  private _ok: boolean;
  public get Ok(): boolean { return this._ok; }
  public set Ok(val: boolean) {
    this._ok = val;
    if (this._ok) this.Failed = false;
  }

  private _failed: boolean;
  public get Failed(): boolean { return this._failed; }
  public set Failed(val: boolean) {
    this._failed = val;
    if (this._failed) this.Ok = false;
  }
}
