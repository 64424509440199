import { EventEmitter, Injectable } from '@angular/core';
import * as moment from 'moment';
import * as uuid from 'uuid';
import { ApiService, AuthService, CustomerInfo, LocalStorage, SettingsService, TranslationService } from 't4core';
import { Coordinates } from '../../models/coordinates';
import { LocationInfo } from 't4core';
import { App, AppType, AppTypeSettings, Web } from '../../models/app-type-settings';
import { AppSettings } from '../../models/app-settings';


@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  public contextId: string = "";
  public appType: AppType = "app";
  public overrideRoutes: boolean;
  public privateKey: string = "";
  public appVersion: string = "";
  public appScheme: string = null;
  public clientVersion: string;
  public originalHref: string = null;
  public localLocationId: number = 0;
  public params: any = {};
  public language: string = "";
  public country: string = "";

  public customerSupportLanguage: string = "";

  static getComponents(components: any) {
    this.components = components;
  }

  public static components: { [key: string]: any } = {}

  //Previously restarted app in this runtime
  public previouslyRestarted: string;

  //Saved data
  public userLoggedIn: boolean = false;
  public userId: string;
  public customer: CustomerInfo;
  public userDetails: any;

  //General
  public settings: AppSettings = { };

  private isSettingsLoaded: boolean = false;
  private isAuthenticated: boolean = false;
  //Custom members in the menu  

  constructor(
    private Api: ApiService,
    private auth: AuthService,
    private localStorage: LocalStorage,
    private translate: TranslationService,
    private authService: AuthService,
    private settingsService: SettingsService
  ) {
    // Listen for user changes
    this.userChanged.subscribe(() => {
      this.userLoggedIn = false;
      this.userDetails = null;
      this.customer = null;
      this.userId = null;
    });

    this.unpack();
  }

  public clear() {
    this.localStorage.remove("viewState");
  }

  public pack() {
    var data: any = {};
    data.contextId = this.contextId;
    data.key       = this.privateKey;
    data.scheme    = this.appScheme;
    data.type      = this.appType;
    data.version   = this.appVersion;
    data.localId   = this.localLocationId;


    var payload = btoa(JSON.stringify(data));

    this.localStorage.set("viewState", payload);
  }

  public unpack() {
    if (!this.localStorage.get("viewState")) {
      if (this.localStorage.get("ContextId")) this.contextId = this.localStorage.get("ContextId");
      if (this.localStorage.get("PrivateKey")) this.privateKey = this.localStorage.get("PrivateKey");
      if (this.localStorage.get("Scheme")) this.appScheme = this.localStorage.get("Scheme");
      if (this.localStorage.get("Version")) this.appVersion = this.localStorage.get("Version");
      if (this.localStorage.get('AppType')) this.appType = this.localStorage.get("AppType") as AppType ?? 'app';
      if (this.localStorage.get("OverrideRoutes")) this.overrideRoutes = this.localStorage.get("OverrideRoutes") === 'true' ? true : false;
      if (this.localStorage.get("Country")) this.country = this.localStorage.get("Country");
      if (this.localStorage.get("Language")) this.language = this.localStorage.get("Language");
      return;
    }

    try {
      var payload = atob(this.localStorage.get("viewState"));
      if (!payload) return;
    } catch (ex) {
      return;
    }

    var data = JSON.parse(payload);
    if (!data) return;

    this.contextId = data.contextId;
    this.privateKey = data.key;
    this.appScheme = data.scheme;
    this.appType = data.type;
    this.appVersion = data.version;
    this.localLocationId = data.localId;

    //After we have únpacked everything, we set the language.
    //And if we dont have a language, we get the brower language.
    if (!this.language)
      this.language = this.getLanguage();
    this.setLanguage(this.language);
  }

  public initParams() {
    this.getParams()
    if (this.getParam("clear") == "true") {
      this.clear();
      window.location.href = "https://" + window.location.host;
    } else {
      if (this.getParam("appVersion")) this.appVersion = this.getParam("appVersion");
      if (this.getParam("appScheme")) this.appScheme = this.getParam("appScheme");
      if (this.getParam("externalId")) this.contextId = this.getParam("externalId");
      if (this.getParam("privateKey")) this.privateKey = this.getParam("privateKey");
      if (this.getParam("lId")) this.localLocationId = parseInt(this.getParam("lId"));

      if(this.getParam("appType"))
        this.appType = this.getParam("appType") as AppType;

      this.pack();
    }

    this.originalHref = window.location.href;
  }

  public getParam(param: string): string | null {
    var queryParams = this.getParams();
    console.log("query params", queryParams);
    console.log("windows params", window["params"]);
    if (window["params"] && window["params"][param]) return window["params"][param];

    return this.params[param];
  }

  private getParams() {
    // Gets all the params as a key/pair object.
    var queryParams = new URLSearchParams(window.location.search);
    //Loop through the queryParams, where it find all the params from our QueryString and adds it to our variable params.
    queryParams.forEach((value: string, key: string) => {
        this.params[key] = value;
    });
  }

  public getLanguage() {
    if (localStorage.getItem('Language')) 
      return localStorage.getItem('Language');
    else 
      return AppSettingsService.GetBrowserLanguage();
  }

  public setLanguage(language: string) {
    localStorage.setItem('Language', language);
    this.translate.setPreferedLanguage(language)
  }


  public async authenticateContext() {
    var userId = this.getUserId();
    await this.auth.Authenticate("", this.contextId, this.privateKey, userId);
    await this.getSettings();
    console.log("auth context - app type", this.appType);
    console.log("auth context - user Id", userId);
    console.log("auth context - settings", this.settings);
    this.settings.typeSettings = this.setTypeSettings(this.appType);

    // If user datat is present, validate it
    if (userId) {
      await this.validateUser();
      userId = this.getUserId();

      // Was user invalid?
      if (!userId) {
        await this.auth.refreshSettings(null);
      }
    }

    this.isAuthenticated = true;
  }

  public async waitForAuthentication(): Promise<any> {
    while (!this.isAuthenticated) await new Promise(resolve => setTimeout(resolve, 100));
    return true;
  }

  public async getUser(): Promise<any> {
    this.userId = this.getUserId();
    this.userLoggedIn = this.userId !== null;

    if (this.userId) {
      if (!this.customer) this.customer = await this.Api.get<CustomerInfo>("Rental/FindCustomerByUser");
      if (!this.userDetails) this.userDetails = await this.Api.get<any>("Settings/getUserDetails");
    }

    return { customer: this.customer, userDetails: this.userDetails, userLoggedIn: this.userLoggedIn };
  }

  //Methods for preloading
  public async getSettings(): Promise<AppSettings> {
    if (this.isSettingsLoaded) return this.settings;

    var settings = await this.Api.get<any>("Settings/GetSettingAsString", { key: "IntellitrailerAppSettings" });
    var parsedSettings = JSON.parse(settings);

    if (parsedSettings) {
      this.settings = parsedSettings;

      // Override theme with parameter if present
      if (this.getParam("theme"))
        this.settings.theme = this.getParam("theme");

      //---Parse settings---

      //Parse custom navigation steps
      if (this.settings.NavigationSteps) {
        parsedSettings.StringValue.NavigationSteps = JSON.parse(parsedSettings.StringValue.NavigationSteps);

        for (let step of parsedSettings.StringValue.NavigationSteps) {
          var instance = AppSettingsService.components[step.Component];
          step.Component = instance;
        }
      }

      // Import css
      if (this.settings.theme) {
        document.getElementById('stylesheet').setAttribute('href', '/Styles/' + this.settings.theme + "/Style.css?" + this.clientVersion);
      }

      if (!this.settings.theme) this.settings.theme = "BSG;"
    }
    this.isSettingsLoaded = true;
    return this.settings;
  }

  public setAppRestartTime() {
    localStorage.setItem("appRestartTime", "" + moment.now());
  }

  public getAppRestartTime(): string {
    if (localStorage.getItem("appRestartTime")) {
      return localStorage.getItem("appRestartTime");
    }
    return null;
  }

  public meetsMinimumAppVersion(version: number): boolean {
    if (this.appVersion && this.appVersion != "null") {
      return +this.appVersion >= version;
    }
    return true;
  }

  public static GetBrowserLanguage(): string {
    var availableLanguages = ['sv', 'en', 'da', 'no', 'de', 'pl'];
    var browserLang = navigator.language;
    if (browserLang.indexOf("-") > 0) browserLang = browserLang.substring(0, browserLang.indexOf("-"));
    if (browserLang == "nb") browserLang = 'no';
    if (browserLang == "nn") browserLang = 'no';

    if (availableLanguages.indexOf(browserLang) >= 0) return browserLang;

    return 'en';
  }

  public static getDeviceType(): string {
    var win: any = window;
    var userAgent = navigator.userAgent || navigator.vendor || win.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !win.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  public getUserId() {
    console.log("get UserId - local storage", window.localStorage);
    console.log("get UserId -  userid", localStorage.getItem('customerUID'));
    if (localStorage.getItem('customerUID')) {
      //this.translate.setPreferedLanguage(null);
      return localStorage.getItem('customerUID');
    }
    else {
      return null;
    }
  }

  public async dumpUser() {
    this.clearUser();
    this.userChanged.emit(null);
  }
  public clearUser() {
    this.setUser(null);
    this.authService.Logout();
  }

  public async setUser(user: string) {
    console.log("set user - user", user);
    if (user) {
      if (this.settings.typeSettings.isStoringCustomer) localStorage.setItem('customerUID', user);
      this.settingsService.customerUserId = user;
    } else {
      localStorage.removeItem('customerUID');
      this.settingsService.customerUserId = "";
    }

    this.userChanged.emit(user);
  }

  public async validateUser() {
    var usr = this.getUserId();
    if (!usr) return;

    var ret = await this.Api.get<boolean>("/Rental/ValidateUser", { userName: usr });
    if (!ret) {
      localStorage.removeItem('customerUID');
      this.settingsService.customerUserId = "";
      this.userChanged.emit(null);
    } else {
      this.setUser(usr);
    }
  }

  public userChanged: EventEmitter<string> = new EventEmitter<string>();

  public async getClientVersion(onload: boolean = false): Promise<[string, boolean]> {

    var id = uuid.v4();

    if (onload) {
      var repsonse = await fetch('/Turbo.Client.Version.txt?v=' + id);
      var data = await repsonse.text();
      if (window["appVersion"] && window["appVersion"] !== data.trim()) {
        window.location.href = this.originalHref + '&q=' + id;
      } else {
        this.clientVersion = data;
      }
    }
    else {
      var latestVersion: string;
      var isLatestVersion: boolean;

      var repsonse = await fetch('/Turbo.Client.Version.txt?v=' + id);
      var data = await repsonse.text();
      latestVersion = data;

      if (latestVersion != this.clientVersion) {
        isLatestVersion = false;
      }
      else {
        isLatestVersion = true;
      }
    }
    return [this.clientVersion, isLatestVersion];
  }

  private setTypeSettings(type: AppType): AppTypeSettings {
    switch (type) {
      case 'web':
        return Web;
      case 'app':
      default:
        return App;
    }
  }

  private fallbackUsed: boolean = false;

  //Get called upon when the coordinates updates in App-integration service.
  public async cooridnatesUpdated(lastknownlocation: Coordinates) {
    //If the country is not set, or the fallback have been used, we are updating the country.
    if (this.fallbackUsed || !this.country) {
      //Return the 3 closest locations, regardless of the distance.
      const closestLocations = await this.Api.get<LocationInfo[]>("/Location/FindClosestLocations", { latitude: lastknownlocation.Latitude, longitude: lastknownlocation.Longitude, maxDistance: 0 });
      if (closestLocations && closestLocations.length > 0) {
        //Set the country of the closet location found.
        this.setAnticipatedUserCountry(closestLocations[0].Country);
        this.fallbackUsed = false;
      }
    }
  }

  public getAnticipatedUserCountry(): Country {
    const selectedCountry = this.country ? this.country.toUpperCase() : null;

    // Check if the provided country is valid
    if (selectedCountry && this.isValidCountry(selectedCountry)) {
      return selectedCountry as Country;
    } else {

      var languageLocalStorage = localStorage.getItem('Language');
      // Check if the language is valid and return the country based on the language
      if (languageLocalStorage) {
        const countryForLanguage = this.getCountryByLanguage(languageLocalStorage);
        if (countryForLanguage) {
          return countryForLanguage;
        }
      }
    }
    // Fallback to Sweden if no valid country or language is found
    return Country.SWEDEN;
  }

  public setAnticipatedUserCountry(value: string) {

    var valueUpper = value.toUpperCase();

    if (this.isValidCountry(valueUpper)) {
      this.country = value;
      localStorage.setItem('Country', value);
    } else {
      this.fallbackUsed = true;
      this.country = Country.SWEDEN;
      localStorage.setItem('Country', Country.SWEDEN);
    }
  }

  private isValidCountry(value: string): boolean {
    return Object.keys(Country).includes(value);
  }

  private getCountryByLanguage(language: string): Country | null {
    switch (language.toLowerCase()) {
      case 'sv':
        return Country.SWEDEN;
      case 'da':
        return Country.DENMARK;
      case 'no':
        return Country.NORWAY;
      case 'de':
        return Country.GERMANY;
      case 'is':
        return Country.ICELAND;
      case 'pl':
        return Country.POLAND;
      default:
        return null; 
    }
  }

}

enum Country {
  SWEDEN = 'Sweden',
  DENMARK = 'Denmark',
  NORWAY = 'Norway',
  GERMANY = 'Germany',
  ICELAND = 'Iceland',
  POLAND = 'Poland'
}

