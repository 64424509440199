import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ComponentBase, RentalObjectInfo, ObjectTypeInfo } from 't4core';


@Component({
  selector: 'view-content',
  templateUrl: './view-content.html',
  styleUrls: ['./view-content.css']
})
export class ViewContentComponent extends ComponentBase implements OnInit {
  @Input() public centered: boolean = false;
  @Input() public fullscreen: boolean = false; 

  constructor() {
    super();
  }

  ngOnInit(): void {
  }
}
