import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { DialogBase, LocationInfo } from 't4core';

@Component({
  selector: 'app-find-location',
  templateUrl: './find-location.component.html',
  styleUrls: ['./find-location.component.css']
})
export class FindLocationComponent extends DialogBase implements OnInit {

    public nearbyLocations: LocationInfo[] = [];
    public loader: boolean = false;
    public mapSearchFocus: boolean = false;
    @ViewChild('txtMapSearch', { static: false }) gmapSearchBox: ElementRef;
    public txtMapSearch: UntypedFormControl = new UntypedFormControl();
    private searchBox: google.maps.places.Autocomplete;

    constructor(
        public dialogRef: MatDialogRef<FindLocationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        super("FindLocationComponent");
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        this.searchBox = new google.maps.places.Autocomplete(this.gmapSearchBox.nativeElement, { types: ["(cities)"], componentRestrictions: { country: this.Settings.clientSettings.Country }, });
        this.gmapSearchBox.nativeElement.focus();
        this.searchBox.addListener("place_changed", () => {
            //get the place result
            let place: google.maps.places.PlaceResult = this.searchBox.getPlace();
            this.getLocations(place);
            this.gmapSearchBox.nativeElement.blur();
            this.mapSearchFocus = true;
        });
    }

    public async getLocations(place: google.maps.places.PlaceResult) {
        this.loader = true;
        this.nearbyLocations = await this.Api.get<LocationInfo[]>("Location/FindLocations", { latitude: place.geometry.location.lat(), longitude: place.geometry.location.lng(), maxDistance: 40 });
        this.loader = false;
    }

    public setPickupLocation(pickupLocation: LocationInfo) {
        //this.data.nearby = this.nearbyLocations;
        this.data.selected = pickupLocation;
    }

    public fixDistance(distance: number): string {
        if (distance < 1) {
            //return +distance.toFixed(2) * 100 + " m"
            return +distance.toFixed(2) + " km";
        }

        return +distance.toFixed(2) + " km";
    }
}
