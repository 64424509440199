<app-view-title Title="Välj släpmodell" [showBack]="true"></app-view-title>
<div class="view-content">
  <div class="row">
    <div class="col-xs-12 col-md-6 col-lg-3 ow-model-card">
      <div class="title-with-divider">
        <div class="title">XL</div>
      </div>
      <img src="/Styles/OneWay/Images/XL.png" />
      <div class="row">
        <div class="col-6">Tekniska data</div>
        <div class="col-6">Lastmått</div>
      </div>
      <div class="row">
        <div class="col-6">Totalvikt: 1000kg<br />Lastvikt: 350kg<br />Volym: 11m<sup>3</sup></div>
        <div class="col-6">Längd: 366cm<br />Bredd: 159cm<br />Höjd: 194cm</div>
      </div>
      <button mat-raised-button color="primary" (click)="select(423)">Välj</button>
    </div>

    <div class="col-xs-12 col-md-6 col-lg-3 ow-model-card">
      <div class="title-with-divider">
        <div class="title">Large</div>
      </div>
      <img src="/Styles/OneWay/Images/L.png" />
      <div class="row">
        <div class="col-6">Tekniska data</div>
        <div class="col-6">Lastmått</div> 
      </div>
      <div class="row">
        <div class="col-6">Totalvikt: 1000kg<br />Lastvikt: 430kg<br />Volym: 9m<sup>3</sup></div>
        <div class="col-6">Längd: 366cm<br />Bredd: 159cm<br />Höjd: 155cm</div>
      </div>
      <button mat-raised-button color="primary" (click)="select(340)">Välj</button>
    </div>

    <div class="col-xs-12 col-md-6 col-lg-3 ow-model-card">
      <div class="title-with-divider">
        <div class="title">Medium</div>
      </div>
      <img src="/Styles/OneWay/Images/M.png" />
      <div class="row">
        <div class="col-6">Tekniska data</div>
        <div class="col-6">Lastmått</div>
      </div>
      <div class="row">
        <div class="col-6">Totalvikt: 1000kg<br />Lastvikt: 510kg<br />Volym: 6,5m<sup>3</sup></div>
        <div class="col-6">Längd: 325cm<br />Bredd: 152cm<br />Höjd: 150cm</div>
      </div>
      <button mat-raised-button color="primary" (click)="select(422)">Välj</button>
    </div>

    <div class="col-xs-12 col-md-6 col-lg-3 ow-model-card">
      <div class="title-with-divider">
        <div class="title">Small</div>
      </div>
      <img src="/Styles/OneWay/Images/S.png" />
      <div class="row">
        <div class="col-6">Tekniska data</div>
        <div class="col-6">Lastmått</div>
      </div>
      <div class="row">
        <div class="col-6">Totalvikt: 1000kg<br />Lastvikt: 590kg<br /></div>
        <div class="col-6">Längd: 258cm<br />Bredd: 128cm<br />Höjd: 140cm</div>
      </div>
      <button mat-raised-button color="primary" (click)="select(421)">Välj</button>
    </div>
  </div>
 </div>
