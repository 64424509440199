import { Component, OnInit, ElementRef } from '@angular/core';
import { AppIntegrationService } from 't4-app-integration';
import { NavigationService } from 'app-components';
import { PageBase, RentalObjectInfo, RentalInfo, BookingErrorCode } from 't4core';
import { ActiveRentalService } from 'app-components';

@Component({
  selector: 'app-change-object',
  templateUrl: './change-object.component.html',
  styleUrls: ['./change-object.component.css']
})
export class ChangeObjectComponent extends PageBase implements OnInit {

    public booking: RentalInfo;

    public selectedObject: RentalObjectInfo;
    public availableObjects: RentalObjectInfo[];
    public availableObjectsOfSameType: RentalObjectInfo[] = [];

    constructor(el: ElementRef, private appSvc: AppIntegrationService, private aviationService: NavigationService, private rentalService: ActiveRentalService) {
        super(el);
      this.rentalService.getBooking().then(x => this.booking = x);
    }

    async ngOnInit(): Promise<void> {
        await this.loadAvailableObjects();
  }

    public async loadAvailableObjects() {
        this.availableObjects = await this.Api.get<RentalObjectInfo[]>("RentalObject/FindAvailableObjects", { locationId: this.booking.PickupLocation.Id, typeId: this.booking.PrimaryObjectTypeId, pickupTime: this.booking.PickupTime.toISOString(), returnTime: this.booking.ReturnTime.toISOString(), inSameCategory: true });

        for (var i = 0; i < this.availableObjects.length; i++) {
            if (this.availableObjects[i].Type.Id == this.booking.PrimaryObjectTypeId) {
                this.availableObjectsOfSameType.push(this.availableObjects[i]);
                this.availableObjects.splice(i, 1);
            }
        }
        console.log(this.availableObjectsOfSameType);
    }

    public selectObject(object: RentalObjectInfo) {
        this.selectedObject = object;

        this.booking.RentalObject = object;
        this.booking.ObjectId = object.Id;
    }

    public async next() {
        //var validation = await this.rentalService.changeRentalObject(this.selectedObject);
        //console.log(validation);
        //if (validation.AggregatedResult == BookingErrorCode.Valid) {
        //    await this.rentalService.setBooking(this.booking, true);
        //    this.aviationService.executeCommand("Change");
        //}
        //else {
        //    console.log("Error when changing trailer");
        //}
    }

}
