import { Component, OnInit, Output, EventEmitter, ElementRef } from '@angular/core';
import { ComponentBase, PageBase } from 't4core';
import { AppSettingsService } from 't4-app-integration';
import { NavigationService } from 'app-components';

interface Swipe {
    xPos: number;
    Time: number;
}

@Component({
  selector: 'app-ow-howto',
  templateUrl: './OneWayHowTo.component.html',
  styleUrls: ['./OneWayHowTo.component.css']
})
export class OneWayHowToComponent extends PageBase implements OnInit {
    @Output() showInfo: EventEmitter<boolean> = new EventEmitter();

    public numberOfSteps: number[] = [0, 0, 0, 0];
    public activeStep: number = 0;

    public swipePress: Swipe = { xPos: 0, Time: 0 };
  public swipeRelease: Swipe = { xPos: 0, Time: 0 };

  constructor(public appSettings: AppSettingsService, el: ElementRef, private navigation: NavigationService) {
    super(el);

    if (localStorage.getItem('newInfoClosed')) this.close();
  }

    ngOnInit(): void {
  }

  public close() {
    localStorage.setItem('newInfoClosed', 'true');
    this.navigation.executeCommand("Go");
    }

    public swipeStart(event: TouchEvent) {
        event.stopPropagation();
        this.swipePress.xPos = event.touches[0].clientX;
        this.swipePress.Time = event.timeStamp;
    }

    public swipeEnd(event: TouchEvent) {
        event.stopPropagation();
        this.swipeRelease.xPos = event.changedTouches[0].clientX;
        this.swipeRelease.Time = event.timeStamp;

        if (this.swipeRelease.xPos < (this.swipePress.xPos - 40) && this.activeStep < (this.numberOfSteps.length - 1)) this.activeStep++;
        else if (this.swipeRelease.xPos > (this.swipePress.xPos + 40) && this.activeStep > 0) this.activeStep--;
    }

    public preventClose() {
        event.stopPropagation();
    }

    public prevTab() {
        if (this.activeStep > 0) this.activeStep--;
    }

    public nextTab() {
        if (this.activeStep < (this.numberOfSteps.length - 1)) this.activeStep++;
    }
}
