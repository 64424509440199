<app-view-title Title="{{ 'Intellitrailer.Rental.ChangeObject.ChangeTrailerTitle' | translate : 'Change trailer' }}"></app-view-title>
<div class="view-content centered-view">
  <div *ngIf="availableObjects">
    <h2>{{ 'Intellitrailer.Rental.ChangeObject.AvailableTrailers' | translate : 'Available trailers' }}</h2>
    <p>{{ 'Intellitrailer.Rental.ChangeObject.ChangePrompt' | translate : 'Please change to another available trailer below.' }}</p>

    <h2 *ngIf="availableObjectsOfSameType && availableObjectsOfSameType.length > 0">{{ 'Intellitrailer.Rental.ChangeObject.SameTypeTitle' | translate : 'Same type as booking' }}</h2>
    <div *ngIf="availableObjectsOfSameType.length > 0" class="object-wrapper">
      <div class="object-container" *ngFor="let object of availableObjectsOfSameType" (click)="selectObject(object)">
        <app-booking-option [object]="object" [checked]="selectedObject && object.Id == selectedObject.Id"></app-booking-option>
      </div>
    </div>

    <h2 *ngIf="availableObjectsOfSameType && availableObjectsOfSameType.length > 0">{{ 'Intellitrailer.Rental.ChangeObject.OtherTypesTitle' | translate : 'Other types' }}</h2>
    <div *ngIf="availableObjects.length > 0" class="object-wrapper">
      <div class="object-container" *ngFor="let object of availableObjects" (click)="selectObject(object)">
        <app-booking-option [object]="object" [checked]="selectedObject && object.Id == selectedObject.Id"></app-booking-option>
      </div>
    </div>
  </div>
    <div *ngIf="!availableObjects">
      <h2>{{ 'Intellitrailer.Rental.ChangeObject.ContactSupport' | translate : 'Contact support' }}</h2>
      <p>{{ 'Intellitrailer.Rental.ChangeObject.CallPrompt' | translate : 'Please call the number below to get help with resolving this issue' }}</p>
      <p>0701234567</p>
    </div>

  </div>
<div class="view-actions">
  <button mat-raised-button [disabled]="!this.selectedObject" (click)="next()">{{ 'Intellitrailer.Rental.ChangeObject.UpdateBooking' | translate : 'Update booking' }}</button>
</div>

