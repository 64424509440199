<app-view-title Title="{{ 'Intellitrailer.Booking.DateAndTime.Availability' | translate : 'Availability' }}, {{booking.PickupLocation?.Name}}" [isSplitColor]="true"></app-view-title>
<div class="view-content">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6" style="margin-bottom: 15px;" [ngClass]="{ 'offset-md-3': pickupNow }">
        <div class="title-with-divider">
          <div class="title">{{ 'Intellitrailer.Booking.DateAndTime.ChoosePickupTime' | translate : 'Choose pickup time' }}</div>
        </div>
        <ng-container *ngIf="pickupNow">
          <div class="pre-selection">
            <div class="row">
              <div class="col-3 icon"> <mat-icon>info</mat-icon></div>
              <div class="col-6 selection">{{ 'Intellitrailer.Booking.DateAndTime.PickupNow' | translate : 'Pickup now' }}</div>
              <div class="col-3 action"><a (click)="toggleTab(1)" [routerLink]="">{{ 'Intellitrailer.Booking.DateAndTime.Change' | translate : 'Change' }}</a></div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="!pickupNow">
          <div class="datepicker-wrapper">
            <div class="datepicker-left-col">
              <span class="material-icons" (click)="setPickupDate(null, -1)" [ngClass]="isSameDay(this.pickupDate, this.today.toDate()) ? 'unselectable' : 'selectable'">keyboard_arrow_left</span>
            </div>
            <div class="datepicker-col text-center">
              <mat-form-field *ngIf="!pickupNow">
                <input #pickupInput class="input-center" matInput [matDatepicker]="pickup" [(ngModel)]="pickupDate" [min]="this.today.toDate()" (dateChange)="setPickupDate($event.value)" (focus)="pickup.open()">
                <mat-datepicker-toggle matSuffix [for]="pickup"></mat-datepicker-toggle>
                <mat-datepicker #pickup (closed)="pickupInput.blur()"></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="datepicker-right-col">
              <span class="material-icons" (click)="setPickupDate(null, 1)">
                keyboard_arrow_right
              </span>
            </div>
          </div>
          <div style="min-width: 100%">
            <timePicker #pickupTime
                        [booking]="booking"
                        [minTime]="minPickup"
                        [maxTime]="maxPickup"
                        [selectedTime]="_pickupTime"
                        [timeSlots]="pickupSlots"
                        (selectedTimeChange)="setPickupTime($event)"
                        steps="quarter"
                        [loader]="timePickerLoading"
                        [isClosed]="pickupClosed">
            </timePicker>
          </div>
          <ng-container *ngIf="gpsBasedBookingFee && theme != 'Tysse Utleie'">
            <ng-container *ngIf="appSettings.appType == 'app'">
              <div *ngIf="!atLocation && !within30m" style="text-align: center; padding: 10px 0; font-size: 0.85em;">{{ 'Intellitrailer.Booking.DateAndTime.errBookingFeeMultiple' | translate : 'Please note! Reservation in advance or at another location may add a booking fee.'}}</div>
              <div *ngIf="!atLocation && within30m" style="text-align: center; padding: 10px 0; font-size: 0.85em;">{{ 'Intellitrailer.Booking.DateAndTime.errBookingFeePhysical' | translate : 'Please note! Reservation at another location may add a booking fee.' }}</div>
              <div *ngIf="atLocation && !within30m" style="text-align: center; padding: 10px 0; font-size: 0.85em;">{{ 'Intellitrailer.Booking.DateAndTime.errBookingFeeTime' | translate : 'Please note! Reservation in advance may add a booking fee.'}}</div>
            </ng-container>
            <ng-container *ngIf="appSettings.appType != 'app'">
              <div *ngIf="!within30m" style="text-align: center; padding: 10px 0; font-size: 0.85em;">{{ 'Intellitrailer.Booking.DateAndTime.errBookingFeeTime' | translate : 'Please note! Reservation in advance may add a booking fee.'}}</div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <div class="col-12 col-md-6" [ngClass]="{ 'offset-md-3': pickupNow }">
        <div class="title-with-divider">
          <div class="title">
            {{
 'Intellitrailer.Booking.DateAndTime.ChooseReturnTime' | translate : 'Choose return time'
            }}
          </div>
        </div>

        <div class="datepicker-wrapper">
          <div class="datepicker-left-col">
            <span class="material-icons" (click)="setReturnDate(null, -1)" [ngClass]="isSameDay(this.returnDate, this.today.toDate()) ? 'unselectable' : 'selectable'">
              keyboard_arrow_left
            </span>
          </div>
          <div class="datepicker-col text-center">
            <mat-form-field>
              <input #returnInput class="input-center" matInput [matDatepicker]="return" [(ngModel)]="returnDate" [min]="minPickup.toDate()" (dateChange)="setReturnDate($event.value)" (focus)="return.open()">
              <mat-datepicker-toggle matSuffix [for]="return"></mat-datepicker-toggle>
              <mat-datepicker #return (closed)="returnInput.blur()"></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="datepicker-right-col">
            <span class="material-icons" (click)="setReturnDate(null, 1)">
              keyboard_arrow_right
            </span>
          </div>
        </div>
        <div style="min-width: 100%">
          <timePicker #returnTime
                      [booking]="booking"
                      [minTime]="minReturn"
                      [maxTime]="maxReturn"
                      [selectedTime]="_returnTime"
                      [timeSlots]="returnSlots"
                      (selectedTimeChange)="setReturnTime($event)"
                      steps="quarter"
                      [loader]="timePickerLoading"
                      (click)="scrolltoBottom()"
                      [isClosed]="returnClosed">
          </timePicker>
        </div>
      </div>
      <div style="margin-top: 20px; text-align: center; min-width: 100%;" *ngIf="this.Settings.clientSettings.Bookings.EnableRentFreeWhenClosed && !isSameDate()">
        <div>{{ 'Intellitrailer.Booking.DateAndTime.nochargedHours' | translate : 'No rent is charged outside the locations open hours' }}</div>
      </div>
    </div>
  </div>
  <div id="bottomDiv" style="height: 100px"></div>
</div>
<div class="view-actions">
  <button type="button" mat-raised-button (click)="next()" [disabled]="!isValid">{{ 'Intellitrailer.Navigation.Next' | translate : 'Next' }}</button>
</div>


