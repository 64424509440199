<div class="container selectorPanel">
  <div class="row">
    <div *ngFor="let category of categories.slice(0, 3); let i = index" class="col-4 category-column image option" [ngClass]="{'checked': categorySelections[i], 'unselectable-category': disableUnselectable && categoryAvailability && categoryAvailability[i]}" (click)="selectCategory(category)" style="background-image: url({{category.ImageUri}});">
      <div style="
    position: absolute;
    padding-bottom: 3px;
    bottom: 0px;
    left: 0;
    right: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: rgba(245, 245, 245, 0.9);
">
        <span *ngIf="category.Name.indexOf('Turbo.') == -1">{{ category.Name }}</span>
        <span *ngIf="category.Name.indexOf('Turbo.') != -1">{{ category.Name | translate }}</span>
      </div>
    </div>
  </div>
  <div class="row">
    <div *ngFor="let category of categories.slice(3, 6); let i = index" class="col-4 category-column image option" [ngClass]="{'checked': categorySelections[i + 3], 'unselectable-category': disableUnselectable && categoryAvailability && categoryAvailability[i + 3]}" (click)="selectCategory(category)" style="background-image: url({{category.ImageUri}});">
      <div style="
    position: absolute;
    padding-bottom: 3px;
    bottom: 0px;
    left: 0;
    right: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: rgba(245, 245, 245, 0.9);
">
        <span *ngIf="category.Name.indexOf('Turbo.') == -1">{{ category.Name }}</span>
        <span *ngIf="category.Name.indexOf('Turbo.') != -1">{{ category.Name | translate }}</span>
      </div>
    </div>
  </div>
</div>

