import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, SimpleChanges, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { NgModel } from '@angular/forms';
import * as moment from 'moment';
import { ComponentBase, ObjectTypeInfo, RentalInfo } from 't4core';
import { advanceTimeByUnit, TimeSlot, TimeStepUnit, TimeUnit } from 'app-components';

interface OptHour {
  text: string;
  options: TimeOption[];
  isValid: boolean;
  collidingBooking: boolean;
  isSelected: boolean;
  time: moment.Moment;
  showOptions: boolean;
}

@Component({
  selector: 'timePicker',
  templateUrl: './timePicker.html',
  styleUrls: ['./timePicker.css'],
  exportAs: "T4TimePicker",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimePicker extends ComponentBase implements OnInit {
  @Input() minTime: moment.Moment = moment().startOf('day');
  @Input() maxTime: moment.Moment = moment().startOf('day').add(23, 'hours').add(59, 'minutes');
  @Input() steps: TimeStepUnit = "quarter";
  @Input() disabled: boolean = false;
  @Input() overrideTimeLimits: boolean = false;
  @Input() loader: boolean = false;
  @Input() booking: RentalInfo;
  public savedBooking: RentalInfo;

  @Input() isClosed: boolean = false;

  @ViewChild("txtTime", { static: true }) private txtTime: NgModel;

  private _isDirty: boolean = false;
  public anyValidTimes: boolean = false;
  public get isDirty(): boolean {
    return this._isDirty;
  }

  private _selectedTime: moment.Moment;
  public get selectedTime(): moment.Moment {
    return this._selectedTime;
  }
  @Input()
  public set selectedTime(val: moment.Moment) {
    let m = advanceTimeByUnit(this.steps);
    this._selectedTime = val ? this.round(val, m, 'minute') : null;
  }
  @Output() selectedTimeChange = new EventEmitter<moment.Moment>();

  public get timeValue(): string {
    return this._selectedTime ? this._selectedTime.format("HH:mm") : '';
  }
  @Input()
  public set timeValue(val: string) {
    var dt = this.selectedTime;
    if (val.length == 5) {
      dt = moment(this.selectedTime.format("YYYY-MM-DD") + " " + val);
    } else if (val.length == 4 && val.indexOf(":") < 0) {
      dt = moment(this.selectedTime.format("YYYY-MM-DD") + " " + val.substring(0, 2) + ":" + val.substring(2, 4));
    } else if (val.length == 3 && val.indexOf(":") < 0) {
      dt = moment(this.selectedTime.format("YYYY-MM-DD") + " 0" + val.substring(0, 1) + ":" + val.substring(1, 3));
    }

    if (dt != this.selectedTime && dt.isValid()) {
      let minutes:number = advanceTimeByUnit(this.steps);
      dt = this.round(dt, minutes, 'minute');

      for (let optHour of this.options) {
        for (let opt of optHour.options) {
          if (opt.time.format("HH:mm") == dt.format("HH:mm")) {
            this.selectTime(opt, true);
          }
        }
      }
    }
    this.txtTime.control.setValue(this.selectedTime.format("HH:mm"));
  }

  @Input() public timeSlots: TimeSlot[] = [];

  public options: OptHour[] = [];
  public selectedOption: TimeOption;
  public showOptions: boolean = false;

  @ViewChild("mnuOptions", { static: true })
  public mnuOptions: ElementRef;

  public selectorOpen: boolean = false;

  constructor(private zone: NgZone, el: ElementRef) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    // Update open hours
    if (
      (changes.minTime && changes.minTime.previousValue != changes.minTime.currentValue) ||
      (changes.maxTime && changes.maxTime.previousValue != changes.maxTime.currentValue) ||
      changes.timeSlots
    ) {
      if (this.maxTime && this.minTime) {
        this.Render();
      }
    }

    // Handle selected time
    if (changes.selectedTime && changes.selectedTime.previousValue != changes.selectedTime.currentValue) {
      for (let optHour of this.options) {
        for (let opt of optHour.options) {
          if (opt.time.format("HH:mm") == this.selectedTime.format("HH:mm")) {
            if (!opt.isSelected) this.selectTime(opt, false);
          }
        }
      }
    }
  }

  public async ngOnInit() {

    if (this.booking.Id) {
      this.savedBooking = await this.Api.get<RentalInfo>("/Rental/GetRentalInfo", { bookingId: this.booking.Id });
    }
    this.Render();
  }

  private Render() {
    this.options = [];
    this.selectedOption = null;

    this.maxTime.set('year', this.minTime.year());
    this.maxTime.set('month', this.minTime.month());
    this.maxTime.set('date', this.minTime.date());

    let startOfMinDate:moment.Moment = this.minTime.clone().startOf('day');
    let endOfMinDate:moment.Moment = this.minTime.clone().endOf('day');

    if (this.maxTime.isSame(this.maxTime.clone().startOf('day'))) this.maxTime = this.maxTime.clone().hour(23).minute(59);

    this.anyValidTimes = false

    while (startOfMinDate <= endOfMinDate) {
      let option = {
        time: startOfMinDate.clone(),
        text: startOfMinDate.format("HH:mm"),
        isValid: startOfMinDate >= this.minTime && startOfMinDate < this.maxTime && this.isWithinAnyTimeSlot(startOfMinDate),
        collidingBooking: !this.isWithinAnyTimeSlot(startOfMinDate),
        isSelected: false
      };
      if (this.selectedTime && (startOfMinDate.format("HH:mm") == this.selectedTime.format("HH:mm") || (!this.selectedTime && option.isValid))) this.selectTime(option, false);

      var foundOptHour: boolean = false;
      for (let optHour of this.options) {
        if (optHour.time.hour() === option.time.hour()) {
          if (!option.collidingBooking) { optHour.collidingBooking = false };
          if (option.isValid) { optHour.isValid = true };
          if (this.selectedTime && this.selectedTime.format("HH:mm") == option.time.format("HH:mm")) { optHour.isSelected = true };
          optHour.options.push(option);
          foundOptHour = true;
        }
      }

      if (!foundOptHour) {
        var newHour: OptHour = {
          text: option.text,
          options: [option],
          isValid: option.isValid,
          collidingBooking: option.collidingBooking,
          isSelected: this.selectedTime && this.selectedTime.format("HH:mm") === option.time.format("HH:mm"),
          time: option.time,
          showOptions: false
        };
        this.options.push(newHour);
      }
      if (option.isValid) this.anyValidTimes = true;

      //Send null to parent if not available
      if (this.selectedTime && this.selectedTime.format('HH:mm') == startOfMinDate.format('HH:mm') && !option.isValid) {
        this.selectedTimeChange.emit(null);
      }
      switch (this.steps) {
        case 'halfhour':
          startOfMinDate = startOfMinDate.add(30, 'minutes');
          break;
        case 'quarter':
          startOfMinDate = startOfMinDate.add(15, 'minutes');
          break;
        default:
          startOfMinDate = startOfMinDate.add(1, 'hour');
          break;
      }
    }

    if (this.options.find(x => x.isValid)) {
      // Remove inactive rows from beginning (Dont show too many unavailable options before opening)
      for (var i = 0; i < this.options.length; i -= -1) {
        if (this.options[i].isValid) break;

        if (i == 4) {
          this.options.splice(0, 4);
          i += -4;
        }
      }

      // Remove inactive rows from the end (Dont show too many unavailable options after closing)
      for (var i = 1; i < this.options.length; i -= -1) {
        if (this.options[this.options.length - i].isValid) break;

        if (i == 4) {
          this.options.splice(this.options.length - 4, 4);
          i += -4;
        }
      }
    }
  }

  public isWithinAnyTimeSlot(time: moment.Moment): boolean {
    let timeSlotsInSpan = this.timeSlots.filter(x => x && time >= moment(x.From) && time <= moment(x.To));
    return timeSlotsInSpan.length > 0;
  }

  public openSelector(event) {
    if (this.disabled) return;

    this.selectorOpen = true;
    this.UI.float(this.mnuOptions.nativeElement, event.clientX - 10, event.clientY - 10);

    if (this.selectedOption) {
      let el = document.getElementById("time" + this.selectedOption.text);
      this.mnuOptions.nativeElement.scrollTop = el.offsetTop - 50;
    }
  }

  public toggleHourOptions(hour: OptHour) {
    //There are multiple options for the hour, eg quarter, halfhour - open the div containing options
    if (hour.options.length > 1 && hour.isValid) {
      this.showOptions = !this.showOptions;
      //Set parent to select if one child is selected
      this.options
        .filter(opt => opt.text === hour.text)
        .forEach(opt => {
          opt.showOptions = !opt.showOptions;
          opt.isSelected = this.selectedOption && this.selectedOption.time.hour() === opt.time.hour() || opt.showOptions;
        });
    }
    //There is only one option for the hour, pick it
    else if (hour.isValid) {
      //Select option
      this.selectTime(hour.options[0]);
      //Set parent to select as well
      this.options
        .filter(opt => opt.text === hour.text)
        .forEach(opt => this.selectedOption && this.selectedOption.time.hour() === opt.time.hour())
    }
  }

  public selectTime(timeOption: TimeOption, isUserSelection: boolean = true) {
    if (!timeOption.isValid && !this.overrideTimeLimits || timeOption.collidingBooking) return;

    if (isUserSelection && this.selectedTime != timeOption.time) this._isDirty = true;

    this.selectedTime = timeOption.time;
    this.selectedTimeChange.emit(this.selectedTime);
    timeOption.isSelected = true;

    if (this.selectedOption) this.selectedOption.isSelected = false;
    this.selectedOption = timeOption;
    this.selectorOpen = false;

    //Set parent to selected as well
    for (let option of this.options) {
      if (option.time.hour() == timeOption.time.hour()) {
        if (timeOption.isSelected) {
          option.text = timeOption.text;
        }
        else {
          option.text = option.options[0].text;
          option.isSelected = false;
        }
      }
      else {
        option.text = option.options[0].text;
        option.isSelected = false;
      }
    }
  }

  private round(date: moment.Moment, precision: number, key: TimeUnit): moment.Moment {
    let direction = 'round';
    let keys: TimeUnit[] = ['hour', 'minute', 'second', 'millisecond'];
    let ceilings:number[] = [24, 60, 60, 1000];
    let value:number = 0;
    let isRounded:boolean = false;
    let subRatio:number = 1;
    let cieling: number;

    // Find the apropriate key level, then ciel every finer level up to it
    for (var i in keys) {
      let k: TimeUnit = keys[i];
      if (k === key) {
        value = date.get(key);
        cieling = ceilings[i];
        isRounded = true;
      } else if (isRounded) {
        subRatio *= ceilings[i];
        value += date.get(k) / subRatio;
        date.set(k, 0);
      }
    };

    value = Math[direction](value / precision) * precision;
    value = Math.min(value, cieling);
    return moment(date.set(key, value));
  }

  public markAsPristine() {
    this._isDirty = false;
  }
}

interface TimeOption {
  time: moment.Moment;
  text: string;
  isValid: boolean;
  isSelected: boolean;
  collidingBooking: boolean;
}
