import { NgModule } from '@angular/core';
import { T4coreModule } from 't4core';
import { ViewTitleComponent } from './Components/view-title/view-title.component';
import { MatDialogModule } from '@angular/material/dialog';


@NgModule({
  declarations: [
    ViewTitleComponent

  ],
  imports: [
    T4coreModule,
    MatDialogModule
  ],
  exports: [
    ViewTitleComponent
  ]
})
export class AppComponentsModule { }
