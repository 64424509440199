import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { AppIntegrationService, AppSettingsService } from "t4-app-integration";
import { PageBase, RentalInfo } from "t4core";
import { BookingModel, BookingService, LayoutService, NavigationResolver, NavigationService, ActiveRentalService } from "app-components";



@Component({
  selector: 'keep-in-mind',
  templateUrl: './keep-in-mind.component.html',
  styleUrls: ['./keep-in-mind.component.css']
})

export class KeepInMindComponent extends PageBase implements OnInit {

  public stage: number = 1;
  private interval: any;


  constructor(el: ElementRef, private aviationService: NavigationService) {
    super(el);
  }

  public async ngOnInit() {
    this.interval = setInterval(() => {
      this.stage++;
      if (this.stage > 2) {
        clearInterval(this.interval);
        this.next();
      }
    }, 4000);
    
  }

  public next() {
    this.aviationService.executeCommand("Next");
  }
}
