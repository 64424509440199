<app-view-title Title="{{ 'Intellitrailer.Booking.BeginBooking.TitlePayment' | translate : 'Payment' }}"></app-view-title>
<div class="view-content centered-view">
  <h2 *ngIf="returnValidation && returnValidation.Result != returnValidationResult.Late">{{ 'Intellitrailer.Rental.ReturnPayment.AdditionalCosts' | translate : 'Additional costs' }}</h2>
  <h2 *ngIf="returnValidation && returnValidation.Result == returnValidationResult.Late">{{ 'Intellitrailer.Rental.ReturnPayment.LateReturnFee' | translate : 'Late return fee' }}</h2>

  <div class="container">
    <div class="row text-center">
      <div class="col-12" *ngIf="returnValidation && returnValidation.Result != returnValidationResult.Late">
        {{ 'Intellitrailer.Rental.ReturnPayment.AdditionalCostsText' | translate : 'There are additional costs for this rent.' }}
      </div>
    </div>
    <div class="row text-center mt-2">
      <div class="col-12" *ngIf="returnValidation && returnValidation.Result == returnValidationResult.Late || this.booking?.Status == 150">
        {{ 'Intellitrailer.Rental.ReturnPayment.BookingLateText' | translate : 'This booking has not been returned in time.' }}
      </div>
    </div>

    <div class="row text-center mt-2" *ngIf="returnValidation && returnValidation.ReturnPrice">
      <div class="col-12">
        {{ 'Intellitrailer.Rental.ReturnPayment.OutstandingCost' | translate : 'Outstanding cost' }}: {{ returnValidation.ReturnPrice.DiscountedTotalPrice | currency }}.
      </div>
    </div>
  </div>

</div>
<div class="view-actions">
  <button mat-raised-button (click)="next()">{{ 'Intellitrailer.Navigation.Pay' | translate : 'Pay' }}</button>
</div>

