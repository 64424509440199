import { NgModule } from '@angular/core';
import { AppIntegrationService } from './services/app-integration/app-integration.service';
import { AppSettingsService } from './services/app-settings/app-settings.service';

@NgModule({
  providers: [
    AppIntegrationService,
    AppSettingsService
  ]
})
export class T4AppIntegrationModule { }
