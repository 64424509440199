import { Component } from '@angular/core';
import { SettingsService } from 't4core';
import { Country, getCountries, getCountryByCode } from '../../Models/Country';
import { TextFieldComponent } from '../form-text-field/text-field.component';

@Component({
  selector: 'app-phone-field',
  templateUrl: './phone-field.component.html',
  styleUrls: ['./PhoneField.css']
})
export class PhoneFieldComponent extends TextFieldComponent {
  public country: Country;
  public countries: Country[] = getCountries();

  constructor(settingsService: SettingsService) {
    super();
    this.country = getCountryByCode(settingsService.clientSettings.Country ?? 'xx');
  }

  public onChange(number: string): void {
    var prefix = this.findPrefix(number);
    if (prefix != '' && prefix != this.country.phonePrefix) {
      var found = false;
      this.countries.forEach(x => {
        if (x.phonePrefix == prefix) {
          this.country = x;
          found = true;
        }
      });
      if (!found) this.country = this.countries.find(x => x.phonePrefix == '+XX');
    }

    var val = number;
    if (this.country.phonePrefix != '+XX')
      val = this.appendPrefix(this.country.phonePrefix, number);

    

    if(val != number)
      this.control.setValue(val);
  }

  private findPrefix(number: string): string {
    var ret = "";

    this.countries.forEach(x => {
      if (number.startsWith(x.phonePrefix)) {
       ret = x.phonePrefix;
      }
    });

    return ret;
  }

  public changePrefix() {
    if (this.country.phonePrefix == "+XX") return;

    var val = this.appendPrefix(this.country.phonePrefix, this.control.value);
    if (this.control.value != val)
      this.control.setValue(val);
  }

  private appendPrefix(prefix: string, number: string): string {
    number = this.removePrefix(number);

    if (prefix == '+46' && number.startsWith('0')) number = number.slice(1);
    number = this.country.phonePrefix + number;
    return number;
  }

  private removePrefix(number: string): string {
    var ret = number;

    this.countries.forEach(x => {
      if (ret.startsWith(x.phonePrefix)) {
        ret = ret.slice(x.phonePrefix.length);
      }
    });

    if (ret.startsWith('+'))
      ret = ret.slice(3);
    else if (ret.startsWith("00"))
      ret =  number.slice((4));

    return ret;
  }
}
