<div class="information-screen">
  <h1>{{ 'Intellitrailer.CameraPermissionRequired.Title' | translate : 'Enable camera access'}}</h1>

  <mat-icon>photo_camera</mat-icon>

  <h2>{{'Intellitrailer.CameraPermissionRequired.SubTitle' | translate : 'We need access to your camera.' }}</h2>
  <p>{{ 'Intellitrailer.CameraPermissionRequired.Text' | translate : 'You are now going to take photos of the trailer to document the current state. Therefor the app needs access to your camera.'}}</p>
  <button mat-raised-button color="accent" (click)="askForPermission()" *ngIf="!permissionAsked">{{ 'Intellitrailer.CameraPermissionRequired.cmdAskPermission' | translate : 'I understand' }}</button>
  <button mat-raised-button color="accent" (click)="openSettings()" *ngIf="permissionAsked">{{ 'Intellitrailer.CameraPermissionRequired.cmdOpenSettings' | translate : 'Open settings' }}</button>
  <view-actions>
    <a (click)="skipTakePictures()" *ngIf="permissionAsked">{{ 'Intellitrailer.Rental.TakePictures.cmdSkip' | translate : 'Skip' }}</a>
  </view-actions>
</div>
