import { NgModule } from '@angular/core';
import { T4coreModule } from 't4core';
import { AppComponentsModule } from 'app-components';
import { OWModelSelectorComponent } from './Booking/Views/select-model/select-model';
import { MatButtonModule } from '@angular/material/button';
import { OWAlternativeSelectorComponent } from './Booking/Views/select-alternative/select-alternative';
import { BookingAlternativeComponent } from './Booking/Components/booking-alternative/booking-alternative';
import { CommonModule, DatePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [
    OWModelSelectorComponent,
    OWAlternativeSelectorComponent,
    BookingAlternativeComponent
  ],
  imports: [
    CommonModule,
    AppComponentsModule,
    T4coreModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [
    OWModelSelectorComponent,
    OWAlternativeSelectorComponent
  ]
})
export class AppOnewayModule { }
