import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogBase } from 't4core';
import { AppIntegrationService, AppSettingsService } from 't4-app-integration';

@Component({
  selector: 'app-rental-agreement',
    templateUrl: './rental-agreement.component.html',
    styleUrls: ['./rental-agreement.component.css']
})
export class RentalAgreementComponent extends DialogBase implements OnInit {

    public rentalAgreement: string;
    public title: string;
    
    constructor(
        public dialogRef: MatDialogRef<RentalAgreementComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any, private appSvc: AppIntegrationService, private appSettings: AppSettingsService) {
        super("FindLocationComponent");
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    public confirm() {
        this.dialogRef.close(true);
    }

    async ngOnInit() {
        this.rentalAgreement = await this.getAgreement();
    }

    public async getAgreement(): Promise<string> {
        var agree = "";
        if (this.data && this.data.agreementType) {
            if (this.data.agreementType == "GDPR") {

                if (this.appSettings.settings.GDPRTerms) {
                  agree = this.appSettings.settings.GDPRTerms;
                }

            }
            else if (this.data.agreementType == "booking") {

                if (this.appSettings.settings.agreementDocument) {
                    agree = this.appSettings.settings.agreementDocument;
                }
                else {
                    agree = await this.Api.get<string>("Settings/GetAgreementText");
                }

            }
            else if (this.data.agreementType == "bauhausVarebil") {
                agree = "https://www.bauhaus.dk/service/udlejning/trailer-udlejning/lejebetingelser-varevogn";
            }

            else if (this.data.agreementType == "info" && this.data.key) {
                if (this.data.title && this.data.title.indexOf('Turbo.') > -1 || this.data.title.indexOf('Intellitrailer.') > -1) this.title = await this.Translate.translate(this.data.title);
                else if (this.data.title) this.title = this.data.title;
                agree = await this.Translate.translate(this.data.key);
            }

            this.tryOpenExternal(agree);
            return agree;
        }
        else {
            var agree = await this.Api.get<string>("Settings/GetAgreementText");
            this.tryOpenExternal(agree);
            return agree;
        }
    }

    public async tryOpenExternal(agree: string) {
        if (agree.startsWith("http")) {
            this.appSvc.openExternal(agree);

            this.dialogRef.close();
        }
    }
}
